import React from 'react'
import { dateTimeFormat, getOrdinal } from '../../../../utils/common-functions'
import { useTranslation } from 'react-i18next'
import Pagination from '../../../../components/Pagination'
import { useSelector } from 'react-redux'

const ResultList = ({
  activeTab,
  data,
  handleResultDetails,
  handleRosterDetailPopup,
  showPagination,
  showNextBtn,
  currentOffset,
  onPageChange
}) => {
  const { t } = useTranslation('home')
  const { timeSettings } = useSelector((state) => state.gameSetting)
  return (
    <>
      <div className='bg-primaryColor-200 rounded-3xl p-4'>
        <div className='overflow-x-auto'>
          <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
            <thead className='ltr:text-left rtl:text-right p-2 lg:table-header-group hidden'>
              <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-lg [&>th]:p-4'>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize text-start'>
                  {t('contestName')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('resultAnnounced')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('entryFee')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('rosterSubmissionDateTime')}
                </th>
                {activeTab === 2 && (
                  <>
                    <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                      {t('position')}
                    </th>
                    <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                      {t('prize')}
                    </th>
                    <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'></th>
                  </>
                )}
              </tr>
            </thead>

            <tbody className='[&>tr]:mb-4 [&>tr]:lg:mb-0 [&>*:last-child]:mb-0 lg:divide-y  lg:divide-primaryColor-350 divide-solid '>
              {data?.rows?.length > 0 ? (
                data?.rows?.map((row) => {
                  return (
                    <tr className='[&>td]:py-6 [&>td]:lg:pl-1 flex flex-col items-start lg:table-row bg-primaryColor-100 lg:bg-transparent rounded-2xl lg:rounded-none max-lg:border-primaryColor-500 max-lg:border max-lg:border-solid'>
                      <td dynamic-heading-value={t('contestName')}
                        onClick={() => handleRosterDetailPopup(true, row)}
                        className="text-center lg:text-start pl-[50%] tabletscreen:whitespace-nowrap px-1 text-white text-base tabletscreen:text-lg lg:text-xl font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full"
                      >
                        <div className='cursor-pointer'>{`${row?.Bucket?.bucketUid}`}</div>
                      </td>

                      <td dynamic-heading-value={t('resultAnnounced')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                        {activeTab == 1 ? moment(timeSettings?.resultTime, 'HH:mm:ssZ').local().format(`MM/DD/YYYY / HH:mm:ss`) : moment(row?.Bucket?.resultDateTime).local().format(`MM/DD/YYYY / HH:mm:ss`)}
                      </td>

                      <td dynamic-heading-value={t('entryFee')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-white font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">{`$ ${row?.amount}`}</td>

                      <td dynamic-heading-value={t('rosterSubmissionDateTime')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                      {moment(row?.submittedAt).local().format(`MM/DD/YYYY / HH:mm:ss`)}
                      </td>

                      {activeTab === 2 && (
                        <>
                          <td dynamic-heading-value={t('position')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                            {getOrdinal(row?.Result?.rank)}
                          </td>
                          <td dynamic-heading-value={t('prize')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full text-green-500">
                            ${row?.Result?.prize}
                          </td>
                          <td className='tabletscreen:whitespace-nowrap px-1 lg:w-auto w-full flex justify-center'>
                            <a
                              onClick={() =>
                                handleResultDetails(
                                  row.bucketId,
                                  row?.Bucket?.bucketUid
                                )
                              }
                              className='inline-block rounded border-primaryColor-500 border border-solid px-6 py-2.5 text-base capitalize font-medium text-primaryColor-500 leading-none hover:animate-btnHover transition-all hover:text-white hover:delay-200'
                            >
                              {t('seeResult')}
                            </a>
                          </td>
                        </>
                      )}
                    </tr>
                  )
                })
              ) : (
                <tr className=''>
                  <td
                    colSpan={activeTab === 2 ? '6' : '4'}
                    className='text-white text-center text-lg pt-4 max-lg:pt-0'
                  >
                    {t('noDataAvailable')}.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showPagination && (
        <Pagination
          currentOffset={currentOffset}
          onPageChange={onPageChange}
          disableNext={showNextBtn}
          itemCount={data?.count}
        />
      )}
    </>
  )
}

export default ResultList
