import React, { useRef } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PlayerDetail from '../PlayerDetail'
import { CloseIcon } from '../../../../assets/image'

const PlayerDetailPopup = ({
  showPlayerDetailPopup,
  handleShowPlayerDetail,
  playerDetailSection,
  bucketDetails,
  remainingSalaryCap,
  handleRosterPlayerList
}) => {
  const cancelButtonRef = useRef(null)
  return (
    <>
      <Transition.Root show={showPlayerDetailPopup} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={() => handleShowPlayerDetail(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-popupBg-100 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full justify-center p-4 text-center items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative shadow-dialogShadow transform overflow-hidden rounded-lg bg-primaryColor-200 text-left transition-all sm:my-8 w-full max-w-[450px]'>
                  <PlayerDetail
                    playerDetailSection={playerDetailSection}
                    bucketDetails={bucketDetails}
                    remainingSalaryCap={remainingSalaryCap}
                    handleRosterPlayerList={handleRosterPlayerList}
                  />
                  <CloseIcon onClick={()=>handleShowPlayerDetail(false)}  className='absolute top-1 right-1 [&>path]:fill-white' />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default PlayerDetailPopup
