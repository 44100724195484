import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllCms } from "../../../redux-store/redux/thunk/gameSetting"

export const useFooterController = () => {
    const dispatch = useDispatch()
    const {allCms,socialLinks, lobbyContent} = useSelector(state=>state.gameSetting)
    const cms = allCms?.cms
    useEffect(()=>{
        // dispatch(getAllCms())
    },[])
    return{
        cms,
        socialLinks,
        lobbyContent
    }
}