import React from 'react'
import Loader from '../../components/Loader'
import { LOADER } from '../../utils/constants'
import ResultList from './components/ResultList'
import { useResultListController } from './controller/useResultListController'
import RosterDetailPopup from './components/RosterDetailPopup'

const TodayResult = () => {
  const {
    t,
    loading,
    resultList,
    activeTab,
    handleTabChange,
    handleSearch,
    handleResultDetails,
    showRosterDetailPopup,
    setShowRosterDetailPopup,
    handleRosterDetailPopup,
    rosterDetail,
    setRosterDetail,
    loader,
    rosterDetailBucket,
    setRosterDetailBucket,
    currentOffset,
    showNextBtn,
    showPagination,
    onPageChange
  } = useResultListController()
  return (
    <>
      <div className='max-w-[1320px] mx-auto px-4 2xl:px-0 py-10 min-h-[calc(100dvh_-_505px)]'>
        <div className='my-6 max-sm:mt-0 flex flex-wrap md:flex-nowrap items-center gap-6 justify-between'>
          <div className='w-full md:w-[56%]'>
            <ul className='bg-primaryColor-100 rounded-[56px] columns-2 p-2'>
              <li
                onClick={() => handleTabChange(1)}
                className={`${
                  activeTab == 1 ? 'resultBtnBg' : ''
                } text-center p-2.5 tabletscreen:p-4 flex flex-col items-center gap-4 group cursor-pointer`}
              >
                <span className='text-xs tabletscreen:text-lg capitalize text-primaryColor-800 group-[.resultBtnBg]:text-dustyGray-700'>
                  {t('currentPending')}
                </span>
              </li>
              <li
                onClick={() => handleTabChange(2)}
                className={`${
                  activeTab == 2 ? 'resultBtnBg' : ''
                } text-center p-2.5 tabletscreen:p-4 flex flex-col items-center gap-4 group cursor-pointer`}
              >
                <span className='text-xs tabletscreen:text-lg capitalize text-primaryColor-800 group-[.resultBtnBg]:text-dustyGray-700'>
                  {t('previous')}
                </span>
              </li>
            </ul>
          </div>
          <div className='w-full md:w-[42%]'>
            <form>
              <div class='relative'>
                <div class='absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none'>
                  <svg
                    class='w-4 h-4 text-primaryColor-500'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                  >
                    <path
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                    />
                  </svg>
                </div>
                <input
                  onChange={handleSearch}
                  type='search'
                  id='default-search'
                  class='block w-full !pl-10 p-2.5 tabletscreen:p-4 text-sm tabletscreen:text-lg text-primaryColor-500 border border-primaryColor-500 rounded-[40px] bg-transparent focus-visible:border-primaryColor-500 focus-visible:outline-none placeholder:text-primaryColor-500'
                  placeholder={t('searchContestName')}
                  required
                />
              </div>
            </form>
          </div>
        </div>
        {loading ? (
          <Loader component={LOADER.TABLE_CELL} />
        ) : (
          <ResultList
            activeTab={activeTab}
            data={resultList}
            handleResultDetails={handleResultDetails}
            handleRosterDetailPopup={handleRosterDetailPopup}
            currentOffset={currentOffset}
            showNextBtn={showNextBtn}
            showPagination={showPagination}
            onPageChange={onPageChange}
          />
        )}
      </div>
      {showRosterDetailPopup && (
        <RosterDetailPopup
          setRosterDetailBucket={setRosterDetailBucket}
          rosterDetailBucket={rosterDetailBucket}
          loader={loader}
          setRosterDetail={setRosterDetail}
          showRosterDetailPopup={showRosterDetailPopup}
          setShowRosterDetailPopup={setShowRosterDetailPopup}
          rosterDetail={rosterDetail}
          activeTab={activeTab}
        />
      )}
    </>
  )
}

export default TodayResult
