import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import dropIcon from '../../assets/image/down-arrow.png'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { useHistory, Link } from 'react-router-dom'
import { userLogout } from '../../redux-store/redux/thunk/Auth/Auth'
import UserIcon from '../../assets/image/usericon.png'
import dollarIcon from '../../assets/image/dollar-symbol.png'
import { toFixed } from '../../utils/common-functions'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const UserDropdown = ({ t, setShowWalletModal }) => {
  const dispatch = useDispatch()

  const { addToast } = useToasts()
  const history = useHistory()
  const { userDetails } = useSelector((state) => state.userSlice)

  const handleLogOut = () => {
    dispatch(userLogout({ history, addToast }))
    localStorage.clear()
  }

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex items-center w-full justify-center gap-x-1.5 rounded-md p-2 text-xs font-semibold'>
          <div
            className={
              userDetails?.profileImageUrl
                ? 'hidden max-lg:block'
                : 'p-2 max-smallscreen:p-1.5 border border-solid activeProTab rounded-full inline-block w-[42px] max-sm:w-[34px] max-smallscreen:w-[28px] h-[42px] max-sm:h-[34px] max-smallscreen:h-[28px] hidden max-lg:block'
            }
          >
            <img
              src={
                userDetails?.profileImageUrl
                  ? userDetails?.profileImageUrl
                  : UserIcon
              }
              alt='user image'
              className={
                userDetails?.profileImageUrl
                  ? 'w-[42px] h-[42px] object-cover rounded-full'
                  : 'mx-auto'
              }
            />
          </div>
          <span className='text-white text-base leading-tight capitalize max-lg:hidden'>
            {userDetails?.firstName} {userDetails?.lastName}
          </span>
          <img src={dropIcon} alt='' className='max-lg:hidden' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-[11] mt-2 w-[216px] origin-top-right border-[1px] border-solid border-primaryColor-500 rounded-lg bg-primaryColor-200 shadow-menushadow'>
          <div className='py-1 divide-y-[1px] divide-primaryColor-350'>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/myaccount'
                  className={classNames(
                    active
                      ? 'bg-primaryColor-200 text-primaryColor-500'
                      : 'text-white',
                    'block px-6 py-4 text-base font-semibold'
                  )}
                >
                  {t('userDropdown.myProfile')}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/withdrawals'
                  className={classNames(
                    active
                      ? 'bg-primaryColor-200 text-primaryColor-500'
                      : 'text-white',
                    'block px-6 py-4 text-base font-semibold'
                  )}
                >
                  {t('userDropdown.withdrawal')}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/transactions'
                  className={classNames(
                    active
                      ? 'bg-primaryColor-200 text-primaryColor-500'
                      : 'text-white',
                    'block px-6 py-4 text-base font-semibold'
                  )}
                >
                  {t('userDropdown.transactions')}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setShowWalletModal(true)}
                  className={classNames(
                    active
                      ? 'bg-primaryColor-200 text-primaryColor-500'
                      : 'text-white',
                    'block px-6 py-4 text-base font-semibold w-full text-start lg:hidden'
                  )}
                >
                  {t('cashier')}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    'bg-primaryColor-600 text-white block px-6 py-4 text-base font-semibold w-full text-start lg:hidden'
                  )}
                >
                  <span className='flex align-center'>
                  <img src={dollarIcon} alt='' /> <span className='px-2'>{userDetails?.wallets?.[0]?.amount &&
                        toFixed(userDetails?.wallets?.[0]?.amount)}</span>
                      </span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleLogOut}
                  className={classNames(
                    active
                      ? 'bg-primaryColor-200 text-primaryColor-500'
                      : 'text-white',
                    'block px-6 py-4 text-base font-semibold w-full text-start'
                  )}
                >
                  {t('userDropdown.logout')}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default UserDropdown
