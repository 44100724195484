import { combineReducers } from '@reduxjs/toolkit'
import authSlice from '../redux-slices/Auth/authSlice'
import userSlice from '../redux-slices/Auth/userSlice'
import lobbySlice from '../redux-slices/Lobby/lobbySlice'
import playerSlice from '../redux-slices/Player/playerSlice'
import user from '../redux-slices/Users/user'
import gameSetting from '../redux-slices/gameSetting'
import transactionSlice from '../redux-slices/Transaction/transactionSlice'
import resultSlice from '../redux-slices/Result/resultSlice'
import rosterSlice from '../redux-slices/Roster/rosterSlice'

const rootReducer = combineReducers({
  user,
  gameSetting,
  authSlice,
  userSlice,
  lobbySlice,
  playerSlice,
  transactionSlice,
  resultSlice,
  rosterSlice
})

export default rootReducer
