import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getBucketWinner } from '../../../../../redux-store/redux/thunk/Result/Result';
export const useResultDetailsController = () => {
    const { t } = useTranslation('home')
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const {winnerData, loading} = useSelector(state=>state.resultSlice)
    const {userDetails} = useSelector(state=>state.userSlice)
    const history = useHistory()
    const location = useLocation();
    // const { state } = location;
    // const {bucketId, bucketUid} = state
    const bucketId = location?.state?.bucketId
    const bucketUid = location?.state?.bucketUid
    useEffect(()=>{
        dispatch(getBucketWinner({bucketId}))
    },[])
    const [dimensions,setDimensions] = useState({
        width:window.innerWidth,
        height:window.innerHeight
    })
    useEffect(()=>{
        const handleResize = () => {
            setDimensions({
                width:window.innerWidth,
            height:window.innerHeight
            })
        }
        window.addEventListener('resize',handleResize)
        return()=>{
        window.removeEventListener('resize',handleResize)

        }
    },[])
    return {
        t,
        loading,
        winnerData,
        userId:userDetails?.id,
        bucketUid,
        history,
        dimensions,
        userDetails
    }
    
}