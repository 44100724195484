import React, { useRef } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CloseIcon,
  PayPalIcon,
  RugbyICon,
  UserIcon,
  ArrowRight,
  BackArrow
} from '../../../../assets/image'
import UserImg from '../../../../assets/image/userimg.png'
import { Link } from 'react-router-dom'
import Pagination from '../../../../components/Pagination'
import BucketAction from '../BucketAction'
import Loader from '../../../../components/Loader'
import { useTranslation } from 'react-i18next'
import { toFixed } from '../../../../utils/common-functions'

const EntryDetails = ({
  showEntryDetailsModal,
  setShowEntryDetailsModal,
  bucketDetails,
  userDetails,
  showPaginationPopup,
  currentOffsetPopup,
  showNextBtnPopup,
  onPageChangePopup,
  bucketUsers,
  setBucketUsers,
  timeSettings,
  handleBucketEnter,
  loader,
  isContestBegins,
  setIsContestBegins,
  isBucketDisable,
  setIsBucketDisable,
}) => {
  const { t } = useTranslation('lobby')
  const cancelButtonRef = useRef(null)

  const handleClose = () => {
    setShowEntryDetailsModal(false)
    setBucketUsers([])
  }

  return (
    <>
      <Transition.Root show={showEntryDetailsModal} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-[rgb(0,_0,_0)] bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='max-w-[1264px] relative transform overflow-hidden rounded-lg bg-primaryColor-200 text-left shadow-dialogShadow transition-all '>
                  <div className='p-6 max-sm:p-4'>
                    <button
                      onClick={handleClose}
                      className='absolute right-6 top-5 cursor-pointer outline-none'
                    >
                      {/* <img src={closeIcon} alt="close btn" /> */}
                      <CloseIcon />
                    </button>
                    <div className='mt-[30px] bg-primaryColor-100 px-8 max-md:px-4 py-6 rounded-3xl'>
                      <div className='flex items-center max-xl:flex-wrap max-xl:gap-4'>
                        <div className='pr-8 max-xl:pr-0 max-xl:pb-4 border-r border-solid border-primaryColor-350 max-xl:border-r-0 max-xl:border-b max-xl:border-primaryColor-350 max-xl:w-full'>
                          <div className='flex items-center gap-[15px]'>
                            {' '}
                            <RugbyICon className='w-[30px] h-[30px] object-contain' />{' '}
                            <span className='text-[25px] max-md:text-lg font-semibold text-white'>
                              {bucketDetails?.bucketUid}
                            </span>
                          </div>
                        </div>

                        <div className='flex max-lg:flex-wrap justify-evenly max-xl:w-full max-xl:justify-between max-xl:gap-4 gap-[68px] pl-8 max-xl:pl-0'>
                          <div>
                            <p className='text-xl max-lg:text-base font-normal text-white'>
                              {bucketDetails?.numberOfUsers} of{' '}
                              {
                                bucketDetails
                                  ?.numberOfParticipants
                              }
                            </p>
                            <p className='text-base max-lg:text-base font-semibold text-primaryColor-500'>
                              {Number(
                                (bucketDetails?.numberOfUsers /
                                  bucketDetails
                                    ?.numberOfParticipants) *
                                  100
                              )
                                .toFixed(2)
                                .replace(/[.,]00$/, '')}{' '}
                              %
                            </p>
                          </div>
                          <div>
                            <p className='text-xl max-lg:text-base font-normal text-customGreen-400'>
                              {bucketDetails?.bucketType ==
                              'free'
                                ? 'Free'
                                : `$ ${bucketDetails?.price && toFixed(bucketDetails?.price)}`}
                            </p>
                            <p className='text-base max-lg:text-base font-semibold text-primaryColor-500 capitalize'>
                              {(t('entryFee'))}
                            </p>
                          </div>
                          <div>
                            <p className='text-xl max-lg:text-base font-normal text-customGreen-400'>
                              <span>{(t('top'))} </span>
                              <span>
                                {
                                  bucketDetails
                                    ?.numberOfWinners
                                }
                              </span>
                              <span> {(t('wins'))}</span>
                            </p>
                            <p className='text-base max-lg:text-base font-semibold text-primaryColor-500 capitalize'>
                              {(t('numberOfWinners'))}
                            </p>
                          </div>
                          <div>
                            <p className='text-xl max-lg:text-base font-normal text-white'>
                              {moment
                                .utc(
                                  bucketDetails?.endTime,
                                  'HH:mm:ssZ'
                                )
                                .local()
                                .format('HH:mm:ss')}
                            </p>
                            <p className='text-base font-semibold text-primaryColor-500 capitalize'>
                              {(t('rosterSubmissionTime'))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mt-5 px-2'>
                      <p className='text-xl max-md:text-base text-white font-normal capitalize border-b-2 border-solid border-primaryColor-800 pb-1.5 inline-block'>
                        {(t('entries'))}
                      </p>
                    </div>

                    { loader ? <Loader />
                    : bucketUsers?.row?.length > 0 ? 
                    <div className='grid grid-cols-1 md:grid-cols-3 mt-5 gap-x-12 px-2'>
                      {['', '', ''].map((divItem, divIndex) => {
                        let i = divIndex * 7
                        return (
                          <div
                            key={divIndex}
                            className='[&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:border-solid [&>*:not(:last-child)]:border-primaryColor-350'
                          >
                            {bucketUsers?.row
                              ?.slice(i, i + 7)
                              .map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className='flex justify-between items-center py-4 pr-3'
                                  >
                                    <div className='flex items-center gap-4'>
                                      {' '}
                                      {item?.profileImageUrl ? (
                                        <img
                                          src={item?.profileImageUrl}
                                          alt=''
                                          className='w-[42px] h-[42px] object-cover rounded-full'
                                        />
                                      ) : (
                                        <UserIcon className='w-[42px] h-[42px] max-sm:hidden bg-primaryColor-100 object-contain rounded-full p-1' />
                                      )}
                                      <span
                                        className='text-white text-lg font-medium capitalize max-w-[160px] text-ellipsis whitespace-nowrap overflow-hidden'
                                        title='Chris Glasser Glasser jhon'
                                      >
                                        {item?.firstName} {item?.lastName}
                                      </span>
                                    </div>

                                    <div className='text-base font-semibold text-primaryColor-500'>
                                      {t('entry')}{' '}
                                      <span>#{index + 1 + divIndex * 7}</span>
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        )
                      })}

                      <div></div>
                    </div>
                    : <span className='flex justify-center align-center text-white p-4'>{t('noUsersJoined')}</span>}

                    {showPaginationPopup && (
                      <Pagination
                        currentOffset={currentOffsetPopup}
                        onPageChange={onPageChangePopup}
                        disableNext={showNextBtnPopup}
                        itemCount={bucketUsers?.count}
                        paginationLimit={21}
                      />
                    )}

                    <div className='flex justify-end items-center mt-[-40px] max-md:mt-0 max-md:justify-center'>
                    <BucketAction
                      item={bucketDetails}
                      timeSettings={timeSettings}
                      userDetails={userDetails}
                      handleBucketEnter={handleBucketEnter}
                      isContestBegins={isContestBegins}
                      setIsContestBegins={setIsContestBegins}
                      isBucketDisable={isBucketDisable}
                      setIsBucketDisable={setIsBucketDisable}
                    />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default EntryDetails
