import React from 'react'
import { useTranslation } from 'react-i18next'
import { MinusIcon, UserIcon } from '../../../../assets/image'
import { toFixed } from '../../../../utils/common-functions'

const RosterSection = ({
  bucketDetails,
  remainingSalaryCap,
  fullRosterDnp,
  rosterPositionList,
  rosterPlayerList,
  handlePerPlayerDnp,
  handleRosterPlayerDelete,
  handleFullRosterDnp,
  dnpInsuranceAmount,
  handleViewRoster
}) => {
  const { t } = useTranslation('playerSelection')
  return (
    <div className='bg-primaryColor-200 rounded-3xl max-lg:rounded-none overflow-hidden'>
      <div className='flex items-center justify-between p-6 bg-btnGrdient capitalize'>
        <h4 className='text-xl max-sm:text-base font-semibold text-white'>
          {t('roster')}
        </h4>
        <p className='text-base max-sm:text-xs font-normal text-white'>
          {t('remainingSalaryCap')} :{' '}
          <span className='text-lg max-sm:text-xs font-semibold text-white'>
            $
            <span>
              {Number(bucketDetails?.budget) -
                remainingSalaryCap}
            </span>
          </span>
        </p>
      </div>

      <div className=' p-2 tabletscreen:p-4'>
        <div className='overflow-x-auto max-h-[770px]'>
          <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
            <thead className='ltr:text-left rtl:text-right p-2'>
              <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-xs [&>th]:tabletscreen:text-base'>
                <th className='text-start whitespace-nowrap pl-4 pr-1 py-5 font-normal capitalize '>
                  {t('playername')}
                </th>

                <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                  {t('pos')}
                </th>
                <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                  {t('careerAvg')}
                </th>
                <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                  {t('salary')}
                </th>
                {!fullRosterDnp &&
                  bucketDetails?.bucketType == 'paid' && (
                    <th className='text-start whitespace-nowrap px-1 py-5 font-normal capitalize '>
                      {t('dnp')}
                    </th>
                  )}
                <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'></th>
              </tr>
            </thead>

            <tbody className='[&>tr]:mb-0 divide-y  divide-primaryColor-350 divide-solid '>
              {bucketDetails?.positionWisePlayer &&
                rosterPositionList?.map((playerPosition, index) => {
                  return (
                    <>
                      {playerPosition?.length >
                        rosterPlayerList?.filter(
                          (item) => item?.position == playerPosition?.position
                        )?.length &&
                        Array(
                          playerPosition?.length -
                            rosterPlayerList?.filter(
                              (item) =>
                                item?.position == playerPosition?.position
                            )?.length
                        )
                          .fill('')
                          .map((item, index) => {
                            return (
                              <tr>
                                <td
                                  key={index}
                                  colSpan='5'
                                  className='capitalize whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold text-center'
                                >
                                  {t('select')}{' '}
                                  <span className='text-primaryColor-800 uppercase'>
                                    {playerPosition?.position}
                                  </span>
                                </td>
                              </tr>
                            )
                          })}
                      {rosterPlayerList
                        ?.filter(
                          (item) => item?.position == playerPosition?.position
                        )
                        .map((item, index) => {
                          return (
                            <tr
                              className='[&>td]:text-center rounded-2x'
                              key={index}
                            >
                              <td className='!text-start capitalize whitespace-nowrap pl-4 pr-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                <div className='flex items-center gap-2  lg:flex'>
                                  {item?.image ? (
                                    <img
                                      className='hidden tabletscreen:block w-[40px] h-[40px] object-cover rounded-full'
                                      src={item?.image}
                                      alt='game image'
                                    />
                                  ) : (
                                    <UserIcon className='hidden tabletscreen:block w-[40px] h-[40px] bg-primaryColor-100 object-contain rounded-full p-1' />
                                  )}
                                  <span className='text-primaryColor-850 text-xs tabletscreen:text-base'>
                                    {item?.name}
                                  </span>
                                </div>
                              </td>

                              <td className='capitalize whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                {item?.position}
                              </td>

                              <td className='whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                {item?.careerAvg && toFixed(item?.careerAvg)}
                              </td>

                              <td className='whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                $ <span>{item?.value && toFixed(item?.value)}</span>
                              </td>

                              {!fullRosterDnp &&
                                bucketDetails
                                  ?.bucketType == 'paid' && (
                                  <td className='whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                    <label
                                      htmlFor='BlueCheckbox'
                                      className='flex items-center justify-start'
                                    >
                                      <input
                                        checked={item?.isPlayerDnp}
                                        onClick={() =>
                                          handlePerPlayerDnp(item?.id)
                                        }
                                        type='checkbox'
                                        id='BlueCheckbox'
                                        className='cursor-pointer appearance-none w-6 h-6 bg-transparent border border-primaryColor-800 border-solid rounded-md checked:bg-bluecheckboxIcon checked:bg-no-repeat checked:bg-center'
                                      />
                                      <p className='text-base font-normal'>
                                        <span
                                          className={`px-2 text-sm ${
                                            item?.isPlayerDnp == true
                                              ? 'text-primaryColor-800'
                                              : 'text-primaryColor-500'
                                          }`}
                                        >
                                          $
                                          <span>
                                            {
                                              bucketDetails
                                                ?.dnpInsurancePerPlayer && 
                                                toFixed(bucketDetails?.dnpInsurancePerPlayer)
                                            }
                                          </span>
                                        </span>
                                      </p>
                                    </label>
                                  </td>
                                )}

                              <td className='whitespace-nowrap px-1 py-4'>
                                <button
                                  onClick={() => handleRosterPlayerDelete(item)}
                                  className='group flex items-center justify-center '
                                >
                                  <MinusIcon className='group-hover:fill-white transition-all delay-100 ease-in-out w-4 h-4 max-sm:w-[16px] max-sm:h-[16px] object-contain' />
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                    </>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>

      <div className='px-2 py-4 tabletscreen:px-6 tabletscreen:py-6 bg-primaryColor-900'>
        {bucketDetails?.bucketType == 'paid' &&
          rosterPlayerList?.length > 0 && (
            <div className='flex gap-2 pb-6 mb-6 border-b border-solid border-primaryColor-350 items-center'>
              <label
                htmlFor='BlueCheckbox'
                className='flex items-center justify-start'
              >
                <input
                  checked={fullRosterDnp}
                  onChange={handleFullRosterDnp}
                  type='checkbox'
                  id='BlueCheckbox'
                  className='cursor-pointer appearance-none w-6 h-6 bg-transparent border border-primaryColor-800 border-solid rounded-md checked:bg-bluecheckboxIcon checked:bg-no-repeat checked:bg-center'
                />
              </label>
              <p className='text-lg max-sm:text-xs font-normal text-white capitalize'>
                {t('fullRosterDnpInsurance')} : $
                <span className='text-white text-lg max-sm:text-xs font-semibold'>
                  {bucketDetails?.dnpInsurancePrice && toFixed(bucketDetails?.dnpInsurancePrice)}
                </span>
              </p>
            </div>
          )}
        <div className='flex gap-6 items-center max-sm:flex-wrap justify-between'>
          <div className='[&>:not(:last-child)]:pb-2'>
            <div className='w-full flex gap-4 pb-1'>
              <span className='w-[150px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
                <span className='text-lg max-md:text-base max-sm:text-xs'>
                  {t('bucketPrice')}
                </span>{' '}
                <span>:</span>
              </span>
              <span className='flex items-center text-white text-lg max-md:text-base max-sm:text-xs font-semibold'>
                {bucketDetails?.bucketType == 'paid' &&
                  '$'}
                <span className='text-white text-lg max-md:text-base max-sm:text-xs font-semibold'>
                  {bucketDetails?.bucketType == 'free'
                    ? 'Free'
                    : bucketDetails?.price && toFixed(bucketDetails?.price)}
                </span>
              </span>
            </div>
            {bucketDetails?.bucketType == 'paid' && (
              <div className='w-full flex gap-4'>
                <span className='w-[150px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
                  <span className='text-lg max-md:text-base max-sm:text-xs'>
                    {t('dnpInsurance')}
                  </span>{' '}
                  <span>:</span>
                </span>
                <span className='flex items-center text-white text-lg max-md:text-base max-sm:text-xs font-semibold'>
                  {' '}
                  $
                  <span className='text-white text-lg max-md:text-base max-sm:text-xs font-semibold'>
                    {dnpInsuranceAmount && toFixed(dnpInsuranceAmount)}
                  </span>
                </span>
              </div>
            )}
          </div>

          <div className=''>
            {/* <button
            onClick={handleViewRoster}
            className='w-full text-xl max-xl:text-base h-[76px] max-xl:h-auto leading-none font-normal capitalize bg-primaryColor-800 text-white border-primaryColor-500 border-solid border px-8 max-xl:px-4 py-[18px] max-sm:py-2.5 rounded-lg text-center'
          >
            View / Submit Roster
          </button> */}

            <button
              onClick={handleViewRoster}
              className='w-full text-xl max-xl:text-base h-[76px] max-xl:h-auto leading-none font-normal capitalize border-primaryColor-500 border-solid border rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group px-8 max-xl:px-4 py-[18px] max-sm:py-2.5  cursor-pointer z-[1] text-center'
            >
              <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
              {t('viewSubmitRoster')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RosterSection
