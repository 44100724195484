import { createSlice } from '@reduxjs/toolkit'
import { gameClose, userLogin, userLogout } from '../../redux/thunk/Auth/Auth'
import { fetchUserInformation } from '../../redux/thunk/Users/User'
import { getPayloadFromToken } from '../../../utils/common-functions'

const defaultAuthState = {
  userInfo: null,
  userDetails: null,
  currencyId: null,
  currencyCode: null,
  isLoggedIn: false
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState: defaultAuthState,
  reducers: {
    setUserInfo: (state, action) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        wallet: action.payload
      }
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        const { user, wallet, accessToken } = action.payload
        const payload = getPayloadFromToken(accessToken)
        const currencyId = payload?.currencyId
        const currencyCode = payload?.currencyCode
        return {
          ...state,
          currencyId,
          currencyCode,
          userInfo: { user, wallet },
          isLoggedIn: true
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          userDetails: action.payload.user
        }
      })
      .addCase(gameClose.fulfilled, (state, action) => {
        return defaultAuthState
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...defaultAuthState
        }
      })
  }
})

export const { setUserInfo } = userSlice.actions

export default userSlice.reducer
