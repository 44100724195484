import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { useEffect, useState } from 'react'
import { PAGINATION_LIMIT } from '../../../utils/constants'
import {
  getBucketList,
  getBucketPlayerList
} from '../../../redux-store/redux/thunk/Lobby/Lobby'
import { useHistory } from 'react-router-dom'
import { clearRosterSlice } from '../../../redux-store/redux-slices/Roster/rosterSlice'
import { useTranslation } from 'react-i18next'

export const useLobbyController = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentOffset, setCurrentOffset] = useState(0)
  const [showPagination, setShowPagination] = useState(false)
  const [showNextBtn, setShowNextBtn] = useState(true)
  const { bucketList } = useSelector((state) => state.lobbySlice)
  const { addToast } = useToasts()
  const [showEntryDetailsModal, setShowEntryDetailsModal] = useState(false)
  const [numOfWinnerModal, setNumOfWinnerModal] = useState(false)
  const { userDetails } = useSelector((state) => state.userSlice)
  const [bucketDetails, setBucketDetails] = useState(null)
  const [currentOffsetPopup, setCurrentOffsetPopup] = useState(0)
  const [showPaginationPopup, setShowPaginationPopup] = useState(false)
  const [showNextBtnPopup, setShowNextBtnPopup] = useState(true)
  const [bucketUsers, setBucketUsers] = useState([])
  const { timeSettings } = useSelector((state) => state.gameSetting)
  const [loader, setLoader] = useState(false)
  const [bucketListLoader, setBucketListLoader] = useState(false)
  const [isContestBegins, setIsContestBegins] = useState(false)
  const [isBucketDisable, setIsBucketDisable] = useState(true)
  const { t } = useTranslation('lobby')

  const onBucketListSuccess = () => {
    setBucketListLoader(false)
  }

  const onBucketListError = () => {
    setBucketListLoader(false)
  }

  useEffect(async () => {
    setBucketListLoader(true)
    const params = {
      limit: PAGINATION_LIMIT,
      offset: currentOffset
    }
    dispatch(
      getBucketList({
        params,
        onBucketListSuccess,
        onBucketListError,
        addToast
      })
    )
  }, [])

  useEffect(() => {
    if (bucketList?.rows?.length || currentOffset > 0) {
      setShowPagination(true)
      if (bucketList?.rows?.length < PAGINATION_LIMIT) {
        setShowNextBtn(true)
      } else {
        setShowNextBtn(false)
      }
    } else {
      setShowPagination(false)
    }
  }, [bucketList])

  useEffect(() => {
    if (bucketUsers?.row?.length || currentOffsetPopup > 0) {
      setShowPaginationPopup(true)
      if (bucketUsers?.row?.length < 21) {
        setShowNextBtnPopup(true)
      } else {
        setShowNextBtnPopup(false)
      }
    } else {
      setShowPaginationPopup(false)
    }
  }, [bucketUsers])

  const onPageChange = async (offsetData) => {
    setBucketListLoader(true)
    const params = {
      limit: PAGINATION_LIMIT,
      offset: offsetData
    }
    dispatch(
      getBucketList({
        params,
        onBucketListSuccess,
        onBucketListError,
        addToast
      })
    )

    setCurrentOffset(offsetData)
  }

  const onPageChangePopup = async (offsetData) => {
    const params = {
      bucketId: bucketDetails?.id,
      limit: 21,
      offset: offsetData
    }
    dispatch(getBucketPlayerList({ params, onSuccess, onError, addToast }))

    setCurrentOffsetPopup(offsetData)
  }

  const onSuccess = (res) => {
    setBucketUsers(res)
    setLoader(false)
  }

  const onError = () => {
    setLoader(false)
  }

  const handleShowEntryDetails = async (value, bucketData) => {
    setLoader(true)
    setBucketDetails(bucketData)
    setShowEntryDetailsModal(value)
    const params = {
      bucketId: bucketData?.id,
      limit: 21,
      offset: currentOffsetPopup
    }
    dispatch(getBucketPlayerList({ params, onSuccess, onError, addToast }))
  }
  const handleShowNumOfWinners = async (value, bucketData) => {
    setBucketDetails(bucketData)
    setNumOfWinnerModal(value)
  }
  const handleBucketEnter = (item) => {
    dispatch(clearRosterSlice())
    history.push(`/player-selection/${item?.bucketUid}`)
  }

  const tableHeadingList = [
    {
      name: t('sport')
    },
    {
      name: t('contestName')
    },
    {
      name: t('contestDate')
    },
    {
      name: t('contestRemainingTime')
    },
    {
      name: t('entryFee')
    },
    {
      name: t('numberOfWinners')
    },
    {
      name: t('numberOfEntries')
    }
  ]

  return {
    t,
    currentOffset,
    showPagination,
    showNextBtn,
    bucketList,
    showEntryDetailsModal,
    userDetails,
    bucketDetails,
    currentOffsetPopup,
    showPaginationPopup,
    showNextBtnPopup,
    bucketUsers,
    timeSettings,
    tableHeadingList,
    loader,
    bucketListLoader,
    isContestBegins,
    isBucketDisable,
    setIsBucketDisable,
    setIsContestBegins,
    setCurrentOffset,
    setShowPagination,
    setShowNextBtn,
    setShowEntryDetailsModal,
    setShowPaginationPopup,
    setShowNextBtnPopup,
    setBucketUsers,
    handleShowEntryDetails,
    onPageChange,
    onPageChangePopup,
    handleBucketEnter,
    handleShowNumOfWinners,
    numOfWinnerModal,
    setNumOfWinnerModal
  }
}
