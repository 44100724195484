import React, { useEffect, useState } from 'react'
import './index.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getLotteryInfo } from '../../redux-store/redux/thunk/gameSetting'

const CountdownTimer = ({t}) => {
  const [currentTime, setCurrentTime] = useState(moment().format('HH:mm:ssZ'))
  const [intervalId, setIntervalId] = useState(null)
  const { lotteryInfo } = useSelector((state) => state.gameSetting)
  const { timeSettings } = useSelector((state) => state.gameSetting)
  const [showLotteryInfo, setShowLotteryInfo] = useState(false)
  const dispatch = useDispatch()
  const lotteryNum =
    lotteryInfo?.lotteryNumber?.lottery_number !== null
      ? lotteryInfo?.lotteryNumber?.lottery_number?.split('')
      : null

  const isAfter = (startTime, endTime) => {
    return moment(moment(startTime, 'HH:mm:ssZ')
        .local().format('HH:mm:ssZ'), 'HH:mm:ssZ')
        .isAfter(moment(moment(endTime, 'HH:mm:ssZ').local().format('HH:mm:ssZ'), 'HH:mm:ssZ'))
    }
    
    const isBefore = (startTime, endTime) => {
      return moment(moment(startTime, 'HH:mm:ssZ')
        .local().format('HH:mm:ssZ'), 'HH:mm:ssZ')
        .isBefore(moment(moment(endTime, 'HH:mm:ssZ').local().format('HH:mm:ssZ'), 'HH:mm:ssZ'))
    }
    
    const isBetween = (curTime, startTime, endTime) => {
      return moment(curTime, 'HH:mm:ssZ').isBetween(
        moment(moment(startTime, 'HH:mm:ssZ').local().format('HH:mm:ssZ'), 'HH:mm:ssZ'),
        moment(moment(endTime, 'HH:mm:ssZ').local().format('HH:mm:ssZ'), 'HH:mm:ssZ'),
        null,
        '[]'
      )
    }

    const onSuccess = () => {
      setShowLotteryInfo(true)
    }

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentTime(moment().format('HH:mm:ssZ'))
    })
    setIntervalId(id)
    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    if (isBefore(timeSettings?.startTime, lotteryInfo?.lotteryTime) &&
    !isBetween(currentTime, timeSettings?.startTime, lotteryInfo?.lotteryTime)
    ) {
      dispatch(getLotteryInfo(onSuccess))
      clearInterval(intervalId)
    } else if (isAfter(timeSettings?.startTime, lotteryInfo?.lotteryTime) &&
      isBetween(currentTime, lotteryInfo?.lotteryTime, timeSettings?.startTime)
    ) {
      dispatch(getLotteryInfo(onSuccess))
      clearInterval(intervalId)
    }
  }, [currentTime])

  return (
    <div className='timer-main flex gap-8 max-sm:gap-2'>
      <div className='[&>*:last-child]:mr-0 flex items-center p-3 max-sm:p-1 border border-dustyGray-500 border-solid rounded-lg leading-[0]'>

        <div>
        <div className='text-[10px] bg-primaryColor-800 leading-none p-1 font-normal text-center'>{t('careerYear')}</div>
          <div className='flex pt-2 justify-center'>
            <div className=' bg-primaryColor-300 text-white mr-1 overflow-hidden'>
              <div className='cus-bg-color text-[52px] max-xl:text-4xl max-sm:text-sm  py-2.5 max-xl:py-0.5 min-w-[48px] max-xl:min-w-[40px] max-sm:min-w-[16px] max-sm:h-[25px] text-center leading-none'>
                {!showLotteryInfo ? (
                  <div className='rolling-up block h-12'>
                    <div>0</div><div>8</div><div>6</div><div>3</div><div>1</div><div>5</div><div>7</div><div>4</div><div>9</div><div>2</div>
                  </div>
                ) : (
                  <div className='block h-12'>
                    <div>{lotteryNum?.[0]}</div>
                  </div>
                )}
              </div>
            </div>
            <div className=' bg-primaryColor-300 text-white overflow-hidden mr-1'>
              <div className='cus-bg-color min-w-[48px] max-xl:min-w-[40px] max-sm:min-w-[16px] max-sm:h-[25px] text-center leading-none text-[52px] max-xl:text-4xl max-sm:text-sm py-2.5 max-xl:py-0.5'>
                {!showLotteryInfo ? (
                  <div className='rolling-up block h-12'>
                    <div>7</div><div>9</div><div>2</div><div>0</div><div>6</div><div>1</div><div>3</div><div>8</div><div>4</div><div>5</div>
                  </div>
                ) : (
                  <div className='block h-12'>
                    <div>{lotteryNum?.[1]}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='[&>*:last-child]:mr-0 flex items-center p-3 max-sm:p-1 border border-dustyGray-500 border-solid rounded-lg leading-[0]'>

        <div>
        <div className='text-[10px] bg-primaryColor-800 leading-none p-1 font-normal text-center'>{t('gameNumber')}</div>
          <div className='flex pt-2 justify-center'>
            <div className=' bg-primaryColor-300 text-white mr-1  overflow-hidden'>
              <div className='cus-bg-color text-[52px] max-xl:text-4xl max-sm:text-sm py-2.5 max-xl:py-0.5 min-w-[48px] max-xl:min-w-[40px] max-sm:min-w-[16px] max-sm:h-[25px] text-center leading-none'>
                {!showLotteryInfo ? (
                  <div className='rolling-down block h-12'>
                    <div>6</div><div>1</div><div>3</div><div>7</div><div>0</div><div>2</div><div>9</div><div>4</div><div>8</div><div>5</div>
                  </div>
                ) : (
                  <div className='block h-12'>
                    <div>{lotteryNum?.[2]}</div>
                  </div>
                )}
              </div>
            </div>
            <div className=' bg-primaryColor-300 text-white mr-1 overflow-hidden'>
              <div className='cus-bg-color text-[52px] max-xl:text-4xl max-sm:text-sm py-2.5 max-xl:py-0.5 min-w-[48px] max-xl:min-w-[40px] max-sm:min-w-[16px] max-sm:h-[25px] text-center leading-none '>
                {!showLotteryInfo ? (
                  <div className='rolling-down block h-12'>
                    <div>9</div><div>2</div><div>7</div><div>3</div><div>0</div><div>4</div><div>6</div><div>5</div><div>8</div><div>1</div>
                  </div>
                ) : (
                  <div className='block h-12'>
                    <div>{lotteryNum?.[3]}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default CountdownTimer
