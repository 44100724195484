import homeEs from './home.json'
import HeaderEs from './header.json'
import LobbyEs from './lobby.json'
import PlayerSelectionEs from './playerSelection.json'
import landingPageEs from './landingPage.json'

const translationsEs = {
  home: homeEs,
  header: HeaderEs,
  lobby: LobbyEs,
  playerSelection: PlayerSelectionEs,
  landingPage: landingPageEs

}

export default translationsEs
