import i18n from 'i18next'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  loginService,
  signUpService,
  logoutService,
  updatePasswordService,
  forgetPasswordService,
  forgetVerifyPasswordService,
  verifyEmailService,
  recordUserDetails,
  resetPasswordService
} from '../../../../network/services/auth.service'
import {
  signIn,
  signOut
} from '../../../../utils/common-services/cookie.services'
import { handleLanguageChange } from '../../../redux-slices/Auth/authSlice'
import { fetchUserInformation } from '../Users/User'
import { setLoader } from '../../../redux-slices/Users/user'
import {
  LOADER_HANDLER_TYPES,
  METHOD_TYPES,
  ROUTE_PATHS
} from '../../../../utils/constants'
import axiosInstanceService, { microServices } from '../../../../network/apis'
import { SuccessMessage } from '../../../../helpers/messages'
import { getLotteryInfo, getTimeSetting } from '../gameSetting'
/**
 * User Login Thunk
 */

export const userLogin = createAsyncThunk(
  'user/login',
  async (
    { userNameOrEmail, password, onLoginSuccess, OnLoginError },
    thunkApi
  ) => {
    try {
      const res = await loginService({ userNameOrEmail, password })
      signIn({
        token: res.accessToken
      })
      onLoginSuccess(res)
      thunkApi.dispatch(fetchUserInformation())
      thunkApi.dispatch(getTimeSetting())
      thunkApi.dispatch(getLotteryInfo())
      thunkApi.dispatch(setLoader(false))
      return res
    } catch (error) {
      OnLoginError(error)
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const gameCloseService = () => {
  return axiosInstanceService(
    METHOD_TYPES.post,
    'user/game-close',
    {},
    {
      server: microServices.USER_URL,
      handlerEnabled: false,
      loader: LOADER_HANDLER_TYPES.submit,
      successMessage: SuccessMessage.gameClose
    }
  )
}

export const gameClose = createAsyncThunk(
  'user/game-close',
  async (data, thunkApi) => {
    try {
      const res = await gameCloseService()
      if (res) {
        signOut()
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const userSignUp = createAsyncThunk(
  'user/signup',
  async ({ userDetails, addToast, handleShowRegister,fromComingSoon,openSuccessPopup }, thunkApi) => {
    try {
      let data ={...userDetails}
      if(fromComingSoon){data.is_early_bird_signup = true}
      const res = await signUpService(data)
      thunkApi.dispatch(setLoader(false))
      addToast('Email Verification Link Sent.', { appearance: 'success' })
      handleShowRegister(false)
      if(fromComingSoon){
        openSuccessPopup()
      }
      // thunkApi.dispatch(setShowSignupPopup(false))
      // setShowPopup(true)
      if (Object.keys(res.additionalInfo).length) {
        i18n.changeLanguage(res.additionalInfo.languageCode)
        thunkApi.dispatch(handleLanguageChange(res.additionalInfo.languageCode))
      }
      return res
    } catch (error) {
      thunkApi.dispatch(setLoader(false))
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Operator Logout Thunk
 */
export const userLogout = createAsyncThunk(
  'user/logout',
  async ({ history, addToast }, thunkApi) => {
    try {
      const res = await logoutService()
      signOut()
      addToast('User Logout Successfully', { appearance: 'success' })
      // if (ethereumClient) {
      //   ethereumClient.disconnect()
      // }
      history.push(ROUTE_PATHS.HOME)
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Operator Change Password Thunk
 */
export const updatePassword = createAsyncThunk(
  'update/password',
  async ({ oldPassword, newPassword, onChangePasswordSuccess, addToast }, thunkApi) => {
    try {
      const res = await updatePasswordService({ oldPassword, newPassword })
      onChangePasswordSuccess()
      thunkApi.dispatch(setLoader(false))
      addToast('Password has been changed successfully', {
        appearance: 'success'
      })
      return res
    } catch (error) {
      thunkApi.dispatch(setLoader(false))
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const forgetPassword = createAsyncThunk(
  'forget/password',
  async ({ userNameOrEmail, addToast, onForgetPasswordSuccess }, thunkApi) => {
    try {
      thunkApi.dispatch(setLoader(true))
      const res = await forgetPasswordService({ userNameOrEmail })
      thunkApi.dispatch(setLoader(false))
      addToast('Token has been sent to your Email, please verify', {
        appearance: 'success'
      })
      onForgetPasswordSuccess()
      return res
    } catch (error) {
      thunkApi.dispatch(setLoader(false))
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const VerifyPasswordToken = createAsyncThunk(
  'verifyforget/password',
  async ({ params, addToast, onResetPasswordSuccess }, thunkApi) => {
    try {
      const res = await forgetVerifyPasswordService(params)
      thunkApi.dispatch(setLoader(false))
      addToast('Password has been changed', { appearance: 'success' })
      // thunkApi.dispatch(setShowLoginPopup(true))
      onResetPasswordSuccess()
      return res
    } catch (error) {
      thunkApi.dispatch(setLoader(false))
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const verifyEmail = createAsyncThunk(
  'verifyforget/password',
  async ({ param, addToast, history }, thunkApi) => {
    try {
      const res = await verifyEmailService(param)
      addToast('Email has been verified', { appearance: 'success' })
      // history.push('/')
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateUserEmail = createAsyncThunk(
  'user/record-user-detail',
  async ({ param, addToast, reset }, thunkApi) => {
    try {
      const res = await recordUserDetails(param)
      addToast('Early admission done.', { appearance: 'success' })
      reset()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'user/reset-password',
  async ({ param, addToast, onResetPasswordSuccess, OnResetPasswordError }, thunkApi) => {
    try {
      const res = await resetPasswordService(param)
      onResetPasswordSuccess()
      return res
    } catch (error) {
      OnResetPasswordError(error)
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
