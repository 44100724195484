import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import globeIcon from '../../assets/image/globe.png'
import { Link } from 'react-router-dom'
import { ArrowDown } from '../../assets/image'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { handleLanguageChange } from '../../redux-store/redux-slices/Auth/authSlice'
import { LANGUAGES } from '../../utils/constants/index'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Dropdown = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.authSlice)
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-primaryColor-900 p-2 text-xs font-semibold text-primaryColor-500 border-[1px] border-solid border-primaryColor-400'>
          <img src={globeIcon} alt='' /> {LANGUAGES?.find(({languageCode})=> languageCode === language)?.label}
          <ArrowDown className='w-3 h-3' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-primaryColor-900 border-[1px] border-solid border-primaryColor-400'>
          <div className='py-1 divide-y-[1px] divide-primaryColor-350'>
            {LANGUAGES.map((info, index) => (
              <Menu.Item key={info.languageCode}>
                {({ active }) => (
                  <Link
                    to='#'
                    onClick={() => {
                      i18n.changeLanguage(info.languageCode)
                      dispatch(handleLanguageChange(info.languageCode))
                    }}
                    className={classNames(
                      active
                        ? 'bg-primaryColor-200 text-primaryColor-500'
                        : 'text-primaryColor-500',
                      'block px-2 py-1 text-xs font-semibold'
                    )}
                  >
                    {info.label}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown
