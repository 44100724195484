import {
  Dollar,
  PodiumBoxLeft,
  PodiumBoxMiddle,
  PodiumBoxRight,
  PodiumBronze,
  PodiumCarpetLeft,
  PodiumCarpetMiddle,
  PodiumCarpetRight,
  PodiumGold,
  PodiumSilver
} from '../../icons'
import { UserIcon } from '../../assets/image'
import './index.scss'
import { useSelector } from 'react-redux'

const Podium = ({ winners }) => {
  const { userDetails } = useSelector((state) => state.userSlice)

  return (
    <>
      <div className='wrapper max-lg:gap-[34px] max-md:gap-6 max-sm:gap-4 max-tabletscreen:gap-[6px]'>
        <div className='podium-container2 max-lg:!static'>
          <div className='podium-player2 max-lg:!static'>
            <div className='rank2-text max-lg:!ml-0 max-lg:!top-[50px] max-sm:!text-xl'>
              <p>2</p>
            </div>
            <div className='img-box2 max-lg:!ml-0'>
              <span
                className={`relative before:absolute before:inset-0 before:rounded-full before:w-full before:h-full ${
                  winners?.[1]?.user_id === userDetails?.id &&
                  'before:border-t-2 max-sm:before:border-t-[1px] before:border-r-2 max-sm:before:border-r-[1px] before:border-solid before:border-white before:animate-borderRotate'
                }`}
              >
                {winners?.[1]?.User?.image ? (
                  <img
                    src={winners?.[1]?.User?.image}
                    className='img2 w-[65px] max-sm:w-[35px] h-[65px] max-sm:h-[35px] rounded-full'
                  />
                ) : (
                  <UserIcon className='img2 w-[65px] max-sm:w-[35px] h-[65px] max-sm:h-[35px] rounded-full' />
                )}
              </span>
            </div>
            <div className='icon-silver max-lg:!ml-0'>
              <PodiumSilver />
            </div>
            <p className='podium-text2 flex items-center justify-center text-white text-sm ml-[100px] font-bold pb-2.5 capitalize max-lg:ml-0'>
              {winners?.[1]?.User?.firstName} {winners?.[1]?.User?.lastName}
            </p>
          </div>
          <div className='podium-section max-lg:!static'>
            <div className='podium2 max-lg:!hidden'>
              <img src='/assets/images/resultPage/podium-box-left.png' />
              {/* <PodiumBoxLeft /> */}
            </div>
            <div className='podium-carpet-left max-lg:hidden'>
              <img src='/assets/images/resultPage/podium-carpet-left.png' />
              {/* <PodiumCarpetLeft /> */}
            </div>
          </div>
          <div className='box-inner2 podium2 max-lg:!static'>
            <Dollar className='w-5 max-sm:w-[14px]' />
            <div className='box-inner-text max-sm:text-xs'>
              {winners?.[1]?.prize}
            </div>
          </div>
          <div className='box-inner-point2 podium2 max-lg:!static text-base max-sm:text-xs'>
            {winners?.[1]?.totalPoints &&
              parseFloat(winners?.[1]?.totalPoints).toFixed(2)}
          </div>
        </div>
        <div className='podium-container1 max-lg:!static'>
          <div className='podium-player1 max-lg:!static'>
            <div className='rank1-text max-lg:!top-[50px] max-sm:!text-xl'>
              <p>1</p>
            </div>
            <div className='img-box1'>
              <span
                className={`relative before:absolute before:inset-0 before:rounded-full before:w-full before:h-full ${
                  winners?.[0]?.user_id === userDetails?.id &&
                  'before:border-t-2 max-sm:before:border-t-[1px] before:border-r-2 max-sm:before:border-r-[1px] before:border-solid before:border-white before:animate-borderRotate'
                }`}
              >
                {winners?.[0]?.User?.image ? (
                  <img
                    src={winners?.[0]?.User?.image}
                    className='img1 w-[65px] max-sm:w-[35px] h-[65px] max-sm:h-[35px] rounded-full'
                  />
                ) : (
                  <UserIcon className='img1 w-[65px] max-sm:w-[35px] h-[65px] max-sm:h-[35px] rounded-full' />
                )}
              </span>
            </div>
            <div className='icon-gold max-lg:!static'>
              <PodiumGold />
            </div>
            <p className='podium-text1 flex items-center justify-center text-white text-sm font-bold pb-2.5 capitalize'>
              {winners?.[0]?.User?.firstName} {winners?.[0]?.User?.lastName}
            </p>
          </div>
          <div className='podium-section max-lg:!static'>
            <div className='podium1 max-lg:!hidden'>
              <img src='/assets/images/resultPage/podium-box-middle.png' />
              {/* <PodiumBoxMiddle /> */}
            </div>
            <div className='podium-carpet-middle max-lg:hidden'>
              <img src='/assets/images/resultPage/podium-carpet-middle.png' />
              {/* <PodiumCarpetMiddle /> */}
            </div>
          </div>
          <div className='box-inner1 podium1 max-lg:!static'>
            <Dollar className='w-5 max-sm:w-[14px]' />
            <div className='box-inner-text max-sm:text-xs'>
              {winners?.[0]?.prize}
            </div>
          </div>
          <div className='box-inner-point1 podium1 max-lg:!static text-base max-sm:text-xs'>
            {winners?.[0]?.totalPoints &&
              parseFloat(winners?.[0]?.totalPoints).toFixed(2)}
          </div>
        </div>
        <div className='podium-container3 max-lg:!static'>
          <div className='podium-player3 max-lg:!static'>
            <div className='rank3-text max-lg:!mr-0 max-lg:!top-[50px] max-sm:!text-xl'>
              <p>3</p>
            </div>
            <div className='img-box3 max-lg:!mr-0'>
              <span
                className={`relative before:absolute before:inset-0 before:rounded-full before:w-full before:h-full ${
                  winners?.[2]?.user_id === userDetails?.id &&
                  'before:border-t-2 max-sm:before:border-t-[1px] before:border-r-2 max-sm:before:border-r-[1px] before:border-solid before:border-white before:animate-borderRotate'
                }`}
              >
                {winners?.[2]?.User?.image ? (
                  <img
                    src={winners?.[2]?.User?.image}
                    className='img3 w-[65px] max-sm:w-[35px] h-[65px] max-sm:h-[35px] rounded-full'
                  />
                ) : (
                  <UserIcon className='img3 w-[65px] max-sm:w-[35px] h-[65px] max-sm:h-[35px] rounded-full' />
                )}
              </span>
            </div>
            <div className='icon-bronze max-lg:!mr-0'>
              <PodiumBronze />
            </div>
            <p className='podium-text3 flex items-center justify-center text-white text-sm mr-[100px] font-bold pb-2.5 capitalize max-lg:mr-0'>
              {winners?.[2]?.User?.firstName} {winners?.[2]?.User?.lastName}
            </p>
          </div>
          <div className='podium-section max-lg:!static'>
            <div className='podium3 max-lg:!hidden'>
              <img src='/assets/images/resultPage/podium-box-right.png' />
              {/* <PodiumBoxRight /> */}
            </div>
            <div className='podium-carpet-right max-lg:hidden'>
              <img src='/assets/images/resultPage/podium-carpet-right.png' />
              {/* <PodiumCarpetRight /> */}
            </div>
          </div>
          <div className='box-inner3 podium3 max-lg:!static'>
            <Dollar className='w-5 max-sm:w-[14px]' />
            <div className='box-inner-text max-sm:text-xs'>
              {winners?.[2]?.prize}
            </div>
          </div>
          <div className='box-inner-point3 podium3 max-lg:!static text-base max-sm:text-xs'>
            {winners?.[2]?.totalPoints &&
              parseFloat(winners?.[2]?.totalPoints).toFixed(2)}
          </div>
        </div>
      </div>
    </>
  )
}

export default Podium
