import { createAsyncThunk } from '@reduxjs/toolkit'
import { addCommentService, getPlayerBySearchService, getPlayerInfoService, getPlayerListService, getSpotlightDataService, getTopPickPlayerService, nominateLegendService } from '../../../../network/services/player.service'

export const fetchPlayerInformation = createAsyncThunk(
  'players/details',
  async (data, thunkApi) => {
    try {
      const res = await getPlayerInfoService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getPlayerList = createAsyncThunk(
  'player/list',
  async ({params, addToast, onPlayerListSuccess, onPlayerListError}, thunkApi) => {
    try {
      const res = await getPlayerListService(params)
      onPlayerListSuccess(res)
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      onPlayerListError()

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
export const getSpotlightData = createAsyncThunk(
  'player/spot-light',
  async (params, addToast, thunkApi) => {
    try {
      const res = await getSpotlightDataService(params)
      // onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
export const submitLegendNominate = createAsyncThunk(
  'player/nominate-player',
  async ({ params, addToast,onSuccess }, thunkApi) => {
    try {
      const res = await nominateLegendService(params, addToast)
      addToast('Player nominated', { appearance: 'success' })
      onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const submitComment = createAsyncThunk(
  'player/comment',
  async ({ params, addToast,onSuccess }, thunkApi) => {
    try {
      const res = await addCommentService(params, addToast)
      addToast('Comment added', { appearance: 'success' })
      onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const searchPlayer = createAsyncThunk(
  'player/players-list',
  async (params, thunkApi) => {
    console.log("heree")
    try {
      const res = await getPlayerBySearchService(params)
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
  export const getTopPicks = createAsyncThunk(
    'player/top-pick-player',
    async (thunkApi) => {
      try {
        const res = await getTopPickPlayerService()
        //  thunkApi.dispatch(setSystemData(res))
        return res
      } catch (error) {
        return thunkApi.rejectWithValue(error[0].description)
      }
    }
  )