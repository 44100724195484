import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ArrowDown, CalendarIcon } from '../../assets/image'
import { useDispatch, useSelector } from 'react-redux'
import { getTransactionList } from '../../redux-store/redux/thunk/Transaction/Transaction'
import { useToasts } from 'react-toast-notifications'
import { PAGINATION_LIMIT } from '../../utils/constants'
import Pagination from '../../components/Pagination'
import { moment } from '../../config/moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { dateTimeFormat } from '../../utils/common-functions'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'

const Transactions = () => {
  const { t } = useTranslation('home')
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: t('all')
  })
  const [selectedDate, setSelectedDate] = useState(null)
  const dispatch = useDispatch()
  const [currentOffset, setCurrentOffset] = useState(0)
  const [showPagination, setShowPagination] = useState(false)
  const [showNextBtn, setShowNextBtn] = useState(true)
  const { transactionList } = useSelector((state) => state.transactionSlice)
  const { addToast } = useToasts()
  const [loader, setLoader] = useState(false)

  const onTransactionListSuccess = () => {
    setLoader(false)
  }

  const onTransactionListError = () => {
    setLoader(false)
  }

  useEffect(async () => {
    setLoader(true)
    const params = {
      limit: PAGINATION_LIMIT,
      offset: currentOffset,
      transactionType: ''
    }
    dispatch(
      getTransactionList({
        params,
        onTransactionListSuccess,
        onTransactionListError,
        addToast
      })
    )
  }, [])

  useEffect(() => {
    if (transactionList?.rows?.length || currentOffset > 0) {
      setShowPagination(true)
      if (transactionList?.rows?.length < PAGINATION_LIMIT) {
        setShowNextBtn(true)
      } else {
        setShowNextBtn(false)
      }
    } else {
      setShowPagination(false)
    }
  }, [transactionList])

  const onPageChange = async (offsetData) => {
    setLoader(true)
    const params = {
      limit: PAGINATION_LIMIT,
      offset: offsetData
    }
    dispatch(
      getTransactionList({
        params,
        onTransactionListSuccess,
        onTransactionListSuccess,
        addToast
      })
    )

    setCurrentOffset(offsetData)
  }

  const handleTransactionList = () => {
    if (selectedDate === null) {
      setLoader(true)
      const params = {
        limit: PAGINATION_LIMIT,
        offset: currentOffset,
        transactionType: selectedOption?.value,
        transactionDate: ''
      }
      dispatch(
        getTransactionList({
          params,
          onTransactionListSuccess,
          onTransactionListError,
          addToast
        })
      )
    } else {
      setLoader(true)
      const date = moment(selectedDate).format('YYYY-MM-DD')
      const params = {
        limit: PAGINATION_LIMIT,
        offset: currentOffset,
        transactionType: selectedOption?.value,
        transactionDate: date
      }
      dispatch(
        getTransactionList({
          params,
          onTransactionListSuccess,
          onTransactionListError,
          addToast
        })
      )
    }
  }

  const handleReset = () => {
    setLoader(true)
    setSelectedOption({ value: '', label: t('all') })
    setSelectedDate(null)
    const params = {
      limit: PAGINATION_LIMIT,
      offset: currentOffset,
      transactionType: ''
    }
    dispatch(
      getTransactionList({
        params,
        onTransactionListSuccess,
        onTransactionListError,
        addToast
      })
    )
  }

  const handleDateSelect = (e) => {
    setSelectedDate(e)
  }

  const options = [
    { value: '', label: t('all') },
    { value: 'withdraw', label: t('withdraw') },
    { value: 'deposit', label: t('deposit') },
    { value: 'bucket_purchase', label: t('bucketPurchase') },
    { value: 'winner', label: t('winner') }
  ]

  return (
    <div className='max-w-[1320px] mx-auto pt-10 pb-[70px] min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
      <div>
        <h1 className='flex justify-center items-center overflow-hidden'>
          <span className='text-[22px] px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full'>
            {t('transactions')}
          </span>
        </h1>
      </div>
      <div className='mt-8'>
        <div className='my-6 flex flex-wrap lg:flex-nowrap gap-4 items-end'>
          <div className='flex items-center gap-4 z-[2] flex-wrap'>
            <div className='min-w-[251px] max-sm:w-full'>
              <label
                className='block block text-base font-medium text-white pb-2'
                for='grid-state'
              >
                {t('selectType')}
              </label>
              <div className='relative'>
                <Select
                  isSearchable={false}
                  placeholder={t('all')}
                  className='react-border-select'
                  classNamePrefix='react-border-inner'
                  defaultValue={selectedOption}
                  value={selectedOption}
                  onChange={(item) => setSelectedOption(item)}
                  options={options}
                />
                <ArrowDown className='absolute top-1/2 right-4 transform translate-y-[-50%] pointer-events-none' />
              </div>
            </div>
            <div className='relative min-w-[251px] max-sm:w-full [&>.react-datepicker-wrapper]:w-full'>
              <label
                className='block block text-base font-medium text-white pb-2'
                for='grid-state'
              >
                {t('selectDate')}
              </label>
              <DatePicker
                showIcon
                icon={
                  <CalendarIcon className='absolute top-1/2 right-4 transform translate-y-[-50%] pointer-events-none' />
                }
                placeholderText='MM-DD-YYYY'
                dateFormat='MM-dd-yyyy'
                maxDate={new Date()}
                selected={selectedDate}
                onChange={(e) => handleDateSelect(e)}
                className='datePicker block h-[42px] w-full px-[18px] py-[10px] text-sm tabletscreen:text-base text-primaryColor-500 border border-primaryColor-500 rounded-lg bg-transparent focus-visible:border-primaryColor-500 focus-visible:outline-none placeholder:text-primaryColor-500 !leading-none'
              />
            </div>
          </div>

          <div className='flex items-center gap-4'>
            <button
              onClick={handleTransactionList}
              className='text-base leading-none font-normal capitalize border-primaryColor-500 border-solid border px-8 py-[12px] rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
            >
              <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
              {t('show')}
            </button>

            <button
              onClick={handleReset}
              class='text-base leading-none font-normal capitalize border-primaryColor-500 border-solid border px-8 py-[12px] rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'
            >
              <span class='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
              {t('reset')}
            </button>
          </div>
        </div>
      </div>

      <div className='bg-primaryColor-200 rounded-3xl p-4'>
        <div className='overflow-x-auto'>
          <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
            <thead className='ltr:text-left rtl:text-right p-2 lg:table-header-group hidden'>
              <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-lg [&>th]:p-4'>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('date')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('amount')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('TXID')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('type')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('status')}
                </th>
              </tr>
            </thead>

            {loader ? (
              <tr>
                <td colSpan='5'>
                  <Loader />
                </td>
              </tr>
            ) : (
              <tbody className='[&>tr]:mb-4 [&>tr]:lg:mb-0 [&>*:last-child]:mb-0 lg:divide-y  lg:divide-primaryColor-350 divide-solid '>
                {transactionList?.rows?.length > 0 ? (
                  transactionList.rows.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className='[&>td]:py-6 [&>td]:lg:pl-1 flex flex-col items-start lg:table-row bg-primaryColor-100 lg:bg-transparent rounded-2xl lg:rounded-none max-lg:border-primaryColor-500 max-lg:border max-lg:border-solid'
                      >
                        {/* <td className="text-center lg:text-start pl-[50%] tabletscreen:whitespace-nowrap px-1 text-white text-base tabletscreen:text-lg lg:text-xl font-semibold w-full relative lg:w-auto before:content-['Bucket_Name'] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                        {item?.bucketInfo?.bucketUid}
                      </td> */}

                        <td dynamic-heading-value={t('date')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                          {item?.createdAt &&
                            dateTimeFormat(item?.createdAt, '--')}
                        </td>

                        <td dynamic-heading-value={t('amount')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-white font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                          <div className='flex items-start justify-center gap-1'>
                            $ <span>{item?.amount}</span>
                            <div className='has-tooltip transition-all delay-100 ease-linear cursor-pointer'>
                              <div className='text-sm tooltip rounded-lg shadow-lg p-3 bg-primaryColor-200 text-primaryColor-950 -mt-12 transition-all delay-100 ease-linear max-lg:right-[70px] max-w-[260px] ml-6'>
                                <div className=''>{t('bonus')}</div>
                              </div>
                              <div>
                                {item?.isNonCashAmount &&
                                  ` ( $ ${item?.nonCashAmount} )`}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td dynamic-heading-value={t('TXID')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                          {item?.transactionId}
                        </td>

                        <td dynamic-heading-value={t('type')} className="text-center pl-[50%] text-base tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                          {item?.transactionType == 1 ? (
                            <div className='flex flex-col'>
                              <span>{t('bucketPurchase')}</span>
                              {item?.bucketInfo?.bucketUid && (
                                <span>({item?.bucketInfo?.bucketUid})</span>
                              )}
                            </div>
                          ) : item?.transactionType == 2 ? (
                            t('withdrawCancel')
                          ) : item?.transactionType == 3 ? (
                            t('deposit')
                          ) : item?.transactionType == 4 ? (
                            t('withdraw')
                          ) : item?.transactionType == 5 ? (
                            t('winner')
                          ) : (
                            ''
                          )}
                        </td>

                        <td className='tabletscreen:whitespace-nowrap px-1 lg:w-auto w-full flex justify-center'>
                          <span
                            className={`${
                              item?.status == 'success'
                                ? 'statusSuccess'
                                : 'statusFailed'
                            } capitalize`}
                          >
                            {t(item.status)}
                          </span>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td
                      colSpan='5'
                      className='text-white text-center text-lg pt-4 max-lg:pt-0'
                    >
                      {t('noDataAvailable')}
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {showPagination && (
        <Pagination
          currentOffset={currentOffset}
          onPageChange={onPageChange}
          disableNext={showNextBtn}
          itemCount={transactionList?.count}
        />
      )}
    </div>
  )
}

export default Transactions
