import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { setGameSettingLoader } from '../../../redux-store/redux-slices/gameSetting'
import { setContactUs } from '../../../redux-store/redux/thunk/gameSetting'
export const useContactUsController = () => {
    const dispatch = useDispatch()
    const { gameSettingLoader } = useSelector(state => state.gameSetting)
    const { addToast } = useToasts()
    const { t } = useTranslation('home')
    const handleContactUsSubmit = useCallback(async (data) => {
        try {
            dispatch(setGameSettingLoader(true))
            dispatch(
                setContactUs({
                    data,
                    addToast,
                    reset
                })
            )
        } catch (e) {
            addToast(e.message, { appearance: 'error' })
        }
    }, [addToast])
    const schema = yup.object().shape({
        firstName: yup
            .string()
            .required(`${t('signup.errors.firstName.required')}`)
            .min(2, `${t('signup.errors.firstName.minLength')}`)
            .max(45, `${t('signup.errors.firstName.maxLength')}`)
            .matches(/^[A-Za-z]+$/, t('signup.errors.firstName.pattern')),
        lastName: yup
            .string()
            .required(`${t('signup.errors.lastName.required')}`)
            .min(2, `${t('signup.errors.lastName.minLength')}`)
            .max(45, `${t('signup.errors.lastName.maxLength')}`)
            .matches(/^[A-Za-z]+$/, t('signup.errors.lastName.pattern')),
        email: yup
            .string()
            .required(t('signup.errors.email.required'))
            .max(255, `${t('signup.errors.email.maxLength')}`)
            .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                t('signup.errors.email.pattern')
            ),
        contact: yup
            .string()
            .required(`${t('contactus.errors.contactNumber.required')}`)
            .matches(
                /^\+(?:[0-9] ?){6,14}[0-9]$/,
                t('signup.errors.phone.pattern')
              ),
        description: yup
            .string()
            .required(`${t('contactus.errors.message.required')}`),
    })

    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        watch,
        resetField,
        setValue,
        trigger
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all'
    })

    const handleMobileNumberChange = (e) => {
        setValue('contact',e)
        trigger()
      }

    return {
        register,
        errors,
        loading:gameSettingLoader,
        handleContactUsSubmit,
        handleSubmit,
        handleMobileNumberChange,
        reset,
        watch
    }
}