import { lazy } from 'react'
import Cms from '../containers/Cms'
import ContactUs from '../containers/ContactUs'
import DnpInsurance from '../containers/DnpInsurance'
import LandingPageContainer from '../containers/LandingPageContainer'
import Lobby from '../containers/Lobby'
import MyAccount from '../containers/MyAccount'
import PlayerDetail from '../containers/PlayerDetail'
import PlayerSelection from '../containers/PlayerSelection'
import ViewRoster from '../containers/PlayerSelection/components/ViewRoster'
import PlayerSpotlight from '../containers/PlayerSpotlight'
import ResultDetials from '../containers/TodayResult/components/ResultDetails'
import TodayResult from '../containers/TodayResult'
import Transactions from '../containers/Transaction'
import { ROUTE_PATHS } from '../utils/constants'
import Payment from '../containers/Payment'
import Withdrawal from '../containers/Withdrawal'
import ComingSoon from '../containers/ComingSoon'
import EarlyBirdAuth from '../containers/EarlyBirdAuth'
const Home = lazy(() => import('../containers/Home'))

const routesList = [
  {
    path: ROUTE_PATHS.HOME,
    component: LandingPageContainer,
    isPrivate: false,
    showHeader: false,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.LOGIN,
    showHeader: true,
    showFooter: true,
    component: Home
  },
  {
    path: ROUTE_PATHS.SIGNUP,
    showHeader: true,
    showFooter: true,
    component: Home
  },
  {
    path: ROUTE_PATHS.VERIFY_EMAIL,
    component: LandingPageContainer,
    isPrivate: false,
    showHeader: false,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    component: LandingPageContainer,
    isPrivate: false,
    showHeader: false,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PLAYERSELECTION,
    component: PlayerSelection,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.MYACCOUNT,
    component: MyAccount,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.LOBBY,
    component: Lobby,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.TODAYRESULT,
    component: TodayResult,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.RESULTDETAILS,
    component: ResultDetials,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PLAYERDETAIL,
    component: PlayerDetail,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PLAYERSPOTLIGHT,
    component: PlayerSpotlight,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.DNPINSURANCE,
    component: DnpInsurance,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  // {
  //   path: ROUTE_PATHS.HOWTOPLAY,
  //   component: HowToPlay,
  //   isPrivate: false,
  //   showHeader: true,
  //   showFooter: true
  // },
  {
    path: ROUTE_PATHS.CONTACTUS,
    component: ContactUs,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PAYMENT,
    component: Payment,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.TRANSACTIONS,
    component: Transactions,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.CMS,
    component: Cms,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.VIEW_ROSTER_DETAILS,
    component: ViewRoster,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.WITHDRAWAL,
    component: Withdrawal,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.COMINGSOON,
    component: ComingSoon,
    isPrivate: false,
    showHeader: false,
    showFooter: false
  },
  {
    path: ROUTE_PATHS.EARLY_BIRD,
    component: EarlyBirdAuth,
    isPrivate: false,
    showHeader: false,
    showFooter: false
  }
]

export default routesList
