import { createSlice } from '@reduxjs/toolkit'
import { getBucketWinner, getResultList } from '../../redux/thunk/Result/Result'

const initialState = {
   resultList:[],
   winnerData:null,
   loading:false,
   activeTab: 1
}

const {
  actions: {
    setActiveTab
  },
  reducer
} = createSlice({
  name: 'resultSlice',
  initialState,
  reducers: {
    setActiveTab: (state, action) => ({
      ...state,
      activeTab: action.payload
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResultList.fulfilled, (state, action) => {
        return {
          ...state,
          resultList: action.payload,
          loading:false
        }
      })
      .addCase(getResultList.pending, (state, action) => {
        return {
          ...state,
          resultList: action.payload,
          loading:true
        }
      })
      .addCase(getResultList.rejected, (state, action) => {
        return {
          ...state,
          resultList: action.payload,
          loading:false
        }
      })
      .addCase(getBucketWinner.fulfilled, (state, action) => {
        return {
          ...state,
          winnerData: action.payload,
          loading:false
        }
      })
      .addCase(getBucketWinner.pending, (state, action) => {
        return {
          ...state,
          winnerData: action.payload,
          loading:true
        }
      })
      .addCase(getBucketWinner.rejected, (state, action) => {
        return {
          ...state,
          winnerData: action.payload,
          loading:false
        }
      })
    }
})

export default reducer
export {
  setActiveTab
}
