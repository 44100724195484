import React, { useRef, useState } from 'react'
import {
  PlayButton,
  PlusIcon,
  SendIcon,
  UserIcon,
  WideIcon
} from '../../assets/image'
import viewersImg from '../../assets/image/defaultUser.png'
import { usePlayerSpotlightController } from './controller/usePlayerSpotlightController'
import {
  convertToEmbedUrl,
  dateTimeFormat,
  toFixed
} from '../../utils/common-functions'
import TimeAgo from 'react-timeago'
import englishStrings from 'react-timeago/lib/language-strings/en'
import spainStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { useSelector } from 'react-redux'
const PlayerSpotlight = (props) => {
  const [activeTab, setActiveTab] = useState('current')
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const { language } = useSelector((state) => state.authSlice)

  const formatter =
    language === 'es'
      ? buildFormatter(spainStrings)
      : buildFormatter(englishStrings)

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime)
  }

  const handleSeek = (e) => {
    const newTime = e.target.value
    videoRef.current.currentTime = newTime
    setCurrentTime(newTime)
  }
  const {
    t,
    spotlightData,
    handleChange,
    handleNominate,
    legendName,
    handleCommentChange,
    comment,
    profileImageurl,
    handleAddComment
  } = usePlayerSpotlightController()
  return (
    <>
      <div className='max-w-[1320px] mx-auto pt-10 min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
        {/* <div className='text-xl font-normal text-white flex gap-2 items-center mb-7'><BackArrow /> Back</div> */}
        <h1 className='flex justify-center items-center overflow-hidden mb-6'>
          <span className='text-[22px] max-sm:text-base px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full'>
            {t('spotlightTitle')}
          </span>
        </h1>
        <div className='mb-[50px] max-lg:mb-[25px] grid items-start grid-cols-[calc(100%_-_65%_-_2.5rem)_calc(100%_-_35%)] max-lg:grid-cols-[calc(100%_-_60%_-_0.625rem)_calc(100%_-_40%_-_0.625rem)] max-md:grid-cols-[calc(100%_-_50%_-_0.625rem)_calc(100%_-_50%_-_0.625rem)] gap-x-10 max-lg:gap-x-5 gap-y-[50px] max-lg:gap-y-[25px] [&>*:nth-child(2)]:max-w-[856px] sm:[&>*:nth-child(2)]:col-start-2 sm:[&>*:nth-child(2)]:col-span-1 sm:[&>*:nth-child(3)]:col-start-1 sm:[&>*:nth-child(3)]:col-span-2 max-sm:grid-cols-1'>
          <div className='bg-primaryColor-200 rounded-3xl p-6 col-start-1 col-span-1 flex flex-col items-center justify-center max-sm:flex-col-reverse'>
            <div className='w-full text-[22px] text-white font-semibold text-center bg-primaryColor-400 rounded-lg p-3 mb-5 flex items-center gap-2 justify-center'>
              {/* <span className='text-xs font-medium py-1 px-2 text-primaryColor-950 bg-dustyGray-400 rounded-lg border-solid border border-primaryColor-950'>QB</span>  */}
              {spotlightData?.player?.name}
            </div>

            <div>
              {spotlightData?.image ? (
                <img
                  className='w-[384px] h-[313px] max-lg:w-[200px] max-lg:h-[200px] object-cover rounded-2xl mb-5'
                  src={spotlightData?.image}
                  alt='Player Image'
                />
              ) : (
                <UserIcon className='w-[384px] h-[313px] max-lg:w-[200px] max-lg:h-[200px] object-cover rounded-2xl mb-5' />
              )}
              <div className='flex justify-between items-center'>
                {/* <p className='text-primaryColor-700'>
                  {spotlightData?.playerSummary}
                </p> */}
                {/* <button type='submit' className='flex items-center justify-center gap-2 text-base leading-none font-normal capitalize bg-primaryColor-800 text-white border-primaryColor-500 border-solid border px-8 py-4 rounded-lg text-center hover:animate-btnHover transition-all hover:text-white hover:delay-200'>
                                    Nominate <PlusIcon className='fill-white w-[11px] h-[11px]' />
                                </button> */}

                {/* <button className="flex items-center justify-center gap-2 text-base leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 py-4 rounded-lg relative text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center min-w-[152px]">

                                    <span className="absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]"></span>
                                    Nominate <PlusIcon className='fill-white w-[11px] h-[11px]' />
                                </button> */}
              </div>
            </div>
            <div className='bg-primaryColor-900 py-2 px-2 my-4 w-full'>
              <h5 className='text-white capitalize text-lg font-normal'>
                {t('playerCareerSummary')}
              </h5>
            </div>
            <div className='flex items-center pb-4 px-2 justify-between w-full flex-wrap'>
              <div className='[&>:not(:last-child)]:pb-2'>
                <div className='w-full flex gap-4'>
                  <span className='min-w-[100px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
                    <span className='text-sm'>{t('careerAvg')}</span>
                    <span>:</span>
                  </span>
                  <span className='text-white text-sm font-semibold'>
                    {spotlightData?.player?.career_avg &&
                      toFixed(spotlightData?.player?.career_avg)}
                  </span>
                </div>
                <div className='w-full flex gap-4'>
                  <span className='min-w-[100px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
                    <span className='text-sm'>{t('careerYear')}</span>
                    <span>:</span>
                  </span>
                  <span className='text-white text-sm font-semibold'>
                    {spotlightData?.player?.career_years}
                  </span>
                </div>
              </div>
              <div className='[&>:not(:last-child)]:pb-2'>
                <div className='w-full flex gap-4'>
                  <span className='min-w-[100px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
                    <span className='text-sm'>{t('salary')}</span>
                    <span>:</span>
                  </span>
                  <span className='flex items-center text-white text-smfont-semibold'>
                    $
                    <span className='text-white text-sm font-semibold'>
                      {spotlightData?.player?.value &&
                        toFixed(spotlightData?.player?.value)}
                    </span>
                  </span>
                </div>
                <div className='w-full flex gap-4'>
                  <span className='min-w-[100px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
                    <span className='text-sm'>{t('position')}</span>
                    <span>:</span>
                  </span>
                  <span className='flex items-center text-white text-smfont-semibold'>
                    <span className='text-white text-sm font-semibold'>
                      {spotlightData?.player?.position}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className=' bg-primaryColor-200 rounded-3xl relative p-4 h-full'>
            <iframe
              className='rounded-2xl'
              width='100%'
              height='100%'
              src={spotlightData?.url && convertToEmbedUrl(spotlightData?.url)}
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowfullscreen
            ></iframe>
            {/* <video className="w-full h-full aspect-video"
                            ref={videoRef}
                            onTimeUpdate={handleTimeUpdate}
                            poster='../../assets/image/videoscreen.png'>
                            {
                                spotlightData?.url &&
                                <source src={spotlightData?.url} type="video/mp4" />
                            }
                            Your browser does not support the video tag.
                        </video>
                        <div onClick={handlePlayPause} className='w-full h-full absolute inset-0 flex justify-center items-center z-[1]'>
                            <PlayButton className='w-[100px] h-[100px] max-lg:w-[75px] max-lg:h-[75px] max-md:w-[50px] max-md:h-[50px] cursor-pointer' />
                        </div>
                        <div className='cus-video flex items-center justify-center gap-3.5 absolute w-full bottom-[2rem] max-md:bottom-[1rem] left-0 z-[2] px-4'>
                            <span className='text-white text-sm font-normal'>2:14</span>
                            <input type="range" defaultValue="50" className="max-w-[660px] w-full h-1.5 bg-gray-200 rounded-none border-red-400 outline-none cursor-pointer dark:bg-gray-700 accent-white" />
                            <span className='text-white text-sm font-normal'>5:37</span>
                            <a href="#"><WideIcon /></a>

                        </div>
                        <SendIcon className='z-[2] cursor-pointer absolute top-8 right-[49px]' /> */}
          </div>

          <div className='bg-primaryColor-200 p-6 max-sm:p-4 rounded-3xl'>
            <h4 className='text-white text-[22px] max-sm:text-base font-semibold capitalize pb-4'>
              {t('article')}
            </h4>
            <p
              className='text-base max-sm:text-[10px] text-primaryColor-700 font-normal'
              dangerouslySetInnerHTML={{ __html: spotlightData?.description }}
            />
          </div>
        </div>

        <div className=' mt-[50px] max-lg:mt-[25px] bg-primaryColor-200 p-6 max-sm:p-4 rounded-3xl mb-[50px] max-lg:mb-[25px]'>
          <div className='mb-4'>
            <div className='flex gap-6 max-sm:gap-2 pt-4'>
              {profileImageurl ? (
                <img
                  className='w-[72px] h-[72px] max-sm:w-[40px] max-sm:h-[40px] object-cover rounded-full'
                  src={profileImageurl}
                  alt='viewers Image'
                />
              ) : (
                <div className='w-[42px max-sm:w-[30px] h-[42px] max-sm:h-[30px]'>
                  <UserIcon className='w-[42px] max-sm:w-[30px] h-[42px] max-sm:h-[30px] bg-primaryColor-100 object-contain rounded-full p-1' />
                </div>
              )}
              <div className='w-full relative '>
                <textarea
                  onChange={handleCommentChange}
                  value={comment}
                  className='w-full max-sm:text-xs pr-28 max-md:pr-8 leading-none text-white pl-4 border-b-2 border-solid border-primaryColor-400 bg-transparent resize-none focus-visible:outline-none'
                  placeholder={t('addCommentHere')}
                ></textarea>

                <div className='flex justify-end items-center absolute -top-2 max-md:top-0 right-0'>
                  {/* <button onClick={handleAddComment} type='submit' className='gradientDark-hover flex text-white place-content-center text-base leading-none font-normal capitalize bg-primaryColor-800 border-primaryColor-500 border-solid border px-8 py-4 rounded-lg text-center'>
                                Submit
                            </button> */}

                  <button
                    onClick={handleAddComment}
                    type='submit'
                    className='text-sm max-md:hidden max-sm:text-xs leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 py-2 max-sm:py-3 rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
                  >
                    <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                    {t('submit')}
                  </button>

                  <SendIcon
                    onClick={handleAddComment}
                    className='cursor-pointer md:hidden fill-primaryColor-500'
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ---- Comments ---- */}
          {/* <div className='flex gap-6 items-center mb-3'>
                        <img className='w-[72px] h-[72px] object-cover rounded-full' src={viewersImg} alt="viewers Image" />
                        <div className='bg-primaryColor-900 p-4 rounded-lg'>
                            <p className='text-primaryColor-700 text-base capitalize mb-2'>Jerry Helfer</p>
                            <p className='text-white text-sm font-normal'>
                                Aliquam a dui vel justo fringilla euismod id id enim. Nunc non semper tellus. Pellentesque vitae tellus non dui fermentum hendrerit. In vel imperdiet mi. Aliquam erat volutpat. Cras dapibus orci eu eros tempus efficitur. Nulla rhoncus arcu nec dictum condimentum. Nunc non semper tellus. Pellentesque vitae tellus non dui fermentum hendrerit. In vel imperdiet mi. Aliquam erat volutpat. Cras dapibus orci eu eros tempus efficitur
                            </p>
                        </div>
                    </div> */}
          {spotlightData?.SpotlightComments.map((item) => {
            return (
              <div className='flex gap-6 max-sm:gap-2 mb-3'>
                {item?.image ? (
                  <img
                    className='w-[72px] h-[72px] max-sm:w-[40px] max-sm:h-[40px] object-cover rounded-full'
                    src={item?.image}
                    alt='viewers Image'
                  />
                ) : (
                  <div className='w-[42px] max-sm:w-[30px] h-[42px] max-sm:h-[30px]'>
                    <UserIcon className='w-[42px] max-sm:w-[30px] h-[42px] max-sm:h-[30px] bg-primaryColor-100 object-contain rounded-full p-1' />
                  </div>
                )}
                <div className='bg-primaryColor-900 px-4 py-2 rounded-lg w-full'>
                  <div className='flex flex-row justify-between'>
                    <p className='text-primaryColor-700 text-base max-sm:text-xs capitalize mb-2'>
                      {item?.user?.firstName} {item?.user?.lastName}
                    </p>
                    <p className='text-primaryColor-500 text-base max-xs:text-xxs capitalize mb-2'>
                      <TimeAgo date={item?.createdAt} formatter={formatter} />
                    </p>
                  </div>
                  <p className='text-white text-sm max-sm:text-xs font-normal'>
                    {item.comments}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
        <div className='bg-primaryColor-200 p-6 rounded-3xl mb-[60px]'>
          <h4 className='text-white text-[22px] max-sm:text-base max-sm:text-center font-semibold lowercase first-letter:uppercase pb-4 leading-none '>
            {t('nominateLegendTitle')}
          </h4>
          <div className='flex items-center gap-8 max-sm:gap-4 max-md:flex-wrap'>
            <input
              onChange={handleChange}
              value={legendName}
              name='lastName'
              maxLength={50}
              type='text'
              id='lastName'
              placeholder={t('enterTheLegendName')}
              className='mt-1 p-5 max-sm:px-4 max-sm:py-[10px] w-full focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
            />

            {/* <button onClick={handleNominate} className='gradientDark-hover flex text-white place-content-center text-base leading-none font-normal capitalize bg-primaryColor-800 border-primaryColor-500 border-solid border px-8 py-4 rounded-lg text-center whitespace-nowrap'>
                                Nominate a Legend
                            </button> */}

            <button
              onClick={handleNominate}
              className='text-base max-sm:text-xs leading-none font-normal border-primaryColor-500 border-solid border px-8 py-4 max-sm:py-3 rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center whitespace-nowrap min-w-[202px] max-md:w-full'
            >
              <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
              {t('nominateALegend')}
            </button>
          </div>
          {/* <div className='grid grid-cols-1 justify-between [&>:last-child]:border-none [&>:last-child]:mb-0 mt-6 '>
            <div className='flex items-center justify-between gap-4 py-4 border-b border-solid border-primaryColor-900 mb-6 max-sm:mb-3'>
              <span className='text-white text-xs tabletscreen:text-lg capitalize'>
                Brett Farve
              </span>
              <button className='text-base max-sm:text-sm leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 max-sm:px-3 py-2 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'>
                <span class='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                {t('remove')}
              </button>
            </div>
            <div className='flex items-center justify-between gap-4 py-4 border-b border-solid border-primaryColor-900 mb-6 max-sm:mb-3'>
              <span className='text-white text-xs tabletscreen:text-lg capitalize'>
                Brett Farve
              </span>
              <button className='text-base max-sm:text-sm leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 max-sm:px-3 py-2 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'>
                <span class='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                {t('remove')}
              </button>
            </div>
            <div className='flex items-center justify-between gap-4 py-4 border-b border-solid border-primaryColor-900 mb-6 max-sm:mb-3'>
              <span className='text-white text-xs tabletscreen:text-lg capitalize'>
                Brett Farve
              </span>
              <button className='text-base max-sm:text-sm leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 max-sm:px-3 py-2 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'>
                <span class='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                {t('remove')}
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default PlayerSpotlight
