import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {
  fetchPlayerInformation,
  searchPlayer
} from '../../redux-store/redux/thunk/Player/Player'
import { PAGINATION_LIMIT } from '../../utils/constants'
import { moment } from '../../config/moment'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
export const usePlayerDetailController = () => {
  const columns = [
    'year',
    'gameNumber',
    'passYds',
    'passTd',
    'passInt',
    'rushYds',
    'rushTd',
    'recRec',
    'recYds',
    'redTd',
    'fumFl',
    'fumFf',
    'fumFr',
    'defSk',
    'defTd',
    'defInt',
    'tackSolo',
    'kXpm',
    'kFgm',
    'k_2pm',
    'totals'
  ]
  const { t } = useTranslation('home')
  const dispatch = useDispatch()
  const location = useLocation()
  const { bucketData, playerId } = location.state || {} // Access the passed data
  const history = useHistory()
  const { playerDetails, playerSearchList, loading } = useSelector(
    (state) => state.playerSlice
  )
  const [visiblePlayerRecords, setVisiblePlayerRecords] = useState([])
  const [searchText, setSearchText] = useState('')
  const [currentOffset, setCurrentOffset] = useState(0)
  const [showPagination, setShowPagination] = useState(false)
  const [showNextBtn, setShowNextBtn] = useState(true)
  const { addToast } = useToasts()
  useEffect(async () => {
    dispatch(searchPlayer({}))
  }, [])
  useEffect(() => {
    if (playerDetails?.PlayersRecords) {
      setVisiblePlayerRecords(playerDetails?.PlayersRecords?.slice(0, 10))
      setCurrentOffset(0)
    }
  }, [playerDetails])
  useEffect(() => {
    if (playerSearchList.length > 0 || playerId) {
      dispatch(
        fetchPlayerInformation({
          playerId: playerId ? playerId : playerSearchList[0]?.id
          // limit: PAGINATION_LIMIT,
          // offset: currentOffset
        })
      )
    }
  }, [playerSearchList])
  useEffect(() => {
    if (visiblePlayerRecords.length || currentOffset > 0) {
      setShowPagination(true)
      if (visiblePlayerRecords.length < PAGINATION_LIMIT) {
        setShowNextBtn(true)
      } else {
        setShowNextBtn(false)
      }
    } else {
      setShowPagination(false)
    }
  }, [visiblePlayerRecords])
  const onPageChange = async (offsetData) => {
    setVisiblePlayerRecords(
      playerDetails?.PlayersRecords?.slice(
        offsetData,
        offsetData + PAGINATION_LIMIT
      )
    )

    setCurrentOffset(offsetData)
  }
  // useEffect(()=>{
  //     if(searchText.length>2){
  //         dispatch(searchPlayer({search:searchText}))
  //     }
  // },[searchText])
  // const handleChange = (value) => {
  //     setSearchText(value.replace(/^\s+/, ''))
  // }
  const handlePlayerSelection = (data) => {
    //console.log(data)
    dispatch(
      fetchPlayerInformation({
        playerId: data.id
      })
    )
  }
  return {
    t,
    columns,
    playerDetails,
    //handleChange,
    value: searchText,
    playerSearchList,
    handlePlayerSelection,
    bucketData,
    history,
    onPageChange,
    showPagination,
    showNextBtn,
    currentOffset,
    loading,
    visiblePlayerRecords
  }
}
