import Pagination from '../../components/Pagination'
import { RugbyICon } from '../../assets/image'
import EntryDetails from './components/EntryDetails'
import { useLobbyController } from './controller/useLobbyController'
import LobbyBannerSection from './components/LobbyBannerSection'
import { moment } from '../../config/moment'
import BucketAction from './components/BucketAction'
import CurrentTimeDifference from '../../components/CurrentTimeDifference'
import Loader from '../../components/Loader'
import { toFixed } from '../../utils/common-functions'
import WinnerDetails from './components/WinnerDetails'

const Lobby = () => {
  const {
    t,
    currentOffset,
    showPagination,
    showNextBtn,
    bucketList,
    showEntryDetailsModal,
    userDetails,
    bucketDetails,
    currentOffsetPopup,
    showPaginationPopup,
    showNextBtnPopup,
    bucketUsers,
    timeSettings,
    tableHeadingList,
    loader,
    bucketListLoader,
    isContestBegins,
    isBucketDisable,
    setIsBucketDisable,
    setIsContestBegins,
    setShowEntryDetailsModal,
    setBucketUsers,
    handleShowEntryDetails,
    onPageChange,
    onPageChangePopup,
    handleBucketEnter,
    handleShowNumOfWinners,
    numOfWinnerModal,
    setNumOfWinnerModal
  } = useLobbyController()

  return (
    <>
      <div className='max-w-[1320px] mx-auto px-5 2xl:px-0 py-10 min-h-[calc(100dvh_-_505px)]'>
        <LobbyBannerSection />
        <div className='bg-primaryColor-200 rounded-3xl px-4 py-6 lg:py-4'>
          <div className='overflow-x-auto'>
            <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
              <thead className='ltr:text-left rtl:text-right p-2 lg:table-header-group hidden'>
                <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-lg [&>th]:p-4'>
                  {tableHeadingList?.map((item, index) => {
                    return (
                      <th
                        key={index}
                        className='whitespace-nowrap px-4 py-2 font-normal capitalize '
                      >
                        {item?.name}
                      </th>
                    )
                  })}
                  <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>{!isContestBegins && !isBucketDisable && t('contestBeginIn')}</th>
                </tr>
              </thead>

              <tbody className='[&>tr]:mb-4 [&>tr]:lg:mb-0 [&>*:last-child]:mb-0 lg:divide-y  lg:divide-primaryColor-350 divide-solid '>
                {!bucketListLoader ? (
                  bucketList?.rows?.length > 0 ? (
                    bucketList?.rows?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className='[&>td]:text-center [&>td]:py-4  [&>td]:lg:pl-1 flex flex-col items-start lg:table-row bg-primaryColor-100 lg:bg-transparent rounded-2xl lg:rounded-none max-lg:border-primaryColor-500 max-lg:border max-lg:border-solid'
                        >
                          <td dynamic-heading-value={t("sport")} className="pl-[50%] tabletscreen:whitespace-nowrap px-1 w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                            <RugbyICon className='mx-auto max-w-[40px] max-h-[40px] object-contain' />
                          </td>

                          <td dynamic-heading-value={t('contestName')} className="pl-[50%] tabletscreen:whitespace-nowrap px-1 text-white text-base tabletscreen:text-lg lg:text-lg font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                            {item?.bucketUid}
                          </td>
                          <td dynamic-heading-value={t('contestDate')} className="pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base tabletscreen:text-lg lg:text-lg font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                            {item?.date &&
                              moment(item?.date, 'YYYY-MM-DD').format(
                                'ddd / MM-DD-YYYY'
                              )}
                          </td>

                          <td dynamic-heading-value={t('contestRemainingTime')} className="pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                            <CurrentTimeDifference
                              time={item?.endTime}
                            />
                          </td>

                          <td dynamic-heading-value={t('entryFee')} className="pl-[50%] tabletscreen:whitespace-nowrap px-1 text-customGreen-400 font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                            <span>
                              {item?.bucketType == 'free'
                                ? t('free')
                                : `$ ${item?.price && toFixed(item?.price)}`}
                            </span>
                          </td>

                          <td 
                            onClick={() =>{
                              if(item?.bucketType != 'free')
                              handleShowNumOfWinners(true, item)
                            }}
                            dynamic-heading-value={t('numberOfWinners')} className={`${item?.bucketType == 'free'?'':'cursor-pointer'} pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full`}>
                            <span className='uppercase'>{t('top')} </span>
                            <span>
                              {item?.numberOfWinners}
                            </span>
                            <span> {t('wins')}</span>
                          </td>

                          <td
                            onClick={() => handleShowEntryDetails(true, item)} 
                            dynamic-heading-value={t('numberOfEntries')} className="cursor-pointer pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full"
                          >
                            {item?.bucketType ===
                            'paid' ? (
                              <>
                                <span>{item?.numberOfUsers}</span> of{' '}
                                <span>
                                  {
                                    item
                                      ?.numberOfParticipants
                                  }
                                </span>
                              </>
                            ) : (
                              <span>{t('unlimited')}</span>
                            )}
                          </td>

                          <td className='tabletscreen:whitespace-nowrap px-1 lg:w-auto w-full'>
                            <BucketAction
                              item={item}
                              timeSettings={timeSettings}
                              userDetails={userDetails}
                              handleBucketEnter={handleBucketEnter}
                              isContestBegins={isContestBegins}
                              setIsContestBegins={setIsContestBegins}
                              isBucketDisable={isBucketDisable}
                              setIsBucketDisable={setIsBucketDisable}
                            />
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan='7'
                        className='text-white text-center text-lg pt-4 max-lg:pt-0'
                      >
                        {t('noBucketFound')}
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan='7'>
                      <Loader />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <WinnerDetails
          numOfWinnerModal={numOfWinnerModal}
          setNumOfWinnerModal={setNumOfWinnerModal}
          bucketDetails={bucketDetails}
          userDetails={userDetails}
          timeSettings={timeSettings}
          handleBucketEnter={handleBucketEnter}
          loader={loader}
          isContestBegins={isContestBegins}
          setIsContestBegins={setIsContestBegins}
          isBucketDisable={isBucketDisable}
          setIsBucketDisable={setIsBucketDisable}
        />
        <EntryDetails
          showEntryDetailsModal={showEntryDetailsModal}
          setShowEntryDetailsModal={setShowEntryDetailsModal}
          bucketDetails={bucketDetails}
          userDetails={userDetails}
          showPaginationPopup={showPaginationPopup}
          currentOffsetPopup={currentOffsetPopup}
          onPageChangePopup={onPageChangePopup}
          showNextBtnPopup={showNextBtnPopup}
          itemCountPopup={bucketUsers?.count}
          bucketUsers={bucketUsers}
          setBucketUsers={setBucketUsers}
          timeSettings={timeSettings}
          handleBucketEnter={handleBucketEnter}
          loader={loader}
          isContestBegins={isContestBegins}
          setIsContestBegins={setIsContestBegins}
          isBucketDisable={isBucketDisable}
          setIsBucketDisable={setIsBucketDisable}
        />
        {showPagination && (
          <Pagination
            currentOffset={currentOffset}
            onPageChange={onPageChange}
            disableNext={showNextBtn}
            itemCount={bucketList?.count}
          />
        )}
      </div>
    </>
  )
}

export default Lobby
