import i18n from 'i18next'
import { errorCodeToMessage, ErrorMessage } from './messages'

/**
 * function which translate any text into user selected language
 * @param {string} text - text which need to translate
 * @returns translated text
 */
export const getTranslation = (text) => i18n.t(text)

/**
 * function which convert error code into tranlated error message
 * @param {number} errorCode - ErrorCode which is obtained from api response error
 * @returns { string } translated Error Message
 */
export const getTranslatedErrorMessage = (errorCode) => {
  const errorMessage =
    errorCodeToMessage.get(errorCode) || ErrorMessage.internalServerError
  return getTranslation(errorMessage)
}

/**
 * function to download excel file of given data
 * @param {[objects]} data - object array which needed to convert into xlsx
 * @param {string} fileName - The filename of excel which will be downloaded
 */
// export const exportToXLSX = (data, columns, fileName = 'reports') => {
//   // STEP 1: Create a new workbook
//   const wb = XLSX.utils.book_new()

//   // STEP 2: Create data rows and styles
//   columns = columns.map(c => ({ v: c, s: { font: { bold: true } } }))
//   const newData = [columns, ...data.map((x) => {
//     return Object.values(x).map(v => ({
//       v
//     }))
//   })]

//   // STEP 3: Create worksheet with rows; Add worksheet to workbook
//   const ws = XLSX.utils.json_to_sheet(newData, { skipHeader: true })
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
//   ws['!cols'] = Array(data.length).fill({ wch: 20 })
//   // STEP 4: Write Excel file to browser
//   XLSX.writeFile(wb, `${fileName}.xlsx`)
// }
