import userIcon from '../../assets/image/usericon.png'
import coinIcon from '../../assets/image/coinicon.png'
import userImg from '../../assets/image/userimg.png'
import React from 'react'
import Select from 'react-select'
import { useMyAccountController } from './controller/useMyAccountController'
import { LOADER } from '../../utils/constants'
import Loader from '../../components/Loader'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  CalendarIcon,
  EditIcon,
  HidePasswordIcon,
  ShowPasswordIcon
} from '../../assets/image'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const MyAccount = (props) => {
  const {
    t,
    handleUpdateProfileSubmit,
    handleChangePasswordSubmit,
    handleSubmitUpdateProfile,
    registerUpdateProfile,
    formStateUpdateProfile,
    resetUpdateProfile,
    handleSubmitChangePassword,
    registerChangePassword,
    formStateChangePassowrd,
    resetChangePassword,
    handleCityChange,
    handleStateChange,
    handleCountryChange,
    loading,
    countryList,
    stateList,
    cityList,
    userDetails,
    history,
    watch,
    fileChangedHandler,
    handleDateSelect,
    handleMobileNumberChange,
    edit,
    setEdit,
    showOldPassword,
    showNewPassword,
    showConfirmPassword,
    handleShowOldPassword,
    handleShowNewPassword,
    handleShowConfirmPassword
  } = useMyAccountController()

  return (
    <>
      <div className='max-w-[1320px] mx-auto py-10 min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
        {/* <ul className='bg-primaryColor-100 rounded-3xl'>
          <li className='accountBtnbg text-center p-4 flex flex-col items-center gap-4'>
            <div className={userDetails?.profileImageUrl ? '' : 'p-5 max-sm:p-2.5 border border-solid activeProTab rounded-full inline-block w-[82px] h-[82px] max-sm:w-[40px] max-sm:h-[40px]'}>
              <img src={userDetails?.profileImageUrl ? userDetails?.profileImageUrl : userIcon} alt='User icon' className={userDetails?.profileImageUrl ? 'w-[70px] h-[70px] object-cover rounded-full' : 'mx-auto'} />
            </div>
            <span className='text-xl max-sm:text-[14px] uppercase text-white'>
              My Account
            </span>
          </li> */}
        {/* <li className="text-center p-4 flex flex-col items-center gap-4">
            <div className="p-5 proTab rounded-full inline-block"><img src={coinIcon} alt="User icon" className="mx-auto" /></div>
            <span className="text-xl uppercase text-white">KYC Verification</span>
          </li> */}
        {/* <div className="text-center p-4">
            <img src={coinIcon} alt="User icon" className="mx-auto"/>
            <span className="text-xl uppercase text-white">KYC Verification</span>
          </div> */}
        {/* </ul> */}

        <div className='bg-primaryColor-100 rounded-3xl mt-10 p-8 max-md:px-4'>
          <h3 className='text-2xl max-sm:text-base font-semibold text-primaryColor-800 capitalize flex items-center gap-4'>
            {t('myAccount.personalDetails')}
            <button
              onClick={() => setEdit(!edit)}
              className='flex align-center justify-center text-center]'
            >
              <EditIcon className='max-sm:w-2/3' />
            </button>
          </h3>

          <div className='pt-8 flex gap-5 items-center'>
            <div
              className={`${
                userDetails?.profileImageUrl
                  ? ''
                  : 'p-2 border border-solid activeProTab rounded-full inline-block max-sm:w-[40px] max-sm:h-[40px]'
              }`}
            >
              <img
                className={`${
                  userDetails?.profileImageUrl
                    ? 'w-[70px] h-[70px] object-cover rounded-full'
                    : 'mx-auto'
                }`}
                src={
                  userDetails?.profileImageUrl
                    ? userDetails?.profileImageUrl
                    : userIcon
                }
                alt=''
              />
            </div>
            <div>
              <p className='text-xl max-sm:text-base text-white capitalize font-medium'>
                {userDetails?.firstName} {userDetails?.lastName}{' '}
              </p>
              <div>
                <label
                  htmlFor='img'
                  className='text-white text-sm max-sm:text-[10px] font-normal'
                >
                  {loading ? (
                    <Loader type={LOADER.BUTTON} />
                  ) : (
                    <span className='relative inline-block'>
                      {t('myAccount.changePicture')}
                      <span className='absolute left-0 right-0 top-2.5 h-0.5 bg-white mt-2'></span>
                    </span>
                  )}
                  <input
                    disabled={loading}
                    type='file'
                    id='img'
                    name='img'
                    accept='image/*'
                    className='hidden'
                    onChange={(e) => fileChangedHandler(e)}
                  />
                </label>
              </div>
            </div>
          </div>
          <form
            action=''
            className='mt-8'
            onSubmit={handleSubmitUpdateProfile((data) =>
              handleUpdateProfileSubmit(data, resetUpdateProfile)
            )}
          >
            <div className='gap-4 grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1'>
              <div className=''>
                <label
                  for='firstname'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.firstName')}
                </label>
                <input
                  disabled={edit || loading}
                  name='firstName'
                  maxLength={50}
                  autoFocus
                  {...registerUpdateProfile('firstName')}
                  type='text'
                  id='firstname'
                  placeholder=''
                  className={`mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none ${
                    edit ? 'bg-primaryColor-400' : 'bg-primaryColor-900'
                  } rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                />
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.firstName?.message}
                </p>
              </div>

              <div className=''>
                <label
                  for='lastName'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.lastName')}
                </label>
                <input
                  disabled={edit || loading}
                  name='lastName'
                  maxLength={50}
                  autoFocus
                  {...registerUpdateProfile('lastName')}
                  type='text'
                  id='lastName'
                  placeholder=''
                  className={`mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none ${
                    edit ? 'bg-primaryColor-400' : 'bg-primaryColor-900'
                  } rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                />
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.lastName?.message}
                </p>
              </div>

              <div className=''>
                <label
                  for='userName'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.username')}
                </label>
                <input
                  disabled={edit || loading}
                  name='userName'
                  maxLength={50}
                  autoFocus
                  {...registerUpdateProfile('userName')}
                  type='text'
                  id='firstname'
                  placeholder=''
                  className={`mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none ${
                    edit ? 'bg-primaryColor-400' : 'bg-primaryColor-900'
                  } rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                />
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.userName?.message}
                </p>
              </div>

              <div className='[&>.react-datepicker-wrapper]:w-full'>
                <label
                  for='dateOfBirth'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.dateOfBirth')}
                </label>
                <DatePicker
                  disabled={edit || loading}
                  showIcon
                  icon={
                    <CalendarIcon className='absolute top-1/2 right-4 transform translate-y-[-50%] pointer-events-none' />
                  }
                  placeholderText='MM-DD-YYYY'
                  dateFormat='MM-dd-yyyy'
                  maxDate={moment().subtract(18, 'years').toDate()}
                  selected={watch('dateOfBirth')}
                  onChange={(e) => handleDateSelect(e)}
                  className={`mt-1 p-5 h-16 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none ${
                    edit ? 'bg-primaryColor-400' : 'bg-primaryColor-900'
                  } rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                />
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.dateOfBirth?.message}
                </p>
              </div>

              <div className=''>
                <label
                  for='email'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.email')}
                </label>
                <input
                  disabled={edit || loading}
                  name='email'
                  // maxLength={50}
                  {...registerUpdateProfile('email')}
                  type='email'
                  id='email'
                  placeholder=''
                  className={`mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none ${
                    edit ? 'bg-primaryColor-400' : 'bg-primaryColor-900'
                  } rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                />
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.email?.message}
                </p>
              </div>

              <div className='[&>.react-datepicker-wrapper]:w-full'>
                <label
                  for='dateOfBirth'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.mobileNumber')}
                </label>
                <PhoneInput
                  disabled={edit || loading}
                  placeholder=''
                  value={watch('phone')}
                  onChange={(e) => handleMobileNumberChange(e)}
                  className={`phone-input ${
                    edit && 'phone-input-disable'
                  } mt-1 p-5 h-16 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none ${
                    edit ? 'bg-primaryColor-400' : 'bg-primaryColor-900'
                  } rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                />
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.phone?.message}
                </p>
              </div>

              <div>
                <label
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                  for='grid-state'
                >
                  {t('signup.country')}
                </label>
                <div className=''>
                  <Select
                    isDisabled={edit || loading}
                    placeholder={t('signup.country')}
                    className={`react-state-select ${edit && 'state-disable'}`}
                    classNamePrefix='react-state-inner'
                    defaultValue={null}
                    value={countryList?.filter(
                      (item) => item.id == watch('countryId')
                    )}
                    onChange={handleCountryChange}
                    options={countryList}
                    getOptionLabel={(option) => option?.countryName}
                    getOptionValue={(option) => option?.id}
                  />
                </div>
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.countryId?.message}
                </p>
              </div>

              <div className=''>
                <label
                  className='block block px-5 text-base max-sm:text-xs font-medium text-white'
                  for='grid-state'
                >
                  {t('signup.state')}
                </label>
                <div className=''>
                  <Select
                    isDisabled={edit || loading}
                    placeholder={t('signup.state')}
                    className={`react-state-select ${edit && 'state-disable'}`}
                    classNamePrefix='react-state-inner'
                    defaultValue={null}
                    value={stateList?.filter(
                      (item) => item.id == watch('stateId')
                    )}
                    onChange={handleStateChange}
                    options={stateList}
                    getOptionLabel={(option) => option?.stateName}
                    getOptionValue={(option) => option?.id}
                  />
                </div>
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.stateId?.message}
                </p>
              </div>

              <div className=''>
                <label
                  className='block block px-5 text-base max-sm:text-xs font-medium text-white'
                  for='grid-state'
                >
                  {t('signup.city')}
                </label>
                <div className=''>
                  <Select
                    isDisabled={edit || loading}
                    placeholder={t('signup.city')}
                    className={`react-state-select ${edit && 'state-disable'}`}
                    classNamePrefix='react-state-inner'
                    defaultValue={null}
                    value={cityList?.filter(
                      (item) => item.id == watch('cityId')
                    )}
                    onChange={handleCityChange}
                    options={cityList}
                    getOptionLabel={(option) => option?.cityName}
                    getOptionValue={(option) => option?.id}
                  />
                </div>
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.cityId?.message}
                </p>
              </div>

              <div className=''>
                <label
                  for='street'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.streetAddress')}
                </label>
                <input
                  disabled={edit || loading}
                  name='street'
                  // maxLength={50}
                  autoFocus
                  {...registerUpdateProfile('street')}
                  type='text'
                  id='street'
                  placeholder=''
                  className={`mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none ${
                    edit ? 'bg-primaryColor-400' : 'bg-primaryColor-900'
                  } rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                />
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.street?.message}
                </p>
              </div>

              <div className=''>
                <label
                  for='zipCode'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.zipCode')}
                </label>
                <input
                  disabled={edit || loading}
                  name='zipCode'
                  // maxLength={10}
                  autoFocus
                  {...registerUpdateProfile('zipCode')}
                  type='text'
                  id='firstname'
                  placeholder={t('signup.zipCode')}
                  className={`mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none ${
                    edit ? 'bg-primaryColor-400' : 'bg-primaryColor-900'
                  } rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                />
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateUpdateProfile?.errors?.zipCode?.message}
                </p>
              </div>
            </div>

            <div className='flex justify-center items-center gap-4 mt-8'>
              <button
                type='button'
                onClick={() => history.goBack()}
                className='text-lg w-[226px] leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 py-4 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'
              >
                <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                {t('cancel')}
              </button>

              <button
                className='text-lg w-[226px] leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 py-4 rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
                disabled={edit || loading}
              >
                <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                {loading ? <Loader type={LOADER.BUTTON} /> : t('save')}
              </button>
            </div>
          </form>
        </div>

        <div className='bg-primaryColor-100 rounded-3xl mt-10 p-8 max-md:px-4'>
          <h3 className='text-2xl max-sm:text-base font-semibold text-primaryColor-800 capitalize flex items-center gap-4'>
            {t('myAccount.changePassword')}
            <button className='flex align-center justify-center text-center]'>
              <EditIcon className='max-sm:w-2/3' />
            </button>
          </h3>

          <form
            action=''
            className='mt-8'
            onSubmit={handleSubmitChangePassword((data) =>
              handleChangePasswordSubmit(data, resetChangePassword)
            )}
          >
            <div className='grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-4'>
              <div className='relative'>
                <label
                  for='current_password'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.currentPassword')}
                </label>
                <input
                  name='oldPassword'
                  maxLength={50}
                  {...registerChangePassword('oldPassword')}
                  type={showOldPassword ? 'text' : 'password'}
                  id='current_password'
                  placeholder={t('signup.password')}
                  className='mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                />
                <button
                  type='button'
                  onClick={() => handleShowOldPassword()}
                  className='absolute top-1/2 right-4 transform translate-y-[-25%]'
                >
                  {showOldPassword ? (
                    <ShowPasswordIcon className='h-7 w-7' />
                  ) : (
                    <HidePasswordIcon className='h-7 w-7' />
                  )}
                </button>
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateChangePassowrd?.errors?.oldPassword?.message}
                </p>
              </div>

              <div className='relative'>
                <label
                  for='new_passord'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.newPassword')}
                </label>
                <input
                  name='newPassword'
                  maxLength={50}
                  {...registerChangePassword('newPassword')}
                  type={showNewPassword ? 'text' : 'password'}
                  id='new_password'
                  placeholder={t('signup.password')}
                  className='mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                />
                <button
                  type='button'
                  onClick={() => handleShowNewPassword()}
                  className='absolute top-1/2 right-4 transform translate-y-[-25%]'
                >
                  {showNewPassword ? (
                    <ShowPasswordIcon className='h-7 w-7' />
                  ) : (
                    <HidePasswordIcon className='h-7 w-7' />
                  )}
                </button>
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateChangePassowrd?.errors?.newPassword?.message}
                </p>
              </div>

              <div className='relative'>
                <label
                  for='confirm_new_password'
                  className='block px-5 text-base max-sm:text-xs font-medium text-white'
                >
                  {t('signup.confirmNewPassword')}
                </label>
                <input
                  name='confirmPassword'
                  maxLength={50}
                  {...registerChangePassword('confirmPassword')}
                  type={showConfirmPassword ? 'text' : 'password'}
                  id='confirm_new_password'
                  placeholder={t('signup.password')}
                  className='mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                />
                <button
                  type='button'
                  onClick={() => handleShowConfirmPassword()}
                  className='absolute top-1/2 right-4 transform translate-y-[-25%]'
                >
                  {showConfirmPassword ? (
                    <ShowPasswordIcon className='h-7 w-7' />
                  ) : (
                    <HidePasswordIcon className='h-7 w-7' />
                  )}
                </button>
                <p
                  id='outlined_error_help'
                  class='text-start mt-2 text-xs text-red-600 dark:text-red-400'
                >
                  {formStateChangePassowrd?.errors?.confirmPassword?.message}
                </p>
              </div>
            </div>

            <div className='flex justify-center items-center gap-4 mt-8'>
              <button
                type='button'
                onClick={() => history.goBack()}
                className='text-lg w-[226px] leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 py-4 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'
              >
                <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                {t('cancel')}
              </button>

              <button
                className='text-lg w-[226px] leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 py-4 rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
                disabled={loading}
              >
                <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                {loading ? <Loader type={LOADER.BUTTON} /> : t('save')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default MyAccount
