import React from 'react'
import Select from 'react-select'
import { BackArrow, UserIcon } from '../../assets/image'
import Pagination from '../../components/Pagination'
import { usePlayerDetailController } from './usePlayerDetailController'
import Loader from '../../components/Loader'
import { LOADER_HANDLER_TYPES } from '../../utils/constants'
import CurrentTimeDifference from '../../components/CurrentTimeDifference'
import { toFixed } from '../../utils/common-functions'
const PlayerDetail = (props) => {
  const {
    t,
    playerDetails,
    columns,
    playerSearchList,
    handlePlayerSelection,
    bucketData,
    history,
    onPageChange,
    showPagination,
    showNextBtn,
    currentOffset,
    loading,
    visiblePlayerRecords
  } = usePlayerDetailController()
  return (
    <>
      {loading ? (
        <Loader component={LOADER_HANDLER_TYPES.table} />
      ) : (
        <div className='max-w-[1320px] mx-auto pt-10 min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
          {bucketData && (
            <div
              onClick={() => history.goBack()}
              className='text-xl max-sm:text-base font-normal text-white flex gap-2 items-center mb-7'
            >
              <BackArrow /> {t('back')}
            </div>
          )}
          <div className='flex gap-8 max-md:flex-wrap'>
            {/* ---- Player Cards Section ---- */}
            <div className='bg-primaryColor-200 rounded-3xl p-6 max-sm:p-3 flex-1 max-md:flex-[1_100%]'>
              <div>
                <form>
                  <div class='relative'>
                    <div class='absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none z-[1]'>
                      <svg
                        class='w-4 h-4 text-primaryColor-500'
                        aria-hidden='true'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 20 20'
                      >
                        <path
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                        />
                      </svg>
                    </div>
                    <Select
                      placeholder={t('searchPlayer')}
                      className='react-border-select react-border-search'
                      classNamePrefix='react-border-inner'
                      defaultValue={null}
                      onChange={handlePlayerSelection}
                      // onInputChange={handleChange}
                      // inputValue={value}
                      options={playerSearchList}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                    />
                    {/* <input onChange={handleChange} value={value} type="search" id="default-search" class="block w-full !pl-10 p-2.5 !leading-none tabletscreen:px-4 tabletscreen:py-[15px] text-sm tabletscreen:text-lg text-primaryColor-500 border border-primaryColor-500 rounded-[40px] bg-transparent focus-visible:border-primaryColor-500 focus-visible:outline-none placeholder:text-primaryColor-500" placeholder="Search Contest" required /> */}
                  </div>
                </form>
              </div>

              <div className='flex justify-between items-center pt-[30px] flex-wrap max-xl:gap-4'>
                <div className='flex gap-8 max-lg:gap-4 max-sm:gap-2 items-center'>
                  {playerDetails?.image ? (
                    <img
                      className='w-[126px] h-[126px] max-lg:w-[100px] max-lg:h-[100px] max-md:w-[62px] max-md:h-[62px] object-contain rounded-xl'
                      src={playerDetails?.image}
                      alt='player image'
                    />
                  ) : (
                    <UserIcon className='w-[126px] h-[126px] max-lg:w-[100px] max-lg:h-[100px] max-md:w-[62px] max-md:h-[62px] object-contain rounded-xl' />
                  )}

                  <div>
                    <span className='text-[22px] max-lg:text-base max-sm:text-xs py-2 max-lg:py-1 px-4 max-lg:px-2 text-primaryColor-500 bg-primaryColor-900 rounded-lg border-solid border border-primaryColor-500'>
                      {playerDetails?.position}
                    </span>
                    <h2 className='text-3xl max-lg:text-xl max-sm:text-base text-white font-semibold mt-2.5'>
                      {playerDetails?.name}
                    </h2>
                  </div>
                </div>

                <div className=' [&>:not(:last-child)]:pb-2 grid-cols-[repeat(2,_minmax(160px,_1fr))] max-tabletscreen:grid-cols-1 grid'>

                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'>
                      {t('careerAverage')}
                    </span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      <span className='text-white text-lg max-sm:text-xs font-semibold'>
                        {playerDetails?.careerAvg && toFixed(playerDetails?.careerAvg)}
                      </span>
                    </span>
                  </div>

                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'>
                      {t('salaryValue')}
                    </span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block text-white text-lg max-sm:text-xs font-semibold'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      ${' '}
                      <span className='text-white text-lg max-sm:text-xs font-semibold'>
                        {playerDetails?.value && toFixed(playerDetails?.value)}
                      </span>
                    </span>
                  </div>

                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'>
                      {t('careerYears')}
                    </span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      <span className='text-white text-lg max-sm:text-xs font-semibold'>
                        {playerDetails?.careerYears}
                      </span>
                    </span>
                  </div>
                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'></span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block text-white text-lg max-sm:text-xs font-semibold'>
                      <span className='px-4 text-primaryColor-500 font-normal'></span>
                      <span className='text-white text-lg max-sm:text-xs font-semibold'></span>
                    </span>
                  </div>
                </div>
                {/* <div className=' [&>:not(:last-child)]:pb-2'>
                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'>
                      {t('careerYears')}
                    </span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      <span className='text-white text-lg max-sm:text-xs font-semibold'>
                        {playerDetails?.careerYears}
                      </span>
                    </span>
                  </div>
                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'></span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block text-white text-lg max-sm:text-xs font-semibold'>
                      <span className='px-4 text-primaryColor-500 font-normal'></span>
                      <span className='text-white text-lg max-sm:text-xs font-semibold'></span>
                    </span>
                  </div>
                </div> */}
                {/* <div>
                                <a href="#" class="text-lg leading-none font-normal capitalize text-primaryColor-500 border-primaryColor-500 border-solid border px-6 py-3 inline-flex items-center justify-center  rounded-lg text-center hover:animate-btnHover transition-all hover:text-white hover:delay-200">Add to Roster</a>
                                <p className='text-white text-base font-normal capitalize flex items-center gap-2 mt-4'>
                                    <label htmlFor="BlueCheckbox" className='flex items-center justify-start'>
                                        <input type="checkbox" id='BlueCheckbox' className='cursor-pointer appearance-none w-6 h-6 bg-transparent border border-primaryColor-800 border-solid rounded-md checked:bg-bluecheckboxIcon checked:bg-no-repeat checked:bg-center' />
                                    </label>

                                    DNP Insurance
                                </p>
                            </div> */}
              </div>
            </div>
            {bucketData && (
              <div className='bg-primaryColor-200 rounded-3xl p-6 max-w-[400px] w-full max-lg:w-auto max-md:flex-[1_100%] max-md:max-w-full'>
                <div className='text-xl max-sm:text-base text-white font-semibold text-center bg-primaryColor-400 rounded-lg p-3 mb-[18px]'>
                  {bucketData?.bucketUid}
                </div>

                <div className=' [&>:not(:last-child)]:pb-2'>
                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'>
                      {t('timeRemaining')}
                    </span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block max-md:flex max-md:items-center max-md:justify-between'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>
                      <span className='text-white text-lg max-sm:text-xs font-semibold'>
                        <CurrentTimeDifference time={bucketData?.endTime} />
                      </span>
                    </span>
                  </div>
                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'>
                      {t('contestEnteryFee')}
                    </span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block text-white text-lg max-sm:text-xs font-semibold max-md:flex max-md:items-center max-md:justify-between'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      <span className='text-white text-lg max-sm:text-xs font-semibold'>
                        {' '}
                        <span>$</span>
                        {bucketData?.entryFee && toFixed(bucketData?.entryFee)}
                      </span>
                    </span>
                  </div>
                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'>
                      {t('contestSalaryCap')}
                    </span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block text-white text-lg max-sm:text-xs font-semibold max-md:flex max-md:items-center max-md:justify-between'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      <span className='text-white text-lg max-sm:text-xs font-semibold'>
                        <span>$</span>
                        {bucketData?.salaryCap && toFixed(bucketData?.salaryCap)}
                      </span>
                    </span>
                  </div>
                  <div className='w-full flex justify-between items-center'>
                    <span className='inline-block capitalize text-primaryColor-500 text-lg max-sm:text-xs'>
                      {t('remainingSalaryCap')}
                    </span>
                    <span className='w-[140px] max-sm:w-[100px] inline-block text-white text-lg max-sm:text-xs font-semibold max-md:flex max-md:items-center max-md:justify-between'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      <span className='text-white text-lg max-sm:text-xs font-semibold'>
                        <span>$</span>
                        {bucketData?.remainingSalaryCap && toFixed(bucketData?.remainingSalaryCap)}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>
            <div className='bg-primaryColor-200 p-4 mt-8 rounded-3xl'>
              <div className='overflow-x-auto'>
                <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
                  <thead className='p-2'>
                    <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-xs [&>th]:text-center'>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Year
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                        Game#
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                        pass-Yds
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                        pass-TD
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        pass-Int
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Rush-Yd
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Rush-TD
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Rec-Rec
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Rec-Yds
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Red-TD
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Fum-FL
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Fum-FF
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Fum-FR
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Def-SK
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Def-TD
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Def-Int
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Tack-Solo
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        K-XPM
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        K-FGM
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        K-2PM
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize '>
                        Totals
                      </th>
                    </tr>
                  </thead>

                  <tbody className='divide-y  divide-primaryColor-350 divide-solid '>
                    {visiblePlayerRecords?.map((row) => {
                      return (
                        <tr className='[&>td]:text-center'>
                          {columns.map((key) => {
                            return (
                              <td
                                className={`whitespace-nowrap px-1 py-8 max-sm:py-4 ${
                                  key !== 'year' &&
                                  key !== 'gameNumber' &&
                                  row?.[key] > 0
                                    ? 'text-customGreen-400 !font-bold'
                                    : 'text-primaryColor-850'
                                } text-base font-normal`}
                              >
                                {toFixed(row?.[key])}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {showPagination && (
              <Pagination
                currentOffset={currentOffset}
                onPageChange={onPageChange}
                disableNext={showNextBtn}
                itemCount={playerDetails?.PlayersRecords?.length || 0}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default PlayerDetail
