import { BackArrow } from '../../../../assets/image'
import UserIcon from '../../../../assets/image/usericon.png'
import dollarIcon from '../../../../assets/image/dollar.png'
import trophyIcon from '../../../../assets/image/trophy.png'
import winnerIcon from '../../../../assets/image/winner.png'
import Loader from '../../../../components/Loader'
import Podium from '../../../../components/Podium'
import { moment } from '../../../../config/moment'
import { dateTimeFormat, getNameFromUser } from '../../../../utils/common-functions'
import { LOADER } from '../../../../utils/constants'
import { useResultDetailsController } from './controller/useResultDetailsController'
import Confetti from 'react-confetti'
const ResultDetials = (props) => {
  const {
    t,
    winnerData,
    userId,
    loading,
    bucketUid,
    history,
    dimensions,
    userDetails
  } = useResultDetailsController()
  return (
    loading ?
      <Loader component={LOADER.TABLE_CELL} /> :
      <>

        <div className='max-w-[1320px] mx-auto px-4 2xl:px-0 min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
          <div
            onClick={() => history.goBack()}
            className='text-xl max-sm:text-base font-normal text-white flex gap-2 items-center mt-5'
          >
            <BackArrow /> {t('back')}
          </div>

          <div className='flex justify-center items-center text-primaryColor-500 text-lg '>
            <span className='text-primaryColor-500 text-lg max-lg:text-sm font-normal pr-3 capitalize'>{t('contestName')}</span>:
            <span className='text-white text-2xl max-lg:text-sm font-semibold pl-3'>{bucketUid}</span>
          </div>

          <Podium winners={winnerData?.winners} />

          <div className='bg-primaryColor-200 rounded-3xl p-4 mb-24 max-sm:mb-12'>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-transparent text-sm border-collapse rounded-3xl">
                <thead className="ltr:text-left rtl:text-right p-2 lg:table-header-group hidden">
                  <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-lg'>

                    <th className="whitespace-nowrap p-4 font-normal capitalize text-start">
                      {t('rank')}
                    </th>
                    <th className="whitespace-nowrap pl-14 pr-4 py-4 font-normal capitalize text-start">
                      {t('name')}
                    </th>
                    <th className="whitespace-nowrap p-4 font-normal capitalize">
                      {t('timeStamp')}
                    </th>
                    <th className="whitespace-nowrap p-4 font-normal capitalize">
                      {t('amount')}
                    </th>
                    <th className="whitespace-nowrap p-4 font-normal capitalize">
                      {t('points')}
                    </th>
                  </tr>
                </thead>

                <tbody className="[&>tr]:mb-4 [&>tr]:lg:mb-0 [&>*:last-child]:mb-0 lg:divide-y  lg:divide-primaryColor-350 divide-solid ">
                  {
                    winnerData?.data?.rows?.map(row => {
                      return (
                        userId == row?.user_id ?
                          <tr className='group [&>td]:py-4 flex flex-col items-start lg:table-row bg-primaryColor-100 lg:bg-transparent rounded-2xl lg:rounded-none max-lg:border-primaryColor-500 max-lg:border max-lg:border-solid activeRank lg:activeRank'>
                            <td dynamic-heading-value={t('rank')} className="text-center lg:text-start pl-[50%] lg:pl-1 tabletscreen:whitespace-nowrap px-1 text-white text-base tabletscreen:text-lg lg:text-xl font-medium w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                              <div className='flex justify-center lg:justify-start items-center gap-2'><img className='group-[.activeRank]:trophyFilter w-[26px] h-[26px] object-contain' src={trophyIcon} alt="trophy" /> <span>{row?.rank}</span></div>
                            </td>

                            <td dynamic-heading-value={t('name')} className="text-start pl-[50%] lg:pl-14 pr-1 tabletscreen:whitespace-nowrap text-white text-base font-medium w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                              <div className='flex justify-center lg:justify-start items-center gap-2'>
                              <div
                                className={
                                  row?.User?.image
                                    ? 'max-lg:hidden'
                                    : 'max-lg:hidden p-2 max-smallscreen:p-1.5 border border-solid activeProTab rounded-full inline-block w-[42px] max-sm:w-[34px] max-smallscreen:w-[28px] h-[42px] max-sm:h-[34px] max-smallscreen:h-[28px]'
                                }
                              >
                                <img
                                  src={
                                    row?.User?.image
                                      ? row?.User?.image
                                      : UserIcon
                                  }
                                  alt='user image'
                                  className={
                                    row?.User?.image
                                      ? 'w-[42px] h-[42px] object-cover rounded-full'
                                      : 'mx-auto'
                                  }
                                />
                              </div> 
                              <span>{getNameFromUser(row?.User)}</span></div>
                            </td>

                            <td dynamic-heading-value={t('timeStamp')} className="text-center pl-[50%] lg:pl-1 tabletscreen:whitespace-nowrap px-1 group-[.activeRank]:text-primaryColor-800 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">{row?.BucketUser?.submitted_at?moment(row?.BucketUser?.submitted_at).local().format('MMM DD, YYYY hh:mm a'):""}</td>

                            <td dynamic-heading-value={t('amount')} className="text-center pl-[50%] lg:pl-1 tabletscreen:whitespace-nowrap px-1 text-white text-base font-medium w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                              <div className='inline-flex justify-center items-center gap-2 bg-primaryColor-900 px-2 py-1.5 rounded-3xl'> <img className='w-[20px] h-[20px] object-contain' src={dollarIcon} alt="dollar" /> <span>{row?.prize}</span></div>
                            </td>

                            <td dynamic-heading-value={t('points')} className="text-center pl-[50%] lg:pl-1 tabletscreen:whitespace-nowrap px-1 text-white text-base font-medium w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                              <span className=' bg-primaryColor-900 px-2 py-1.5 rounded-3xl'>{!!row?.totalPoints ? parseFloat(row?.totalPoints).toFixed(2) : '0'}</span>
                            </td>
                          </tr> :
                          <tr className='[&>td]:py-4 flex flex-col items-start lg:table-row bg-primaryColor-100 lg:bg-transparent rounded-2xl lg:rounded-none max-lg:border-primaryColor-500 max-lg:border max-lg:border-solid ' >


                            <td dynamic-heading-value={t('rank')} className="text-center lg:text-start pl-[50%] lg:pl-1 tabletscreen:whitespace-nowrap px-1 text-white text-base tabletscreen:text-lg lg:text-xl font-medium w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                              <div className='flex justify-center lg:justify-start items-center gap-2'><img className='w-[26px] h-[26px] object-contain' src={trophyIcon} alt="trophy" /> <span>{row?.rank}</span></div>
                            </td>

                            <td dynamic-heading-value={t('name')} className="text-start pl-[50%] lg:pl-14 pr-1 tabletscreen:whitespace-nowrap text-white text-base font-medium w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                              <div className='flex justify-center lg:justify-start items-center gap-2'>
                              <div
                                className={
                                  row?.User?.image
                                    ? 'max-lg:hidden'
                                    : 'max-lg:hidden p-2 max-smallscreen:p-1.5 border border-solid activeProTab rounded-full inline-block w-[42px] max-sm:w-[34px] max-smallscreen:w-[28px] h-[42px] max-sm:h-[34px] max-smallscreen:h-[28px]'
                                }
                              >
                                <img
                                  src={
                                    row?.User?.image
                                      ? row?.User?.image
                                      : UserIcon
                                  }
                                  alt='user image'
                                  className={
                                    row?.User?.image
                                      ? 'w-[42px] h-[42px] object-cover rounded-full'
                                      : 'mx-auto'
                                  }
                                />
                              </div> 
                              <span>{getNameFromUser(row?.User)}</span></div>
                            </td>

                            <td dynamic-heading-value={t('timeStamp')} className="text-center pl-[50%] lg:pl-1 tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">{row?.BucketUser?.submitted_at?moment(row?.BucketUser?.submitted_at).local().format('MMM DD, YYYY hh:mm a'):""}</td>

                            <td dynamic-heading-value={t('amount')} className="text-center pl-[50%] lg:pl-1 tabletscreen:whitespace-nowrap px-1 text-white text-base font-medium w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                              <div className='inline-flex justify-center items-center gap-2 bg-primaryColor-900 px-2 py-1.5 rounded-3xl'> <img className='w-[20px] h-[20px] object-contain' src={dollarIcon} alt="dollar" /> <span>{row?.prize}</span></div>
                            </td>

                            <td dynamic-heading-value={t('points')} className="text-center pl-[50%] lg:pl-1 tabletscreen:whitespace-nowrap px-1 text-white text-base font-medium w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                              <span className=' bg-primaryColor-900 px-2 py-1.5 rounded-3xl'>{!!row?.totalPoints ? parseFloat(row?.totalPoints).toFixed(2) : '0'}</span>
                            </td>
                          </tr>

                      )
                    })
                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>
        {
          userId == winnerData?.winners?.[0]?.user_id && 
          <Confetti
            width={dimensions.width}
            height={dimensions.height}
            //startVelocity={1000}
            gravity={0.5}
            agnle={90}
            confettiSource={{
              x:dimensions.width/2,
              y:dimensions.height
            }}
            recycle={false}
            initialVelocityY={50}
            initialVelocityX={20}
            numberOfPieces={1000}
        />
        }
      </>
  )
}

export default ResultDetials

