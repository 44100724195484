import { LogoSvg, MaintananceIcon } from "../../assets/image"

const MaintenanceMode = () => {
    return (
        <>
            <div className="bg-heroPattern bg-center bg-cover">
                <div className='max-w-[1320px] mx-auto px-5 2xl:px-0 h-[100dvh] pb-10 flex justify-center items-center'>
                    <div className='max-w-[1257px] mx-auto flex gap-[40px] justify-between items-center max-md:flex-col-reverse'>
                        <div className='w-1/2 max-md:w-full max-md:text-center'>

                            <h1 className="text-white text-6xl max-xl:text-5xl max-lg:text-4xl max-sm:text-3xl font-bold pb-4">We are <br /><span className="text-primaryColor-800 text-6xl max-xl:text-5xl max-lg:text-4xl max-sm:text-3xl font-bold">Under Maintenance</span></h1>
                            <p className="text-white text-base">This Website is currently undergoing scheduled maintenance. We should be back shortly</p>
                        </div>
                        <div className='w-1/2 max-md:w-full'>
                            <div className="py-4 bg-primaryColor-200 rounded-xl border-solid border-[10px] max-lg:border-[5px] border-primaryColor-800 ">
                                <div className="flex justify-center">
                                    <LogoSvg className='max-w-72 w-full max-md:max-w-48 h-full' />
                                </div>
                                <div className="pt-14 pb-8 flex justify-center">
                                    <MaintananceIcon className='w-full h-full max-md:w-1/2' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MaintenanceMode