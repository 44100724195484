import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUserInfoService,
  updateProfilePhotoService,
  updateUserInfoService,
} from '../../../../network/services/users.service'
import { setLoader } from '../../../redux-slices/Users/user'

/**
 * Fetch User info who is logged in
 */
export const fetchUserInformation = createAsyncThunk(
  'user/user-detail',
  async (_, thunkApi) => {
    try {
      const res = await getUserInfoService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update User info who is logged in
 */
export const updateUserInformation = createAsyncThunk(
  'user/update-profile',
  async ({ params, addToast }, thunkApi) => {
    try {
      const res = await updateUserInfoService(params, addToast)
      thunkApi.dispatch(setLoader(false))
      addToast('Profile updated', { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())

      // onSuccess()
      return res
    } catch (error) {
      thunkApi.dispatch(setLoader(false))
      addToast(error[0].description, { appearance: 'error' })
      thunkApi.dispatch(fetchUserInformation())

      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateProfilePhoto = createAsyncThunk(
  'user/upload-profile-photo',
  async ({ data, addToast }, thunkApi) => {
    try {
      const res = await updateProfilePhotoService(data, addToast)
      thunkApi.dispatch(setLoader(false))
      addToast('Profile picture updated', { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      addToast(error?.[0]?.description, { appearance: 'error' })
      thunkApi.dispatch(setLoader(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
