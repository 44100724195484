import { LANGUAGE } from '../../utils/constants/index'

export const setLocalLanguage = (lang) => {
  localStorage.setItem(LANGUAGE, lang)
}

export const getLocalLanguage = () => {
  return localStorage.getItem(LANGUAGE)
}

export const setItem = (item, value) => {
  localStorage.setItem(item, JSON.stringify(value))
}

export const getItem = item => {
  const value = localStorage.getItem(item)
  return value ? JSON.parse(value) : null
}
