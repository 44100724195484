import { createAsyncThunk } from '@reduxjs/toolkit'
import { getBucketWinnerService, getResultListService, getRosterDetailService } from '../../../../network/services/result.service'
export const getResultList = createAsyncThunk(
  'result/list',
  async (params, thunkApi) => {
    try {
      const res = await getResultListService(params)
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      console.log(error)
      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
export const getBucketWinner = createAsyncThunk(
  'result/winners',
  async (params, thunkApi) => {
    try {
      const res = await getBucketWinnerService(params)
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      console.log(error)
      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
})

export const getRosterDetail = createAsyncThunk(
  'result/user-roster',
  async ({ params, onSuccess, onError, addToast }, thunkApi) => {
    try {
      const res = await getRosterDetailService(params)
      onSuccess(res)
      return res
    } catch (error) {
      onError()
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
