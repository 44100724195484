import { createSlice } from '@reduxjs/toolkit'
import { userLogout } from '../../redux/thunk/Auth/Auth';
import { getTransactionList } from '../../redux/thunk/Transaction/Transaction';

const initialState = {
    transactionList: []
}

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

      .addCase(getTransactionList.fulfilled, (state, action) => {
        return {
          ...state,
          transactionList: action.payload
        }
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...initialState
        }
      })
    }
})

export default transactionSlice.reducer
