import { Menu, Transition } from '@headlessui/react'
import dollarIcon from '../../assets/image/dollar-symbol.png'
import { useSelector } from 'react-redux'
import { toFixed } from '../../utils/common-functions'
import { BonusIcon } from '../../icons'
import { Fragment } from 'react'
import { WalletIcon, ArrowDown } from '../../../src/assets/image'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown() {
  const { userDetails } = useSelector((state) => state.userSlice)

  return (
    // <Menu as='div' className='relative inline-block text-left'>
    //   <div className='flex justify-center align-center'>
    //     <Menu.Button className='mr-2 inline-flex items-center w-full justify-center gap-x-1.5 bg-transparent p-2 text-xs font-semibold text-white border-[1px] border-solid border-white rounded-3xl'>
    //       <img src={dollarIcon} alt='' />{' '}
    //       <span className='text-base leading-none'>
    //         {userDetails?.wallets?.[0]?.amount &&
    //           toFixed(userDetails?.wallets?.[0]?.amount)}
    //       </span>
    //     </Menu.Button>
    //     <Menu.Button className='inline-flex items-center w-full justify-center gap-x-1.5 bg-transparent p-2 text-xs font-semibold text-white border-[1px] border-solid border-white rounded-3xl'>
    //       <BonusIcon />{' '}
    //       <span className='text-base leading-none'>
    //         {userDetails?.wallets?.[0]?.nonCashAmount &&
    //           toFixed(userDetails?.wallets?.[0]?.nonCashAmount)}
    //       </span>
    //     </Menu.Button>
    //   </div>
    // </Menu>

    <Menu as="div" className="relative inline-block text-left">
      <div className='leading-none'>
        <Menu.Button className='mr-2 inline-flex items-center w-full justify-between bg-transparent p-0 text-xs font-semibold text-white border-[1px] border-solid border-white rounded-3xl'>
          {/* <img src={dollarIcon} alt='' />{' '} */}
          <span className='bg-btnGrdient p-2 rounded-full'><WalletIcon /></span>
          <span className='text-base leading-none p-2 flex items-center'>
            <span className='pr-1'>$</span>
            {userDetails?.wallets?.[0]?.amount &&
              toFixed(userDetails?.wallets?.[0]?.amount)}
              <span className='pl-1'><ArrowDown/> </span>
          </span>
          
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right ring-1 ring-black ring-opacity-5 focus:outline-none border-[1px] border-solid border-primaryColor-500 rounded-lg bg-primaryColor-200 shadow-menushadow">
          <div className="py-1 divide-y-[1px] divide-primaryColor-350">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-primaryColor-100 text-white' : 'text-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <div className='flex justify-between items-center'>
                    <span className='capitalize text-white font-semibold'>wallet Balance</span>
                    <span className='text-base leading-none font-semibold flex items-center'>
                      <span className='pr-1'><img src={dollarIcon}/></span>
                      {userDetails?.wallets?.[0]?.amount &&
                        toFixed(userDetails?.wallets?.[0]?.amount)}
                    </span>
                  </div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-primaryColor-100 text-white' : 'text-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <div className='flex justify-between items-center'>
                    <span className='capitalize text-white font-semibold'>Bonus Balance</span>
                    <span className='text-base leading-none font-semibold flex items-center'>
                    <span className='pr-1'><img src={dollarIcon}/></span>
                      {userDetails?.wallets?.[0]?.nonCashAmount &&
                        toFixed(userDetails?.wallets?.[0]?.nonCashAmount)}
                    </span>
                  </div>
                </a>
              )}
            </Menu.Item>

          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
