import plusIcon from '../../assets/image/plus.png'

const Cashier = ({ setShowWalletModal, t }) => {
  return (
    <button
      onClick={() => setShowWalletModal(true)}
      className='leading-tight px-4 py-2.5 text-base bg-btnGrdient rounded-3xl overflow-hidden group relative hover:bg-btnGrdient-to-r hover:from-btnGrdient hover:to-btnGrdient text-white transition-all ease-out duration-300'
    >
      <span className='absolute blur-2xl right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-primaryColor-800 rotate-12 group-hover:-translate-x-60 te-x-44 ease animate-btnMotion'></span>
      <span className='relative capitalize leading-tight flex items-center gap-2'>
        {t('cashier')} <img src={plusIcon} alt='plus icon' />
      </span>
    </button>
  )
}
export default Cashier
