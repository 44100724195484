import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { PAGINATION_LIMIT } from '../../utils/constants'
import { ArrowRight } from '../../assets/image'

const Pagination = ({ currentOffset, onPageChange, disableNext, itemCount, paginationLimit }) => {
  const { t } = useTranslation('home')

  return (
    <div className='flex items-center justify-center my-6'>
      <div className={`group ${currentOffset >= (paginationLimit || PAGINATION_LIMIT) ? 'pageEnable' : 'pageDisable'} capitalize flex items-center text-xl max-sm:text-xs font-normal`}>
        <span onClick={() => onPageChange(0)} className={`mr-10 max-sm:mr-4 ${currentOffset == 0 ? 'pageDisable' : 'pageEnable'} cursor-pointer`}>{t('first')}</span>
        <span className='flex justify-center align-center' onClick={() => {
            if (currentOffset >= (paginationLimit || PAGINATION_LIMIT)) onPageChange(currentOffset - (paginationLimit || PAGINATION_LIMIT))
          }}>
        <span><ArrowRight className={`rotate-180 group-[.pageDisable]:fill-primaryColor-400 cursor-pointer`} /></span>
        <span className='ml-[10px] cursor-pointer'>{t('prev')}</span>
            </span>
      </div>

      <div className='px-8 max-sm:px-4 text-xl max-sm:text-xs font-normal flex items-center gap-1'>
        <span className='text-primaryColor-800 cursor-pointer p-1'>{(currentOffset / (paginationLimit || PAGINATION_LIMIT)) + 1}</span>
        <span className='capitalize text-white text-base max-sm:text-xs cursor-pointer'>of</span>
        <span className='text-white text-base max-sm:text-xs cursor-pointer p-1'>{Math.floor(((itemCount - 1) / (paginationLimit || PAGINATION_LIMIT))) + 1}</span>
      </div>

      <div className={`group ${!disableNext && ((currentOffset / (paginationLimit || PAGINATION_LIMIT)) + 1 != Math.floor(((itemCount - 1) / (paginationLimit || PAGINATION_LIMIT))) + 1) ? 'pageEnable' : 'pageDisable'} capitalize flex items-center text-xl max-sm:text-xs font-normal`}>
        <span className='flex justify-center align-center' onClick={() => {
              if (!disableNext && ((currentOffset / (paginationLimit || PAGINATION_LIMIT)) + 1 != Math.floor(((itemCount - 1) / (paginationLimit || PAGINATION_LIMIT))) + 1)) onPageChange(currentOffset + (paginationLimit ||PAGINATION_LIMIT))
            }}>
          <span className='mr-[10px] cursor-pointer'>{t('next')}</span>
          <span><ArrowRight className='group-[.pageDisable]:fill-primaryColor-400 cursor-pointer' /></span></span>
        <span onClick={() => onPageChange(Math.floor((itemCount -1) / (paginationLimit || PAGINATION_LIMIT))*(paginationLimit ||PAGINATION_LIMIT))} className={`ml-10 max-sm:ml-4 ${(currentOffset / (paginationLimit || PAGINATION_LIMIT)) + 1 == Math.floor(((itemCount - 1) / (paginationLimit || PAGINATION_LIMIT))) + 1 ? 'pageDisable' : 'pageEnable'} cursor-pointer`}>{t('last')}</span>
      </div>
    </div>
  )
}

export default memo(Pagination)
