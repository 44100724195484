import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { getDnpPageData } from '../../../redux-store/redux/thunk/gameSetting'
export const useDnpPageController = () => {
    const dispatch = useDispatch()
    const { gameSettingLoader,dnpPage } = useSelector(state => state.gameSetting)
    let description = dnpPage? JSON.parse(dnpPage?.description) :null
    let content = dnpPage? JSON.parse(dnpPage?.content) :null
    const { addToast } = useToasts()
    const { t,i18n } = useTranslation('home')
    const lang = i18n.language
    const [loading,setLoading] = useState(false)
    useEffect(()=>{
        setLoading(true)
        dispatch(getDnpPageData({onSuccess}))
    },[])
    const onSuccess = () => {
        setLoading(false)
    }
    return {
        loading,
        lang,
        description,
        content,
        dnpPage
    }
}