import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis/index'

export const getTransactionListService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/transaction-list', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data

  })
}