import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  updateProfilePhoto,
  updateUserInformation
} from '../../../redux-store/redux/thunk/Users/User'
import { useToasts } from 'react-toast-notifications'
import { updatePassword } from '../../../redux-store/redux/thunk/Auth/Auth'
import {
  getCity,
  getState,
  getCountry
} from '../../../redux-store/redux/thunk/gameSetting'
import { useHistory } from 'react-router-dom'
import { setLoader } from '../../../redux-store/redux-slices/Users/user'

const format = ['png', 'jpeg', 'jpg']
export const useMyAccountController = () => {
  const { t } = useTranslation('home')
  const dispatch = useDispatch()
  const [countryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const { loading } = useSelector((state) => state.user)
  const { userDetails } = useSelector((state) => state.userSlice)
  const history = useHistory()
  const [edit, setEdit] = useState(true)
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const fileChangedHandler = (event) => {
    const currentFileSizeInKB =
      event.currentTarget.files[0] && event.currentTarget.files[0].size / 1000
    const currentFileFormat =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split('.')[1]
    if (currentFileSizeInKB <= 2048) {
      if (format.indexOf(currentFileFormat) > -1) {
        const file = event.currentTarget.files[0]
        uploadProfilePicHandler(file)
      } else {
        addToast(t('myAccount.imageFormat'), { appearance: 'error' })
      }
    } else {
      addToast(t('myAccount.minImageSize'), { appearance: 'error' })
    }
  }

  const uploadProfilePicHandler = async (file) => {
    if (file) {
      const data = {
        file
      }
      try {
        dispatch(setLoader(true))
        dispatch(updateProfilePhoto({ data, addToast }))
      } catch (e) {
        addToast(e.message, { appearance: 'error' })
      }
    } else {
      addToast(t('myAccount.pleaseSelectImage'), { appearance: 'error' })
    }
  }

  const { addToast } = useToasts()

  const onCountrySuccess = (res) => {
    setCountryList(res?.rows)
  }

  useEffect(() => {
    dispatch(getCountry({ onCountrySuccess }))
  }, [])

  const handleUpdateProfileSubmit = async (data) => {
    const params = {
      ...data,
      dateOfBirth: data?.dateOfBirth
        ? moment(data?.dateOfBirth).format('YYYY-MM-DD')
        : null,
      phone: data?.phone ? data?.phone : null,
      cityId: data?.cityId ? data?.cityId : null,
      stateId: data?.stateId ? data?.stateId : null,
      countryId: data?.countryId ? data?.countryId : null,
    }
    dispatch(setLoader(true))
    dispatch(updateUserInformation({ params, addToast }))
    setEdit(true)
  }

  const onChangePasswordSuccess = () => {
    resetChangePassword()
  }

  const handleChangePasswordSubmit = async (params) => {
    const { oldPassword, newPassword } = params
    dispatch(setLoader(true))
    dispatch(
      updatePassword({
        oldPassword,
        newPassword,
        onChangePasswordSuccess,
        addToast
      })
    )
  }

  const updateProfileSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(`${t('signup.errors.firstName.required')}`)
      .min(2, `${t('signup.errors.firstName.minLength')}`)
      .max(45, `${t('signup.errors.firstName.maxLength')}`)
      .matches(
        /^[a-zA-Z]+(?:[a-zA-Z]+)*$/,
        t('signup.errors.firstName.pattern')
      ),
    lastName: yup
      .string()
      .required(`${t('signup.errors.lastName.required')}`)
      .min(2, `${t('signup.errors.lastName.minLength')}`)
      .max(45, `${t('signup.errors.lastName.maxLength')}`)
      .matches(
        /^[a-zA-Z]+(?:[a-zA-Z]+)*$/,
        t('signup.errors.lastName.pattern')
      ),
    userName: yup
      .string()
      .required(`${t('signup.errors.userName.required')}`)
      .min(4, `${t('signup.errors.userName.minLength')}`)
      .max(20, `${t('signup.errors.userName.maxLength')}`)
      .matches(/^\w+$/, t('signup.errors.userName.pattern')),
    email: yup
      .string()
      .required(t('signup.errors.email.required'))
      .max(255, `${t('signup.errors.email.maxLength')}`)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signup.errors.email.pattern')
      ),
    phone: yup
      .string()
      .nullable()
      .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, t('signup.errors.phone.pattern')),
    dateOfBirth: yup
      .date()
      .nullable()
      .max(
        moment().subtract(18, 'years').toDate(),
        'signup.errors.dateOfBirth.minDate'
      )
      .min(
        moment('01-01-1970', 'DD-MM-YYYY').toDate(),
        t('signup.errors.dateOfBirth.maxDate')
      ),
    street: yup.string().max(100, t('signup.errors.street.maxLength')),
    cityId: yup.string().nullable(),
    stateId: yup.string().nullable(),
    countryId: yup.string().nullable(),
    zipCode: yup
      .string()
      .max(15, t('signup.errors.zipcode.maxLength'))
      .test('is-valid-zipcode', t('signup.errors.zipcode.pattern'), (value) => {
        if (!value) return true
        return /^[0-9]+$/.test(value)
      })
  })

  const changePasswordSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(`${t('signup.errors.password.required')}`)
      .min(8, `${t('signup.errors.password.pattern')}`)
      .max(16, `${t('signup.errors.password.pattern')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('signup.errors.password.pattern')
      ),
    newPassword: yup
      .string()
      .required(`${t('signup.errors.newPassword.required')}`)
      .min(8, `${t('signup.errors.newPassword.pattern')}`)
      .max(16, `${t('signup.errors.newPassword.pattern')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('signup.errors.newPassword.pattern')
      )
      .notOneOf(
        [yup.ref('oldPassword'), null],
        'New password must not be same as old password'
      ),
    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf(
        [yup.ref('newPassword'), null],
        t('signup.errors.confirmPassword.match')
      )
  })

  const {
    handleSubmit: handleSubmitUpdateProfile,
    register: registerUpdateProfile,
    formState: formStateUpdateProfile,
    reset: resetUpdateProfile,
    resetField: resetFieldUpdateProfile,
    setValue: setValueUpdateProfile,
    watch,
    trigger
  } = useForm({
    // defaultValues: {
    //   firstName: userDetails?.firstName
    // },
    resolver: yupResolver(updateProfileSchema),
    mode: 'all'
  })

  const {
    handleSubmit: handleSubmitChangePassword,
    register: registerChangePassword,
    formState: formStateChangePassowrd,
    reset: resetChangePassword,
    resetField: resetFieldChangePassword
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
    mode: 'all'
  })

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.countryId) {
        const countryId = userDetails?.countryId
        dispatch(getState({ countryId, onStateSuccess }))
      }
      if (userDetails?.stateId) {
        const stateId = userDetails?.stateId
        dispatch(getCity({ stateId, onCitySuccess }))
      }
      setValueUpdateProfile('firstName', userDetails?.firstName || '')
      setValueUpdateProfile('lastName', userDetails?.lastName || '')
      setValueUpdateProfile('userName', userDetails?.userName || '')
      setValueUpdateProfile('email', userDetails?.email || '')
      setValueUpdateProfile('phone', userDetails?.phone || null)
      setValueUpdateProfile(
        'dateOfBirth',
        (userDetails?.dateOfBirth &&
          moment(userDetails?.dateOfBirth, 'YYYY-MM-DD').toDate()) ||
          null
      )
      setValueUpdateProfile('street', userDetails?.street || '')
      setValueUpdateProfile('cityId', userDetails?.cityId || null)
      setValueUpdateProfile('stateId', userDetails?.stateId || null)
      setValueUpdateProfile('countryId', userDetails?.countryId || null)
      setValueUpdateProfile('zipCode', userDetails?.zipCode || '')
      trigger()
    }
  }, [userDetails, setValueUpdateProfile])

  const onStateSuccess = (res) => {
    setStateList(res?.rows)
  }

  const onCitySuccess = (res) => {
    setCityList(res?.rows)
  }

  const handleCityChange = (e) => {
    setValueUpdateProfile('cityId', e.id)
    trigger()
  }

  const handleStateChange = (e) => {
    const stateId = e?.id
    setCityList([])
    setValueUpdateProfile('cityId', '')
    setValueUpdateProfile('stateId', e.id)
    dispatch(getCity({ stateId, onCitySuccess }))
    trigger()
  }

  const handleCountryChange = (e) => {
    const countryId = e?.id
    setCityList([])
    setStateList([])
    setValueUpdateProfile('cityId', '')
    setValueUpdateProfile('stateId', '')
    setValueUpdateProfile('countryId', e.id)
    dispatch(getState({ countryId, onStateSuccess }))
    trigger()
  }

  const handleDateSelect = (e) => {
    setValueUpdateProfile('dateOfBirth', e)
    trigger()
  }

  const handleMobileNumberChange = (e) => {
    setValueUpdateProfile('phone', e)
    trigger()
  }

  const handleShowOldPassword = () => {
    setShowOldPassword((prev) => !prev)
  }

  const handleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev)
  }

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev)
  }

  return {
    t,
    handleUpdateProfileSubmit,
    handleChangePasswordSubmit,
    handleSubmitUpdateProfile,
    registerUpdateProfile,
    formStateUpdateProfile,
    resetUpdateProfile,
    handleSubmitChangePassword,
    registerChangePassword,
    formStateChangePassowrd,
    resetChangePassword,
    handleCityChange,
    handleStateChange,
    handleCountryChange,
    loading,
    countryList,
    stateList,
    cityList,
    userDetails,
    history,
    watch,
    fileChangedHandler,
    handleDateSelect,
    handleMobileNumberChange,
    edit,
    setEdit,
    showOldPassword,
    showNewPassword,
    showConfirmPassword,
    handleShowOldPassword,
    handleShowNewPassword,
    handleShowConfirmPassword
  }
}
