import React, {
  lazy,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import routes from './routesList'
import PrivateRoute from './HOC/PrivateRoutes/index'
import PublicRoutes from './HOC/PublicRoutes/index'
import {
  getAuthToken,
  isEarlyBirdAuthenticated,
  setAuthToken
} from '../utils/common-services/cookie.services'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInformation } from '../redux-store/redux/thunk/Users/User'
import {
  getAllCms,
  getAllSocialLinks,
  getLobbyContent,
  getLotteryInfo,
  getTimeSetting,
  getWebSettings
} from '../redux-store/redux/thunk/gameSetting'
import Cms from '../containers/Cms'
import { getItem } from '../utils/common-services/localstorage.services'
import Loader from '../components/Loader'
import ComingSoon from '../containers/ComingSoon'
import MaintenanceMode from '../containers/MaintananceMode'
import { ROUTE_PATHS } from '../utils/constants'
import EarlyBirdAuth from '../containers/EarlyBirdAuth'

const NotFoundComponent = lazy(() => import('../components/404'))

const RouteRenderer = (route,isComingSoon) => {
  const {
    component: Component,
    child: Child,
    showHeader = false,
    showFooter = false
  } = route
  
  if (!getAuthToken()) {
    const rememberAuthToken = getItem('authToken')
    if (rememberAuthToken) {
      setAuthToken(rememberAuthToken)
    }
  }
  const isAuthenticated = !!getAuthToken()
  const isEarlyBirdVerified =  !!isEarlyBirdAuthenticated()
  const redirectToComingSoon=isComingSoon && !isEarlyBirdVerified
  const isRouteEalryBird= route.path === ROUTE_PATHS.EARLY_BIRD 
  
  if(redirectToComingSoon && !isRouteEalryBird){
    return(
      <ComingSoon/>
    )
  }
  else if(redirectToComingSoon && isRouteEalryBird){
    <EarlyBirdAuth/>
  }
  return (
    <>
      {showHeader && <Header />}
      {route?.isPrivate ? (
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          Component={Component}
          child={Child}
          //redirectToHome={route.path == ROUTE_PATHS.COMINGSOON || route.path == ROUTE_PATHS.EARLY_BIRD}
          // redirectToComingSoon={isComingSoon && !isEarlyBirdVerified}
          // isRouteEalryBird={route.path === ROUTE_PATHS.EARLY_BIRD}
        />
      ) : (
        <PublicRoutes
          isAuthenticated={isAuthenticated}
          Component={Component}
          child={Child}
          //redirectToHome={route.path == ROUTE_PATHS.COMINGSOON || route.path == ROUTE_PATHS.EARLY_BIRD}
          // redirectToComingSoon={isComingSoon && !isEarlyBirdVerified}
          // isRouteEalryBird={route.path === ROUTE_PATHS.EARLY_BIRD}
        />
      )}
      {showFooter && <Footer />}
    </>
  )
}

const Wrapper = () => {
  const location = useLocation()
  const myRef = useRef(null)
  const [dynamicRoute, setDynamicRoute] = useState(routes)
  const { allCms } = useSelector((state) => state.gameSetting)
  const cms = allCms?.cms
  const { webSettings } = useSelector((state) => state.gameSetting)
  const isComingSoon = webSettings?.find(item=>item.key === "comingSoon")?.value ?? false
  useEffect(() => {
    if (cms) {
      updateRoutes()
    }
  }, [cms])
  const updateRoutes = () => {
    const newRoutes = cms?.map((item) => {
      return {
        path: `/${item?.slug}`,
        component: Cms,
        isPrivate: false,
        showHeader: true,
        showFooter: true
      }
    })
    setDynamicRoute([...routes, ...newRoutes])
  }
  useLayoutEffect(() => {
    myRef.current.scrollTo(0, 0)
  }, [myRef.current, location.pathname])

  return (
    <>
      <div ref={myRef}>
        <Switch>
          {dynamicRoute.map((route) => (
            <Route
              path={route.path}
              key={route.path}
              exact
              render={() => RouteRenderer(route,isComingSoon)}
            />
          ))}
          <Route component={() => <NotFoundComponent />} />
        </Switch>
      </div>
    </>
  )
}

const CustomRoutes = () => {
  const [loading,setLoading] = useState(true)
  const dispatch = useDispatch()
  const { webSettings } = useSelector((state) => state.gameSetting)
  const isComingSoon = webSettings?.find(item=>item.key === "comingSoon")?.value ?? false
  const isMaintenanceMode = webSettings?.find(item=>item.key === "maintenanceMode")?.value ?? false
  if (!getAuthToken()) {
    const rememberAuthToken = getItem('authToken')
    if (rememberAuthToken) {
      setAuthToken(rememberAuthToken)
    }
  }
  const token = !!getAuthToken()
  const onSuccess = ()=>setLoading(false)
  useEffect(() => {
    dispatch(getWebSettings({onSuccess}))
    if (token) {
      dispatch(fetchUserInformation())
      dispatch(getTimeSetting())
      dispatch(getLotteryInfo())
    }
    dispatch(getAllCms())
    dispatch(getAllSocialLinks())
    dispatch(getLobbyContent())
  }, [])
  return (
    <>
      {
        loading?
         <div className='h-[100dvh] flex justify-center items-center'>
          <Loader/>
         </div>:
          (isMaintenanceMode?
          <MaintenanceMode/>:
          <Wrapper/>)
      }
    </>
  )
}

export default CustomRoutes
