import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { setLoader } from '../../redux-store/redux-slices/Users/user'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../redux-store/redux/thunk/Auth/Auth'
import { useHistory } from 'react-router-dom'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CloseIcon,
  HidePasswordIcon,
  ShowPasswordIcon
} from '../../assets/image'
import { LOADER } from '../../utils/constants'
import Loader from '../../components/Loader'
import { setItem } from '../../utils/common-services/localstorage.services'
import { isEarlyBirdAuthenticated } from '../../utils/common-services/cookie.services'

const Login = ({
  showLoginModal,
  handleShowLogin,
  handleShowRegister,
  handleShowForgotPassword
}) => {
  const { t } = useTranslation('home')
  const { loading } = useSelector((state) => state.user)
  const [rememberMe, setRememberMe] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const cancelButtonRef = useRef(null)

  const onLoginSuccess = (res) => {
    if (rememberMe) {
      setItem('authToken', res?.accessToken)
    }
    handleShowLogin(false)
    dispatch(setLoader(false))
    addToast(t('signin.successfullyLoggedIn'), { appearance: 'success' })
    history.push('/lobby')
  }
  const OnLoginError = (error) => {
    dispatch(setLoader(false))
    addToast(error[0].description, { appearance: 'error' })
  }
  const handleLoginSubmit = useCallback(
    async (loginDetails) => {
      dispatch(setLoader(true))
      dispatch(
        userLogin({
          userNameOrEmail: loginDetails.userNameOrEmail,
          password: loginDetails.password,
          onLoginSuccess,
          OnLoginError
        })
      )
    },
    [onLoginSuccess, OnLoginError, dispatch]
  )

  const userNameOrEmailRef = useRef(null) // Create a ref for the input field
  const [showPassword, setShowPassword] = useState(false)

  const loginValidationSchema = yup.object().shape({
    userNameOrEmail: yup
      .string()
      .required(`${t('signin.errors.userName.required')}`),
    password: yup
      .string()
      .required(`${t('signin.errors.password.required')}`)
      .min(8, `${t('signin.errors.password.minLength')}`)
      .max(16, `${t('signin.errors.password.maxLength')}`)
  })

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    mode: 'all'
  })

  useEffect(() => {
    if (userNameOrEmailRef.current) {
      userNameOrEmailRef.current.focus() // Set focus on the input field
    }
  }, [userNameOrEmailRef.current])

  useEffect(() => {
    return () => {
      handleShowLogin(false)
    }
  }, [reset])

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  const handleOpenRegister = () => {
    handleShowLogin(false)
    handleShowRegister(true)
  }

  const handleOpenForgotPassword = () => {
    handleShowLogin(false)
    handleShowForgotPassword(true)
  }
  const isEarlyBirdVerified = isEarlyBirdAuthenticated()
  return (
    <>
      <Transition.Root show={showLoginModal} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          initialFocus={cancelButtonRef}
          onClose={() => handleShowLogin(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-popupBg-100 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full max-w-[472px]'>
                  <div className='p-6 bg-dialogBg'>
                    <button
                      type='button'
                      onClick={() => handleShowLogin(false)}
                      className='absolute right-6 top-5 cursor-pointer outline-none'
                    >
                      {/* <img src={closeIcon} alt="close btn" /> */}
                      <CloseIcon />
                    </button>
                    <div className=''>
                      <div className='mt-3 text-center'>
                        <Dialog.Title
                          as='h3'
                          className='text-[22px] font-semibold leading-6 text-royalNavy-200'
                        >
                          {t('signin.title')}
                        </Dialog.Title>

                        <div className='mt-6'>
                          <form
                            class=''
                            onSubmit={handleSubmit(handleLoginSubmit)}
                          >
                            <div>
                              <div class='relative'>
                                <input
                                  ref={userNameOrEmailRef}
                                  name='userNameOrEmail'
                                  maxLength={50}
                                  autoComplete='off'
                                  {...register('userNameOrEmail')}
                                  type='text'
                                  id='outlined_error'
                                  aria-describedby='outlined_error_help'
                                  class='block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                  placeholder=' '
                                />
                                <label
                                  for='outlined_error'
                                  class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
                                >
                                  {t('signin.username')}
                                </label>
                              </div>
                              <p
                                id='outlined_error_help'
                                class={
                                  errors &&
                                  errors?.userNameOrEmail &&
                                  errors?.userNameOrEmail?.message &&
                                  ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                }
                              >
                                {errors?.userNameOrEmail?.message}
                              </p>

                              <div className='text-start mt-4 flex'>
                                <label
                                  htmlFor='check-box-group'
                                  className='cursor-pointer relative'
                                >
                                  <input
                                    checked={rememberMe}
                                    onChange={() => setRememberMe(!rememberMe)}
                                    type='checkbox'
                                    id='check-box-group'
                                    className='checked:bg-checkboxIcon appearance-none rounded-sm bg-dustyGray-300 w-4 h-4 cursor-pointer'
                                  />
                                </label>
                                <p className='text-xs text-dustyGray-200 pl-1.5 font-normal'>
                                  {t('signin.rememberMe')}
                                </p>
                              </div>

                              <div class='relative'>
                                <input
                                  type={showPassword ? 'text' : 'password'}
                                  name='password'
                                  maxLength={50}
                                  autoComplete='off'
                                  {...register('password')}
                                  id='outlined_error'
                                  aria-describedby='outlined_error_help'
                                  class='block px-2.5 pb-2.5 pt-4 mt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                  placeholder=' '
                                />
                                <button
                                  type='button'
                                  onClick={() => handleShowPassword()}
                                  className='absolute top-1/2 right-4 transform translate-y-[-50%]'
                                >
                                  {showPassword ? (
                                    <ShowPasswordIcon className='h-7 w-7' />
                                  ) : (
                                    <HidePasswordIcon className='h-7 w-7' />
                                  )}
                                </button>
                                <label
                                  for='outlined_error'
                                  class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
                                >
                                  {t('signin.password')}
                                </label>
                              </div>
                              <p
                                id='outlined_error_help'
                                class={
                                  errors &&
                                  errors?.password &&
                                  errors?.password?.message &&
                                  ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                }
                              >
                                {errors?.password?.message}
                              </p>

                              <div className='text-end pt-2.5'>
                                <button
                                  type='button'
                                  onClick={handleOpenForgotPassword}
                                  className='text-primaryColor-600 text-base '
                                >
                                  {t('signin.forgetPassword')}
                                </button>
                              </div>

                              <div className='my-6'>
                                <button
                                  type='submit'
                                  className='w-full bg-btnGrdient inline-block p-4 text-[18px] text-white rounded-lg capitalize leading-tight'
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <Loader type={LOADER.BUTTON} />
                                  ) : (
                                    t('login')
                                  )}
                                </button>
                              </div>

                              {
                                !isEarlyBirdVerified &&
                                <div className='border-t border-solid border-dustyGray-300 pt-6'>
                                <p className='text-dustyGray-100 text-base font-normal'>
                                  {t('signin.dontHaveAccount')}?{' '}
                                  <button
                                    type='button'
                                    onClick={handleOpenRegister}
                                    className='text-primaryColor-600 font-semibold'
                                  >
                                    {t('signin.signUp')}
                                  </button>
                                </p>
                              </div>
                              }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Login
