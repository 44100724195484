import { createSlice } from '@reduxjs/toolkit'
import { getPlayerList, getSpotlightData, searchPlayer } from '../../redux/thunk/Player/Player'
import { fetchPlayerInformation } from '../../redux/thunk/Player/Player'
import { userLogout } from '../../redux/thunk/Auth/Auth'
import { getTopPicks } from '../../redux/thunk/Player/Player'

const initialState = {
    playerList: [],
    playerDetails: null,
    spotlightData:null,
    playerSearchList:[],
    topPicks:[],
    loading:false
}

const playerSlice = createSlice({
  name: 'playerSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlayerList.fulfilled, (state, action) => {
        return {
          ...state,
          playerList: action.payload
        }
      })
      .addCase(getSpotlightData.fulfilled, (state, action) => {
        return {
          ...state,
          spotlightData: action.payload
        }
      })
      .addCase(fetchPlayerInformation.fulfilled, (state, action) => {
        return {
          ...state,
          playerDetails: action.payload,
          loading:false
        }
      })
      .addCase(fetchPlayerInformation.pending, (state, action) => {
        return {
          ...state,
          playerDetails: action.payload,
          loading:true
        }
      })
      .addCase(fetchPlayerInformation.rejected, (state, action) => {
        return {
          ...state,
          playerDetails: action.payload,
          loading:false
        }
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...initialState
        }
      })
      .addCase(searchPlayer.fulfilled, (state, action) => {
        return {
          ...state,
          playerSearchList:action.payload.count>0?action.payload.rows:[],
          //loading:false
        }
      })
      .addCase(searchPlayer.pending, (state, action) => {
        return {
          ...state,
          loading:true
        }
      })
      .addCase(searchPlayer.rejected, (state, action) => {
        return {
          ...state,
          loading:false
        }
      })
      .addCase(getTopPicks.fulfilled, (state, action) => {
        return {
          ...state,
          topPicks:action.payload
        }
      })
    }
})

export default playerSlice.reducer
