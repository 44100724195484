import React, { useEffect, useState } from 'react'
import playerImg from '../../assets/image/user.png'
import {
  DownArrow,
  InfofilledIcon,
  MinusIcon,
  PlusIcon,
  PlusMinus,
  RugbyICon,
  UserIcon
} from '../../assets/image'
import playerIcon from '../../assets/image/player.png'
import { usePlayerSelectionController } from './controller/usePlayerSelectionController'
import { Link } from 'react-router-dom'
import Pagination from '../../components/Pagination'
import CurrentTimeDifference from '../../components/CurrentTimeDifference'
import RosterSectionPopup from './components/RosterSectionPopup'
import PlayerDetailPopup from './components/PlayerDetailPopup'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Loader from '../../components/Loader'
import RosterSection from './components/RosterSection'
import PlayerDetail from './components/PlayerDetail'
import { toFixed } from '../../utils/common-functions'

const PlayerSelection = (props) => {
  const {
    t,
    activeTab,
    currentOffset,
    showPagination,
    showNextBtn,
    playerList,
    rosterPlayerList,
    bucketDetails,
    remainingSalaryCap,
    fullRosterDnp,
    dnpInsuranceAmount,
    playerDetailSection,
    rosterInfoArray,
    positionTabArray,
    rosterPositionList,
    loader,
    setPlayerDetailSection,
    onTabChange,
    onPageChange,
    handleRosterPlayerList,
    handleRosterPlayerDelete,
    handleFullRosterDnp,
    handlePerPlayerDnp,
    handleViewRoster,
    handleSearch,
    listOrder,
    setListOrder,
    history
  } = usePlayerSelectionController()

  const [showRosterSectionPopup, setShowRosterSectionPopup] = useState(false)
  const [showPlayerDetailPopup, setShowPlayerDetailPopup] = useState(false)
  const [headerVisible, setHeaderVisible] = useState(false)

  const handleShowRosterSection = (value) => {
    setShowRosterSectionPopup(value)
  }

  const handleShowPlayerDetail = (value) => {
    setShowPlayerDetailPopup(value)
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop

      const threshold = window.innerHeight / 2
      setHeaderVisible(scrollY > threshold)
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    Aos.init({
      duration: 2000,
      disable: window.innerWidth < 1280
    })
  }, [])

  useEffect(() => {
    if (
      moment(
        bucketDetails?.startTime,
        'HH:mm:ssZ'
      ).isBefore(
        moment(bucketDetails?.endTime, 'HH:mm:ssZ')
      )
    ) {
      if (
        moment().isAfter(
          moment(
            bucketDetails?.endTime,
            'HH:mm:ssZ'
          ).local()
        )
      ) {
        history.push('/lobby')
      }
    } else if (
      moment(bucketDetails?.startTime, 'HH:mm:ssZ')
        .local()
        .isAfter(
          moment(
            bucketDetails?.endTime,
            'HH:mm:ssZ'
          ).local()
        )
    ) {
      if (
        moment().isBetween(
          moment(bucketDetails?.endTime, 'HH:mm:ssZ'),
          moment(bucketDetails?.startTime, 'HH:mm:ssZ')
        )
      ) {
        history.push('/lobby')
      }
    }
  }, [bucketDetails?.endTime])

  return (
    <>
      <div className='max-w-[1320px] mx-auto pt-10 py-10 max-containerWidth:px-5'>
        <div>
          {/* ---- Player Heading & Paragraph Section ---- */}
          <h1 className='flex justify-center items-center overflow-hidden'>
            <span className='text-xl px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full'>
              {t('title')}
            </span>
          </h1>
          <p className='text-base max-lg:text-sm text-primaryColor-950 text-center leading-5 pt-2'>
            {t('description')}
          </p>

          {/* ---- Player Detail Section ---- */}
          <div className='flex justify-between max-sm:justify-center max-sm:flex-wrap max-sm:gap-4 items-center bg-primaryColor-200 rounded-3xl px-6 max-sm:px-5 py-4 max-sm:py-4 my-8'>
            <div className='max-sm:flex-[1_100%] max-sm:text-center max-sm:border-b max-sm:border-solid max-sm:border-white max-sm:pb-4'>
              <p className='text-primaryColor-500 text-lg max-lg:text-base font-normal'>
                {t('contestName')}
              </p>
              <p className='flex items-center max-sm:justify-center gap-4 text-white text-2xl max-lg:text-xl max-sm:text-lg font-semibold'>
                {bucketDetails?.bucketUid}
                <RugbyICon className='w-[35px] h-[35px] max-lg:w-[30px] max-lg:h-[30px] object-contain' />
              </p>
            </div>
            <div className=' [&>:not(:last-child)]:pb-2'>
              <div className='w-full flex justify-between'>
                <span className='inline-block capitalize text-primaryColor-500 text-base max-sm:text-xs'>
                  {t('timeRemaining')}
                </span>
                <span className='w-[140px] max-sm:w-[100px] inline-block'>
                  <span className='px-4 text-primaryColor-500 font-normal'>
                    :
                  </span>{' '}
                  <span className='text-white text-base max-sm:text-xs font-semibold'>
                    <CurrentTimeDifference
                      time={bucketDetails?.endTime}
                    />
                  </span>
                </span>
              </div>
              <div className='w-full flex justify-between'>
                <span className='inline-block capitalize text-primaryColor-500 text-base max-sm:text-xs'>
                  {t('contestEntryFee')}
                </span>
                <span className='w-[140px] max-sm:w-[100px] inline-block text-white text-base max-sm:text-xs font-semibold'>
                  <span className='px-4 text-primaryColor-500 font-normal'>
                    :
                  </span>{' '}
                  <span className='text-white text-base max-sm:text-xs font-semibold'>
                  {bucketDetails?.bucketType == 'free'
                    ? t('free')
                    : `$ ${bucketDetails?.price && toFixed(bucketDetails?.price)}`}
                  </span>
                </span>
              </div>
              <div className='w-full flex justify-between'>
                <span className='inline-block capitalize text-primaryColor-500 text-base max-sm:text-xs'>
                  {t('contestSalaryCap')}
                </span>
                <span className='w-[140px] max-sm:w-[100px] inline-block text-white text-base max-sm:text-xs font-semibold'>
                  <span className='px-4 text-primaryColor-500 font-normal'>
                    :
                  </span>{' '}
                  $
                  <span className='text-white text-base max-sm:text-xs font-semibold'>
                    {bucketDetails?.budget}
                  </span>
                </span>
              </div>
            </div>
          </div>

          {/* ---- Player Detail Section Header ---- */}
          {headerVisible && (
            <div
              data-aos='fade-down'
              className='fixed top-0 left-0 w-full bg-primaryColor-200 z-10 max-lg:hidden'
            >
              <div className='max-w-[1320px] mx-auto flex justify-between max-sm:justify-center max-sm:flex-wrap max-sm:gap-4 items-center px-6 max-sm:px-5 py-2 max-sm:py-2 border-b-2 border-solid border-primaryColor-800'>
                <div className='max-sm:flex-[1_100%] max-sm:text-center max-sm:border-b max-sm:border-solid max-sm:border-white max-sm:pb-4'>
                  <p className='text-primaryColor-500 text-sm max-lg:text-sm font-normal'>
                    {t('contestName')}
                  </p>
                  <p className='flex items-center max-sm:justify-center gap-4 text-white text-base max-lg:text-base max-sm:text-lg font-semibold'>
                    {bucketDetails?.bucketUid}
                    <RugbyICon className='w-[25px] h-[25px] max-lg:w-[25px] max-lg:h-[25px] object-contain' />
                  </p>
                </div>
                <div className='flex gap-4'>
                  <div className='w-full flex justify-between'>
                    <span className='capitalize text-primaryColor-500 text-xs max-sm:text-xs whitespace-nowrap'>
                      {t('timeRemaining')}
                    </span>
                    <span className='flex text-white text-xs max-sm:text-xs font-semibold'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      <span className='text-white text-xs max-sm:text-xs font-semibold'>
                        <CurrentTimeDifference
                          time={bucketDetails?.endTime}
                        />
                      </span>
                    </span>
                  </div>
                  <div className='w-full flex justify-between'>
                    <span className='capitalize text-primaryColor-500 text-xs max-sm:text-xs whitespace-nowrap'>
                      {t('contestEntryFee')}
                    </span>
                    <span className='flex text-white text-xs max-sm:text-xs font-semibold'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      <span className='text-white text-xs max-sm:text-xs font-semibold'>
                      {bucketDetails?.bucketType == 'free'
                        ? t('free')
                        : `$ ${bucketDetails?.price && toFixed(bucketDetails?.price)}`}
                      </span>
                    </span>
                  </div>
                  <div className='w-full flex justify-between'>
                    <span className='capitalize text-primaryColor-500 text-xs max-sm:text-xs whitespace-nowrap'>
                      {t('contestSalaryCap')}
                    </span>
                    <span className='flex text-white text-xs max-sm:text-xs font-semibold'>
                      <span className='px-4 text-primaryColor-500 font-normal'>
                        :
                      </span>{' '}
                      $
                      <span className='text-white text-xs max-sm:text-xs font-semibold'>
                        {bucketDetails?.budget}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 items-start'>
          {/* ---- Select Legend Section ---- */}
          <div>
            <div className='bg-primaryColor-200 rounded-3xl overflow-hidden'>
              <div className='flex items-center justify-between p-6 bg-btnGrdient'>
                <h4 className='text-xl max-sm:text-base font-semibold text-white capitalize'>
                  {t('selectLegend')}
                </h4>
                <p className='text-base max-sm:text-xs font-normal text-white flex items-center gap-4 max-sm:gap-2 capitalize'>
                  {t('rosterInfo')} :
                  <div className='has-tooltip transition-all delay-100 ease-linear cursor-pointer'>
                    <div className='text-sm tooltip rounded-lg shadow-lg p-3 bg-primaryColor-200 text-primaryColor-950 -mt-12 transition-all delay-100 ease-linear max-lg:right-[70px] max-w-[260px] ml-6'>
                      <div className=''>
                        {t('totalPlayer')}:{' '}
                        {bucketDetails?.maximumPlayers},
                      </div>
                      {rosterInfoArray?.map((item, index) => {
                        return (
                          <div key={index}>
                            {item?.name} {t('player')}: {item?.playerLimit},
                          </div>
                        )
                      })}
                    </div>
                    <InfofilledIcon className='w-[22px] max-sm:w-[18px] h-[22px] max-sm:h-[18px]' />
                  </div>
                </p>
              </div>

              <div className='bg-primaryColor-900 p-2 tabletscreen:p-6 '>
                <form>
                  <div className='relative'>
                    <div className='absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none'>
                      <svg
                        className='w-4 h-4 text-primaryColor-500'
                        aria-hidden='true'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 20 20'
                      >
                        <path
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                        />
                      </svg>
                    </div>
                    <input
                      onChange={handleSearch}
                      type='search'
                      id='default-search'
                      className='block w-full !pl-10 p-2.5 !leading-none tabletscreen:px-4 tabletscreen:py-[15px] text-sm tabletscreen:text-lg text-primaryColor-500 border border-primaryColor-500 rounded-[40px] bg-transparent focus-visible:border-primaryColor-500 focus-visible:outline-none placeholder:text-primaryColor-500'
                      placeholder={t('searchPlayer')}
                      required
                    />
                  </div>
                </form>

                <div className='mt-6'>
                  <ul className='bg-primaryColor-100 rounded-[56px] flex items-center justify-between p-2 [&>li]:flex-1'>
                    {positionTabArray?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => onTabChange(item?.name)}
                          className={`${
                            activeTab == item?.name ? 'resultBtnBg' : ''
                          } cursor-pointer text-center p-2.5 tabletscreen:p-3 flex flex-col items-center group`}
                        >
                          <span className='text-xs tabletscreen:text-base uppercase text-primaryColor-800 group-[.resultBtnBg]:text-dustyGray-700 !leading-tight'>
                            {t(item?.name)}
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>

              <div className=' p-2 tabletscreen:p-4'>
                <div className='overflow-x-auto overflow-y-hidden max-h-[650px]'>
                  {loader ? (
                    <Loader />
                  ) : (
                    <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
                      <thead className='ltr:text-left rtl:text-right p-2'>
                        <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-xs [&>th]:tabletscreen:text-base'>
                          <th className='text-start whitespace-nowrap pl-4 pr-1 py-4 font-normal capitalize '>
                            {t('playername')}
                          </th>
                          <th className='whitespace-nowrap px-1 py-4 font-normal capitalize'>
                            {t('position')}
                          </th>
                          <th className='whitespace-nowrap px-1 py-4 font-normal capitalize'>
                            {t('careerAvg')}
                          </th>
                          <th className='whitespace-nowrap px-1 py-4 font-normal capitalize '>
                            {t('salary')}
                          </th>
                          <th className='whitespace-nowrap px-1 py-4 font-normal capitalize pr-4'>
                            <div class='flex justify-end mt-4 items-center gap-1'>
                              <div onClick={() => setListOrder('asc')}>
                                <DownArrow className='w-[25px] h-[25px] cursor-pointer hover:fill-white ' />
                              </div>
                              <div onClick={() => setListOrder('desc')}>
                                <DownArrow className='w-[25px] h-[25px] cursor-pointer rotate-180 hover:fill-white' />
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody className='divide-y  divide-primaryColor-350 divide-solid '>
                        {playerList &&
                          playerList?.rows &&
                          playerList.rows.map((item, index) => {
                            return (
                              <tr className='[&>td]:text-center' key={index}>
                                <td className='whitespace-nowrap px-1 py-4'>
                                  {/* desktop view */}
                                  <div
                                    onClick={() => setPlayerDetailSection(item)}
                                    className='items-center gap-2 hidden lg:flex'
                                  >
                                    {' '}
                                    {item?.image ? (
                                      <img
                                        className='hidden tabletscreen:block w-[40px] h-[40px] object-cover rounded-full cursor-pointer'
                                        src={item?.image}
                                        alt='game image'
                                      />
                                    ) : (
                                      <UserIcon className='cursor-pointer hidden tabletscreen:block w-[40px] h-[40px] bg-primaryColor-100 object-contain rounded-full p-1' />
                                    )}{' '}
                                    <span className='text-primaryColor-850 text-xs tabletscreen:text-base cursor-pointer'>
                                      {item?.name}
                                    </span>
                                  </div>
                                  {/* desktop view end */}
                                  {/* mobile view */}
                                  <div
                                    onClick={() => { setPlayerDetailSection(item)
                                      handleShowPlayerDetail(true)}}
                                    className='flex items-center gap-2 lg:hidden'
                                  >
                                    {' '}
                                    {item?.image ? (
                                      <img
                                        className='hidden tabletscreen:block w-[32px] h-[32px] object-cover rounded-full'
                                        src={item?.image}
                                        alt='game image'
                                      />
                                    ) : (
                                      <UserIcon className='hidden tabletscreen:block w-[32px] h-[32px] bg-primaryColor-100 object-contain rounded-full p-1' />
                                    )}{' '}
                                    <span className='text-primaryColor-850 text-xs tabletscreen:text-base'>
                                      {item?.name}
                                    </span>
                                  </div>
                                  {/* mobile view end */}
                                </td>

                                <td className='uppercase whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                  {item?.position}
                                </td>

                                <td className='whitespace-nowrap px-1 py-4 text-primaryColor-850 font-semibold text-xs tabletscreen:text-base'>
                                  {item?.careerAvg && toFixed(item?.careerAvg)}
                                </td>

                                <td className='whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold '>
                                  $ <span>{item?.value && toFixed(item?.value)}</span>
                                </td>

                                <td className='whitespace-nowrap px-1 py-4'>
                                  {!rosterPlayerList
                                    .map((dataItem) => dataItem.id)
                                    .includes(item.id) ? (
                                    <button
                                      onClick={() =>
                                        handleRosterPlayerList(item)
                                      }
                                      className='group inline-block'
                                    >
                                      <PlusIcon className='group-hover:fill-white transition-all delay-100 ease-in-out w-[16px] h-[16px] max-sm:w-[16px] max-sm:h-[16px] object-contain' />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handleRosterPlayerDelete(item)
                                      }
                                      className='group inline-block'
                                    >
                                      <MinusIcon className='group-hover:fill-white transition-all delay-100 ease-in-out w-[16px] h-[16px] max-sm:w-[16px] max-sm:h-[16px] object-contain' />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            {!loader && showPagination && (
              <Pagination
                currentOffset={currentOffset}
                onPageChange={onPageChange}
                disableNext={showNextBtn}
                itemCount={playerList?.count}
                paginationLimit={8}
              />
            )}
          </div>

          {/* ---- Roster Section ---- */}
          <div className='grid gap-8 max-lg:hidden'>
            <RosterSection
              bucketDetails={bucketDetails}
              remainingSalaryCap={remainingSalaryCap}
              fullRosterDnp={fullRosterDnp}
              rosterPositionList={rosterPositionList}
              rosterPlayerList={rosterPlayerList}
              handlePerPlayerDnp={handlePerPlayerDnp}
              handleRosterPlayerDelete={handleRosterPlayerDelete}
              handleFullRosterDnp={handleFullRosterDnp}
              dnpInsuranceAmount={dnpInsuranceAmount}
              handleViewRoster={handleViewRoster}
            />

            <PlayerDetail
              playerDetailSection={playerDetailSection}
              bucketDetails={bucketDetails}
              remainingSalaryCap={remainingSalaryCap}
              handleRosterPlayerList={handleRosterPlayerList}
            />
          </div>
        </div>
      </div>

      <button
        onClick={() => handleShowRosterSection(true)}
        className='hidden max-lg:flex items-center gap-3 text-white fixed bottom-5 right-0 bg-btnGrdient pl-3 pr-6 py-2 z-10 rounded-s-xl activeProTab'
      >
        <UserIcon className='w-[32px] h-[32px] bg-primaryColor-100 object-contain rounded-full p-1' />
        {t('roster')}{' '}
        <div className='text-sm bg-primaryColor-200 w-6 h-6 p-2 rounded-full leading-none flex items-center'>
          {rosterPlayerList?.length}
        </div>
      </button>
      <RosterSectionPopup
        showRosterSectionPopup={showRosterSectionPopup}
        handleShowRosterSection={handleShowRosterSection}
        bucketDetails={bucketDetails}
        remainingSalaryCap={remainingSalaryCap}
        fullRosterDnp={fullRosterDnp}
        rosterPositionList={rosterPositionList}
        rosterPlayerList={rosterPlayerList}
        handlePerPlayerDnp={handlePerPlayerDnp}
        handleRosterPlayerDelete={handleRosterPlayerDelete}
        handleFullRosterDnp={handleFullRosterDnp}
        dnpInsuranceAmount={dnpInsuranceAmount}
        handleViewRoster={handleViewRoster}
      />
      <PlayerDetailPopup
        showPlayerDetailPopup={showPlayerDetailPopup}
        handleShowPlayerDetail={handleShowPlayerDetail}
        playerDetailSection={playerDetailSection}
        bucketDetails={bucketDetails}
        remainingSalaryCap={remainingSalaryCap}
        handleRosterPlayerList={handleRosterPlayerList}
      />
    </>
  )
}

export default PlayerSelection
