import React, { memo } from 'react'
import { LOADER } from '../../utils/constants'
import { MoonLoader, PulseLoader } from 'react-spinners'
import loaderIcon from '../../assets/image/loader.gif'

const Loader = ({ type }) => {
  const commonLoaderProps = {
    color: '#fff'
  }

  return (
    <>
      {type === LOADER.BUTTON ? (
        <PulseLoader {...commonLoaderProps} size={10} />
      ) : (
        <div className='flex justify-center items-center h-[200px]'>
          {/* <MoonLoader {...commonLoaderProps} size={50} /> */}
          <img src={loaderIcon} alt="" className='max-w-[100px]'/>

        </div>
      )}
    </>
  )
}

export default memo(Loader)
