import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { getSpotlightData, submitComment, submitLegendNominate } from '../../../redux-store/redux/thunk/Player/Player';
import { useToasts } from 'react-toast-notifications'
export const usePlayerSpotlightController = () => {
    const { t } = useTranslation('home')
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const {spotlightData} = useSelector(state=>state.playerSlice)
    const {userDetails} = useSelector(state=>state.userSlice)
    const profileImageurl = userDetails?.profileImageurl
    const {user} = useSelector(state=>state.userSlice)
    const [legendName,setLegendName] = useState("")
    const [comment,setComment] = useState("")
    const handleChange = (event) => {
        setLegendName(event.target.value)
    }
    const handleCommentChange = (event) => {
        setComment(event.target.value)
    }
    const onSuccess = () => {
        setLegendName("")
    }
    const onCommentSuccess = () => {
        setComment("")
        dispatch(getSpotlightData())
    }
    const handleNominate = () => {
        const params = {playerName:legendName.trim()}
        dispatch(submitLegendNominate({params,addToast,onSuccess}))
    }
    const handleAddComment = () => {
        const params = {comments:comment.trim(),spotlightId:Number(spotlightData?.id)}
        dispatch(submitComment({params,addToast,onSuccess:onCommentSuccess}))
    }
    useEffect(()=>{
        dispatch(getSpotlightData())
    },[])
    return {
        t,
        spotlightData,
        legendName,
        handleChange,
        handleNominate,
        handleCommentChange,
        handleAddComment,
        comment,
        profileImageurl
    }
    
}