import React from 'react'
import footerIcon from '../../assets/image/footerlogo.png'
import Dropdown from '../LandingPage/Dropdown'
import { CopyrightIcon } from '../../assets/image'
import { Link } from 'react-router-dom'
import { useFooterController } from './controller/useFooterController'
import { useTranslation } from 'react-i18next'
import { EMAILS } from '../../utils/constants'

const Footer = () => {
  const { t, i18n } = useTranslation('home')
  const { cms, socialLinks, lobbyContent } = useFooterController()
  const lang = i18n.language
  const footerDescription =
    lang == 'en'
      ? lobbyContent?.footerDescription
      : lobbyContent?.footerDescriptionSp
  return (
    <>
      {/* ---- Footer Section---- */}
      <footer className='p-0 m-0'>
        <div className='bg-primaryColor-200 px-5'>
          <div className='max-w-[1080px] mx-auto py-6 flex flex-wrap justify-between border-b-[1px] border-primaryColor-450 max-md:gap-[30px]'>
            <div className='max-w-[301px] max-md:max-w-full max-md:flex-[1_100%]'>
              <img
                className='max-w-[245px] max-h-[80px] object-contain'
                src={footerIcon}
                alt='logo'
              />
              <p className='text-sm text-primaryColor-450 font-normal leading-4 pt-3'>
                {footerDescription ?? t('footerDescription')}
              </p>
            </div>
            <div className='max-w-[150px]'>
              <h5 className='text-xl leading-6 capitalize text-white pb-2.5'>
                {t('platform')}
              </h5>
              <ul className='flex flex-col gap-2'>
                <li className='text-[13px] text-primaryColor-450 font-semibold list-none leading-4 capitalize'>
                  <Link to='/player-spotlight'>{t('playerSpotlight')}</Link>
                </li>

                <li className='text-[13px] text-primaryColor-450 font-semibold list-none leading-4 capitalize'>
                  <Link to='/contact-us'>{t('contactUs')}</Link>
                </li>
                {/* <li className='text-[13px] text-primaryColor-450 font-semibold list-none leading-4 capitalize'><Link to="#">FAQ</Link></li> */}
                <li className='text-[13px] text-primaryColor-450 font-semibold list-none leading-4 capitalize'>
                  <Link to='/dnp-insurance'>{t('dnpInsurance')}</Link>
                </li>
              </ul>
            </div>
            <div className='max-w-[250px]'>
              <h5 className='text-xl leading-6 capitalize text-white pb-2.5'>
                {t('aboutUs')}
              </h5>
              {/* <ul className='flex flex-col gap-2'>
                  {
                    cms?.map(item=>
                      item?.slug == 'dnp-insurance'?<></>:
                      <li className='text-[13px] text-primaryColor-450 font-semibold list-none leading-4 capitalize'><Link to={`/${item?.slug}`}>{lang == "en"?item?.title:(item?.titleSp ?? item?.title)}</Link></li>
                    )
                  }
                </ul> */}
              <div className='flex'>
                {cms?.length > 0 ?
                  Array(Math.ceil(cms?.length / 4))
                    .fill('')
                    .map((itm, indx) => {
                      let i = indx * 4
                      return (
                        <ul className='flex flex-col gap-2'>
                          {cms?.slice(i, i + 4).map((item, index) =>
                            item?.isFooter && 
                            <li className='text-[13px] text-primaryColor-450 font-semibold list-none leading-4 capitalize'>
                            <Link to={`/${item?.slug}`}>
                              {lang == 'en'
                                ? item?.title
                                : item?.titleSp ?? item?.title}
                            </Link>
                          </li>
                          )}
                        </ul>
                      )
                    }) : <></>
                  }
              </div>
            </div>
            <div className='max-w-[150px]'>
              <h5 className='text-xl leading-6 capitalize text-white pb-2.5'>
                {t('socialMedia')}
              </h5>
              <ul className='flex flex-col gap-2'>
                {socialLinks?.map((item) => {
                  return (
                    <li className='text-[13px] text-primaryColor-450 font-semibold list-none leading-4 capitalize'>
                      <a
                        href={item.value}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {item.key}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className='max-w-[1080px] mx-auto py-6 flex justify-between'>
            <p className='flex items-center text-primaryColor-500 text-[11px] leading-none'>
              {/* <img className='w-3 mr-1' src={copyRightIcon} alt="copyright" />  */}
              <CopyrightIcon className='mx-1' /> {moment().format('YYYY')} Rosterstars{' '}
              {t('allRightsReserved')}.
            </p>
            <Dropdown />
          </div>
        </div>
        <div className='bg-primaryColor-100 px-5 flex justify-center items-center py-5 max-sm:py-4 divide-x  divide-white flex-wrap'>
          {EMAILS?.map((item, index) => {
            return (
              <div key={index} className='text-xs px-1.5 leading-4 font-medium'>
                <span className='text-primaryColor-450'>{t(item?.label)}</span>{' '}
                <a href='#' className='text-white'>
                  {item?.email}
                </a>
              </div>
            )
          })}
        </div>
      </footer>
    </>
  )
}

export default Footer
