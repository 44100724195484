import React from 'react'
import { Redirect } from 'react-router-dom'
const PublicRoute = ({ Component, isAuthenticated, child, showHeader = false, redirectToHome}) => {
  if(redirectToHome)
  return <Redirect to={{ pathname: '/' }} />
  return (
    <Component child={child} />
  )
}

export default PublicRoute
