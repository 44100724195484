import React from 'react'
import dnpIcon from '../../assets/image/dnp.png'
import { StarCheck } from '../../assets/image'
import { useTranslation } from 'react-i18next'
import { useDnpPageController } from './controller/useDnpPageController'
import Loader from '../../components/Loader'

const DnpInsurance = () => {
  const { t } = useTranslation('home')
  const { description, content, loading, lang,dnpPage } = useDnpPageController()
  return (

    <div className='max-w-[1320px] mx-auto pt-10 py-10 min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
      <div>
        <h1 className='flex justify-center items-center overflow-hidden'>
          <span className='text-[22px] max-sm:text-base px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full'>
            {t('dnpInsurance')}
          </span>
        </h1>
      </div>

      {/* ---- DNP Insurance Section---- */}
      {
        loading ?
          <div>
            <Loader />
          </div>
          :
          <div className='flex items-center gap-12 justify-between mb-[70px] relative bg-primaryColor-200 rounded-[18px] p-6 mt-6'>

            <div className='w-full'>
              {/* <p className='pb-6 text-primaryColor-700 text-base font-normal'>
                  {t('dnpInsuranceParagraph1')}
                </p>

                <p className='pb-6 text-primaryColor-700 text-base font-normal'>
                  {t('dnpInsuranceParagraph2')}
                </p> */}
              {
                description?.[lang]?.map(item => {
                  return (
                    <p className='pb-6 text-primaryColor-700 text-base font-normal'>
                      {item}
                    </p>
                  )
                })
              }

              <ul className='flex flex-col gap-2'>
                {
                  content?.[lang]?.map(item => {
                    return (
                      <li className='text-base font-normal text-primaryColor-700 flex items-center gap-4 list-none'>
                        <StarCheck className='w-[15px] h-[15px] object-contain' />{' '}
                        {item}
                      </li>
                    )
                  })
                }
                {/* <li className='text-base font-normal text-primaryColor-700 flex items-center gap-4 list-none'>
                    <StarCheck className='w-[15px] h-[15px] object-contain' />
                    {t('dnpText2')}
                  </li>
                  <li className='text-base font-normal text-primaryColor-700 flex items-center gap-4 list-none'>
                    <StarCheck className='w-[15px] h-[15px] object-contain' />{' '}
                    {t('dnpText3')}
                  </li>
                  <li className='text-base font-normal text-primaryColor-700 flex items-center gap-4 list-none'>
                    <StarCheck className='w-[15px] h-[15px] object-contain' />
                    {t('dnpText4')}
                  </li> */}
              </ul>
            </div>
            <div className='max-md:hidden'>
              <img
                className='w-[282px] h-[318px] object-contain'
                src={dnpPage?.image??dnpIcon}
                alt=''
              />
            </div>
          </div>
      }

    </div>
  )
}

export default DnpInsurance
