import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllCmsService,
  getAllSocialLinksService,
  getBannerService,
  getBonusDetailsService,
  getCityService,
  getCountryService,
  getDnpPageDataService,
  getLobbyContentService,
  getLotteryInfoService,
  getStateService,
  getTimeSettingService,
  getWebSettingsService,
  getWithdrawRequestService,
  getWithdrawalListService,
  setContactUsService,
  withdrawRequestCancelService
} from '../../../network/services/gamesetting'
import { setGameSettingLoader } from '../../redux-slices/gameSetting'
import { fetchUserInformation } from './Users/User'

export const getBanner = createAsyncThunk(
  'system/get-banner',
  async (_, thunkApi) => {
    try {
      const res = await getBannerService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCountry = createAsyncThunk(
  'system/get-countries',
  async ({ onCountrySuccess }, thunkApi) => {
    try {
      const res = await getCountryService()
      onCountrySuccess(res)
      OnCountryError()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getState = createAsyncThunk(
  'system/get-states',
  async ({ countryId, onStateSuccess }, thunkApi) => {
    try {
      const res = await getStateService(countryId)
      onStateSuccess(res)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCity = createAsyncThunk(
  'system/get-cities',
  async ({ stateId, onCitySuccess }, thunkApi) => {
    try {
      const res = await getCityService(stateId)
      onCitySuccess(res)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getAllCms = createAsyncThunk(
  'system/get-all-cms',
  async (thunkApi) => {
    try {
      const res = await getAllCmsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getAllSocialLinks = createAsyncThunk(
  'system/get-all-sociallinks',
  async (thunkApi) => {
    try {
      const res = await getAllSocialLinksService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const setContactUs = createAsyncThunk(
  'system/contactus',
  async ({ data, addToast, reset }, thunkApi) => {
    try {
      const res = await setContactUsService(data)
      thunkApi.dispatch(setGameSettingLoader(false))
      addToast('Details submitted!', { appearance: 'success' })
      reset()
      // setShowPopup(true)
      return res
    } catch (error) {
      thunkApi.dispatch(setLoader(false))
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getLobbyContent = createAsyncThunk(
  'system/get-lobby-content',
  async (thunkApi) => {
    try {
      const res = await getLobbyContentService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getLotteryInfo = createAsyncThunk(
  'system/get-lottery-info',
  async (onSuccess, thunkApi) => {
    try {
      const res = await getLotteryInfoService()
      if(onSuccess) {
        onSuccess(res)
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getTimeSetting = createAsyncThunk(
  'system/get-time-setting',
  async (thunkApi) => {
    try {
      const res = await getTimeSettingService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const withdrawRequest = createAsyncThunk(
  'payment/withdrawal-paypal',
  async ({ data, addToast, onSuccess, onError }, thunkApi) => {
    try {
      const res = await getWithdrawRequestService(data)
      addToast(res?.message, { appearance: 'success' })
      thunkApi.dispatch(fetchUserInformation())
      onSuccess()
      return res
    } catch (error) {
      onError()
      addToast(error?.[0]?.description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getWithdrawalList = createAsyncThunk(
  'withdraw-request/all-withdraw-requests',
  async ({params, onWithdrawListSuccess, onWithdrawListError, addToast}, thunkApi) => {
    try {
      const res = await getWithdrawalListService(params)
      onWithdrawListSuccess()
      return res
    } catch (error) {
      onWithdrawListError()
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const withdrawRequestCancel = createAsyncThunk(
  'withdraw-request/cancel',
  async ({params, addToast, onSuccess}, thunkApi) => {
    try {
      const res = await withdrawRequestCancelService(params)
      onSuccess()
      addToast(res?.message, { appearance: 'success' })
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getDnpPageData = createAsyncThunk(
  'system/get-dnp-data',
  async ({onSuccess},thunkApi) => {
    try {
      const res = await getDnpPageDataService()
      onSuccess()
      return res
    } catch (error) {
      onSuccess()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getWebSettings = createAsyncThunk(
  'system/get-web-settings',
  async ({onSuccess},thunkApi) => {
    try {
      const res = await getWebSettingsService()
      onSuccess()
      return res
    } catch (error) {
      onSuccess()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const getBonusDetails = createAsyncThunk(
  'bonus/get-bonus-settings',
  async ({onSuccess},thunkApi) => {
    try {
      const res = await getBonusDetailsService()
      onSuccess()
      return res
    } catch (error) {
      onSuccess()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

