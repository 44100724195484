import { createSlice } from '@reduxjs/toolkit'
import { fetchBucketInformation, getBucketList } from '../../redux/thunk/Lobby/Lobby';
import { userLogout } from '../../redux/thunk/Auth/Auth';

const initialState = {
    bucketList: [],
    bucketDetails: null
}

const lobbySlice = createSlice({
  name: 'lobbySlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

      .addCase(getBucketList.fulfilled, (state, action) => {
        return {
          ...state,
          bucketList: action.payload
        }
      })
      .addCase(fetchBucketInformation.fulfilled, (state, action) => {
        return {
          ...state,
          bucketDetails: action.payload
        }
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...initialState
        }
      })
    }
})

export default lobbySlice.reducer
