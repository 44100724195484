import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ComingSoonImg, LogoSvg } from '../../assets/image'
import borderLine1 from '../../assets/image/borderline1.svg'
import borderLine2 from '../../assets/image/borderline2.svg'
import afterImg from '../../assets/image/comingsoonafter.png'
import beforeImg from '../../assets/image/comingsoonbefore.png'
import LogoIcon from '../../assets/image/logo.png'
import PlayerIcon from '../../assets/image/playericon.png'
import { getBonusDetails } from '../../redux-store/redux/thunk/gameSetting'
import Register from '../Register'
import SuccessPopup from './components/SuccessPopup'

const ComingSoon = () => {
  const [showRegisterPopup, setShowRegisterPopup] = useState(false)
  const dispatch = useDispatch()
  const {bonus} = useSelector(state=>state.gameSetting)
  const bonusAmount = bonus?.joiningBonus?.bonusAmount ?? ""
  const handleShowRegister = (value) => {
    setShowRegisterPopup(value)
  }
  useEffect(()=>{
    dispatch(getBonusDetails({
      onSuccess:()=>{}
    }))
  },[])
  const [successPopup,setSuccessPopup] = useState(false)
  return (
    <main className='h-[100dvh] bg-comingSoonBg bg-no-repeat bg-top bg-cover relative overflow-hidden'>
      <div className='absolute bottom-0 z-20'>
        <img src={afterImg} alt="" />
      </div>

      <div className='absolute top-0 right-0'>
        <img src={beforeImg} alt="" />
      </div>

      {/* ---- Header Section ---- */}
      {/* <div className='p-2.5 pb-0 max-lg:flex max-lg:justify-center max-lg:items-center !hidden'>
        <LogoSvg className='max-w-[342px] p-2.5 max-containerWidth:p-0 max-lg:max-w-[250px] max-lg:mb-4' />
      </div> */}

      {/* ---- Main content ---- */}
      <div className='max-w-[1320px] mx-auto max-containerWidth:px-5'>
        <div className='flex items-center'>

          {/* ---- Col-1 Section ---- */}
          <div className='basis-2/4 max-tabletxl:hidden'>
            <img className='h-[calc(100dvh_-_110px)] left-[21%] absolute bottom-0 z-10 ' src={PlayerIcon} alt="" />
          </div>

          {/* ---- Col-2 Section ---- */}
          <div className='basis-2/4 flex justify-center flex-col h-[100dvh] items-center max-tabletxl:basis-full'>

            {/* ---- Header Section ---- */}
            <div className='p-2.5 header-logo'>
              <img src={LogoIcon} alt='' className='max-w-[450px] p-2.5 max-containerWidth:p-0 max-lg:max-w-[250px] max-smallscreen:max-w-[175px] max-lg:mb-4' />
            </div>

            <div className='comingsoon-img max-w-[300px] max-md:max-w-[250px] max-sm:max-w-[200px] w-auto h-auto'>
              <ComingSoonImg className='w-full h-auto' />
            </div>
            {/* <img className='max-w-[550px] max-md:max-w-[300px] max-sm:max-w-[250px] w-auto h-auto' src={ComingSoonIcon} alt="" /> */}

            {/* ---- Timer Section ---- */}
            <div className='flex gap-4 max-md:gap-3 max-sm:gap-2 mt-6 mb-8'>

              <div className='flex items-center justify-center'>
                <img className='mb-7 max-lg:w-2/4' src={borderLine1} alt="" />
              </div>

              <CountdownTimer  unit="Days" />
              <CountdownTimer unit="Hours" />
              <CountdownTimer unit="Minutes" />
              <CountdownTimer  unit="Seconds" />


              <div className='flex items-center justify-center'>
                <img className='mb-7 max-lg:w-2/4' src={borderLine2} alt="" />
              </div>
            </div>

            <div className='flex gap-2 max-smallscreen:gap-1 items-center pb-8 z-30'>
              <span className='max-w-[369px] max-2xl:max-w-[300px] max-sm:max-w-[200px] w-full max-lg:w-auto text-[34px] max-2xl:text-3xl max-md:text-xl text-right leading-none uppercase text-white font-semibold border-r-2 border-solid border-vividcyan-100 pr-2.5 max-smallscreen:pr-1'>
                SIGNUP & GET Early bird bonus
              </span>
              <span className='text-[80px] max-2xl:text-[75px] max-md:text-[50px] max-sm:text-4xl max-smallscreen:text-3xl leading-none uppercase text-primaryColor-250 font-semibold'>
                ${bonusAmount}
              </span>
            </div>
            <button onClick={()=>handleShowRegister(true)} className="text-2xl z-30 max-2xl:text-xl max-w-[298px] w-full max-md:w-auto h-[65px] max-2xl:h-12 flex justify-center items-center leading-none font-semibold uppercase border-primaryColor-500 border-solid border px-6 py-4 relative text-white transition-all duration-500 ease-in-out bg-btnGrdient  overflow-hidden group cursor-pointer text-center rounded-full">
              Sign Up Now
            </button>

          </div>
        </div>
      </div>
      <Register showRegisterPopup={showRegisterPopup} handleShowRegister={handleShowRegister} fromComingSoon={true} openSuccessPopup={()=>setSuccessPopup(true)}/>
      <SuccessPopup isOpen={successPopup} onClose={()=>setSuccessPopup(false)}/>
    </main>
  )
}

export default ComingSoon
function CountdownTimer({ value, unit }) {
  const [timeValue, setTimeValue] = useState(0);
  const { webSettings } = useSelector((state) => state.gameSetting)
  const launchDate = webSettings?.find(item=>item.key === "launchDate")?.value ?? false
  const localLaunchDate = moment(launchDate).toDate();
  useEffect(() => {
    const interval = setInterval(() => {
      
      const currentTime = new Date();
      const remainingTime = localLaunchDate - currentTime;

      let timeValue;
      switch (unit) {
        case 'Days':
          timeValue = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
          break;
        case 'Hours':
          timeValue = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
          break;
        case 'Minutes':
          timeValue = Math.floor((remainingTime / (1000 * 60)) % 60);
          break;
        case 'Seconds':
          timeValue = Math.floor((remainingTime / 1000) % 60);
          break;
        default:
          timeValue = 0;
      }

      setTimeValue(timeValue);
    }, 1000);

    return () => clearInterval(interval);
  }, [unit]);

  return (
    <div className='gap-[13px] max-md:gap-2 flex flex-col items-center justify-center'>
      <div className='bg-timerBg border border-solid border-vividcyan-100 flex items-center justify-center rounded-lg'>
        <div className='text-white font-semibold text-[40px] max-md:text-xl w-[72px] max-md:w-[52px] h-[65px] max-md:h-[52px] px-2.5 py-4 flex items-center justify-center'>
          {timeValue > 0 ? timeValue : '0'}
        </div>
      </div>
      <div className='text-vividcyan-100 font-medium text-base max-md:text-xs uppercase'>{unit}</div>
    </div>
  );
}
