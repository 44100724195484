import PaypalPayment from '../PaypalPayment'

const Payment = () => {
  return (
      <div className='flex gap-4 items-start flex-wrap justify-center m-8'>
        <div className=' bg-primaryColor-200 p-6 rounded-3xl max-w-[516px] w-full'>
          <div className='max-sm:p-0'>
            <div>
              <PaypalPayment />
            </div>
          </div>
        </div>
      </div>
  )
}

export default Payment
