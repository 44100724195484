/**
 * Generate a random card number between [1,52]
 * @returns {number}
 */
export const randomCardGenerate = () => {
  return Math.floor(Math.random() * 52) + 1
}

export const generateClientSeed = () => {
  const availableChars = '0123456789abcdef'
  let seed = ''
  for (let i = 0; i < 32; i++) {
    seed +=
          availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return seed
}

export const cartesianProductOfArrays = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))

export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}
export function getNameFromUser(person) {
  const { firstName, lastName } = person;
  
  // Capitalize the first letter of firstName
  const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  
  // Capitalize the first letter of lastName if it exists, otherwise an empty string
  const capitalizedLastName = lastName ? ` ${lastName.charAt(0).toUpperCase() + lastName.slice(1)}` : '';

  // Concatenate with a space and trim
  const fullName = `${capitalizedFirstName}${capitalizedLastName}`.trim();
  
  return fullName;
}
export const getOrdinal = (number) => {
  const suffixes = ['st', 'nd', 'rd'];
  const lastDigit = number % 10;
  const suffix = suffixes[(number % 100 > 10 && number % 100 < 14) || lastDigit > 3 ? 0 : lastDigit - 1] || 'th';
  return `${number}${suffix}`;
};

export const dateTimeFormat = (dateTime, separator = '/') => {
  return moment(dateTime)
  .local()
  .format(`MM/DD/YYYY ${separator} HH:mm`)
}
export function convertToEmbedUrl(shareUrl) {
  // Check if the URL is already an embed URL
  if (shareUrl.includes('youtube.com/embed/')) {
      return shareUrl; // Return the URL as is
  }

  // Extract video ID from share URL
  const videoIdMatch = shareUrl.match(/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
  
  if (videoIdMatch && videoIdMatch[1]) {
      const videoId = videoIdMatch[1];
      // Construct the embed URL
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      return embedUrl;
  } else {
      // Invalid or unsupported URL format
      return null;
  }
}

export const toFixed = (amount, point = 2) => {
  return Number(amount) % 1 === 0 ? amount : parseFloat(amount).toFixed(point)
}