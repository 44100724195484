import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  bucketPurchaseService,
  getBucketInfoService,
  getBucketListService,
  getBucketPlayerListService
} from '../../../../network/services/lobby.service'
import { ROUTE_PATHS } from '../../../../utils/constants'

export const getBucketList = createAsyncThunk(
  'bucket/list',
  async ({params, onBucketListSuccess, onBucketListError, addToast}, thunkApi) => {
    try {
      const res = await getBucketListService(params)
      onBucketListSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      onBucketListError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchBucketInformation = createAsyncThunk(
  'buckets/details',
  async (bucketUid, thunkApi) => {
    try {
      const res = await getBucketInfoService(bucketUid)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const bucketPurchase = createAsyncThunk(
  'buckets/purchase',
  async ({ newData, history, addToast, onSuccess }, thunkApi) => {
    try {
      const res = await bucketPurchaseService(newData)
      addToast(res?.message, { appearance: 'success' })
      onSuccess()
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getBucketPlayerList = createAsyncThunk(
  'bucketPlayer/list',
  async ({ params, onSuccess, onError, addToast }, thunkApi) => {
    try {
      const res = await getBucketPlayerListService(params)
      onSuccess(res)
      return res
    } catch (error) {
      addToast(error[0].description, { appearance: 'error' })
      onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
