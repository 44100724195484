import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// Import Swiper styles
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper.min.css";


import SwiperCore, {
  EffectCoverflow,
  Navigation,
  Pagination
} from 'swiper/core';
import slideImag from '../../assets/image/defaultPlayer.png';
import { ArrowLeftSliderIcon, ArrowRightSliderIcon } from '../../icons';
import './index.scss';

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const CustomSlider3d = ({ data, ...props }) => {
  return (
    <>
      {
        data?.length ?
          <div className="carousel-container">
            <Swiper
              slideToClickedSlide={true}
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 0,
                stretch: 16,
                depth: 50,
                modifier: 6,
                slideShadows: false,
              }}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
              }}
              breakpoints={{
                "576": {
                  "slidesPerView": 2,
                  "coverflowEffect": {
                    rotate: 0,
                    stretch: 22,
                    depth: 52,
                    modifier: 6,
                    slideShadows: false,
                  }
                },
                "768": {
                  "slidesPerView": 2,
                  "coverflowEffect": {
                    rotate: 0,
                    stretch: 22,
                    depth: 36,
                    modifier: 8,
                    slideShadows: false,
                  }
                },
                "992": {
                  "slidesPerView": 2,
                  "coverflowEffect": {
                    rotate: 0,
                    stretch: 22,
                    depth: 25,
                    modifier: 10,
                    slideShadows: false,
                  }
                },
                "1727": {
                  "slidesPerView": 2,
                  "coverflowEffect": {
                    rotate: 0,
                    stretch: 22,
                    depth: 16,
                    modifier: 12,
                    slideShadows: false,
                  }
                },
              }}
              className="swiper_container"
            >
              {
                data?.map(player => {
                  return (
                    <SwiperSlide >
                      <div className='bg-primaryColor-750 text-center rounded-[40px] overflow-hidden pointer-events-auto'>
                        <img src={player?.["Player.image"] ?? slideImag} alt="slide_image" />
                        <div className='bg-primaryColor-400 px-8 py-4'>
                          <p className='text-2xl max-lg:text-lg max-md:text-base flex justify-center items-center text-white'>{player?.["Player.name"]}<span className='text-[12px] ml-2 py-1 px-2 text-primaryColor-950 bg-dustyGray-400 rounded-lg border-solid border border-primaryColor-950'>{player?.["Player.position"]}</span></p>
                          <p className='text-2xl max-lg:text-lg max-md:text-base mt-3 text-white bg-primaryColor-800 px-8 inline-block rounded-lg'>$ <span>{player?.["Player.value"]}</span></p>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
              <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ArrowLeftSliderIcon />
                </div>
                <div className="swiper-button-next slider-arrow">
                  <ArrowRightSliderIcon />
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
          </div>
          : <></>
      }
    </>
  )
}

export default CustomSlider3d;