import React, { memo, useEffect, useState, useCallback } from 'react'
import Slider from 'react-slick'
import { useDispatch, useSelector } from 'react-redux'
import { getBanner } from '../../../../redux-store/redux/thunk/gameSetting'
import LazyLoadImage from '../../../../components/ImageLazyLoad'
import './lobbyBanner.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const homeSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  prevArrow: null,
  nextArrow: null
}

const LobbyBannerSection = () => {
  const dispatch = useDispatch()
  const [banners, setBanners] = useState([])
  const { homeBanner } = useSelector((state) => state.gameSetting)

  const fetchBanners = useCallback(async () => {
    try {
      if (!homeBanner?.banners?.length) await dispatch(getBanner())
    } catch (error) {
      console.error('Error fetching banners:', error)
    }
  }, [dispatch, homeBanner?.banners])

  useEffect(() => {
    if (homeBanner?.banners) {
      const enabledBanner = homeBanner?.banners?.filter(
        (banner) => banner.enabled
      )
      setBanners(enabledBanner)
    }
  }, [homeBanner?.banners])

  useEffect(() => {
    fetchBanners()
  }, [])

  return (
    <section className='lobby-banner-section !mb-5 !px-0'>
      <div className='w-full'>
        <Slider {...homeSlider}>
          {!!banners?.length ? (
            banners.map((banner) => (
              <a
                href={banner?.redirectUrl}
                target='_blank'
                className='img-box rounded-2xl overflow-hidden'
                key={banner.id}
              >
                <LazyLoadImage
                  src={
                    banner?.imageUrl ||
                    '/assets/images/homePage/home-banner.png'
                  }
                  placeholderImg='/assets/images/homePage/home-banner.png'
                  className='img-fluid desktop-banner  max-md:!h-[192px] max-sm:!h-[150px] object-cover'
                  alt='Img'
                />
                <LazyLoadImage
                  src={
                    banner?.imageUrl ||
                    '/assets/images/homePage/home-banner.png'
                  }
                  className='img-fluid mobile-banner max-md:!h-[192px] max-sm:!h-[150px] object-cover'
                  alt='Img'
                />
              </a>
            ))
          ) : (
            <a href='#' className='img-box rounded-2xl overflow-hidden'>
              <img
                src='/assets/images/homePage/home-banner.png'
                className='img-fluid desktop-banner  max-md:!h-[192px] max-sm:!h-[150px] object-cover'
                alt='Img'
              />
              <img
                src='/assets/images/homePage/home-banner.png'
                className='img-fluid mobile-banner max-md:!h-[192px] max-sm:!h-[150px] object-cover'
                alt='Img'
              />
            </a>
          )}
        </Slider>
      </div>
    </section>
  )
}

export default memo(LobbyBannerSection)
