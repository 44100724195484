import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis/index'

/**
 * Service to get user info
 */
export const getUserInfoService = () => {
  return axiosInstance(METHOD_TYPES.get, 'user/user-detail', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const updateUserInfoService = (data) => {
  return axiosInstance(METHOD_TYPES.put, 'user/update-profile', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const updateProfilePhotoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/upload-profile-photo', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: {
    'Content-Type': 'multipart/form-data'
    }
  })
}
