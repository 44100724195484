import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const BucketAction = ({
  timeSettings,
  item,
  userDetails,
  handleBucketEnter,
  isContestBegins,
  setIsContestBegins,
  isBucketDisable,
  setIsBucketDisable,
}) => {
  const { t } = useTranslation('lobby')
  const [currentTime, setCurrentTime] = useState(moment().format('HH:mm:ssZ'))

  const isAfter = (startTime, endTime) => {
    return moment(moment(startTime, 'HH:mm:ssZ')
      .local().format('HH:mm:ssZ'), 'HH:mm:ssZ')
      .isAfter(moment(moment(endTime, 'HH:mm:ssZ').local().format('HH:mm:ssZ'), 'HH:mm:ssZ'))
  }

  const isBefore = (startTime, endTime) => {
    return moment(moment(startTime, 'HH:mm:ssZ')
      .local().format('HH:mm:ssZ'), 'HH:mm:ssZ')
      .isBefore(moment(moment(endTime, 'HH:mm:ssZ').local().format('HH:mm:ssZ'), 'HH:mm:ssZ'))
  }

  const isBetween = (curTime, startTime, endTime) => {
    return moment(curTime, 'HH:mm:ssZ').isBetween(
      moment(moment(startTime, 'HH:mm:ssZ').local().format('HH:mm:ssZ'), 'HH:mm:ssZ'),
      moment(moment(endTime, 'HH:mm:ssZ').local().format('HH:mm:ssZ'), 'HH:mm:ssZ'),
      null,
      '[]'
    )
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format('HH:mm:ssZ'))
      setIsBucketDisable(false)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    checkBucket()
  }, [currentTime])

  const checkBucket = () => {
      isAfter(timeSettings?.startTime, timeSettings?.endTime)
        ? isAfter(currentTime, timeSettings?.startTime)
          ? setIsContestBegins(true)
          : isBefore(currentTime, timeSettings?.endTime)
          ? setIsContestBegins(true)
          : setIsContestBegins(false)
        : isBetween(currentTime, timeSettings?.startTime, timeSettings?.endTime)
        ? setIsContestBegins(true)
        : setIsContestBegins(false)
  }

  return (
    <>
      {isBucketDisable ? 
      <></> 
      : isContestBegins ? (
        !item?.bucketUsers
          .map((item) => item.userId)
          .includes(userDetails?.id) ? (
          <button
            onClick={() => handleBucketEnter(item)}
            className='text-base leading-none font-medium capitalize border-primaryColor-500 border-solid border px-6 py-2.5 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'
          >
            <span class='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
            {t('enter')}
          </button>
        ) : (
          <span className='inline-block rounded border-primaryColor-500 border border-solid px-6 py-2.5 text-base capitalize font-medium text-primaryColor-500 leading-none'>
            {t('joined')}
          </span>
        )
      ) : (
        <span className='inline-block rounded border-primaryColor-500 border border-solid px-6 py-2.5 text-base capitalize font-medium text-primaryColor-500 leading-none'>
          {moment
            .utc(
              moment
                .duration(
                  moment(timeSettings?.startTime, 'HH:mm:ssZ')
                    .local()
                    .diff(moment(currentTime, 'HH:mm:ssZ'))
                )
                .asMilliseconds()
            )
            .format('HH:mm:ss')}
        </span>
      )}
    </>
  )
}

export default BucketAction
