import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { getPlayerList } from '../../../redux-store/redux/thunk/Player/Player'
import {
  bucketPurchase,
  fetchBucketInformation
} from '../../../redux-store/redux/thunk/Lobby/Lobby'
import { useParams, useHistory } from 'react-router-dom'
import {
  setDnpInsuranceAmount,
  setFullRosterDnp,
  setRosterPlayerList,
  setRemainingSalaryCap
} from '../../../redux-store/redux-slices/Roster/rosterSlice'
import { useTranslation } from 'react-i18next'

export const usePlayerSelectionController = () => {
  const { t } = useTranslation('playerSelection')
  const [activeTab, setActiveTab] = useState('all')
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentOffset, setCurrentOffset] = useState(0)
  const [showPagination, setShowPagination] = useState(false)
  const [showNextBtn, setShowNextBtn] = useState(true)
  const { playerList } = useSelector((state) => state.playerSlice)
  const { addToast } = useToasts()
  const { bucketUid } = useParams()
  const { bucketDetails } = useSelector((state) => state.lobbySlice)
  const [playerDetailSection, setPlayerDetailSection] = useState(null)
  const { userDetails } = useSelector((state) => state.userSlice)
  const [paymentType, setPaymentType] = useState('Paypal')
  const [showRosterDetailsModal, setShowRosterDetailsModal] = useState(false)
  const {
    dnpInsuranceAmount,
    rosterPlayerList,
    fullRosterDnp,
    remainingSalaryCap
  } = useSelector((state) => state.rosterSlice)
  const [listOrder, setListOrder] = useState('asc')
  const [loader, setLoader] = useState(false)

  const handleShowRosterDetails = (value) => {
    setShowRosterDetailsModal(value)
  }

  const onPlayerListSuccess = (res) => {
    res?.rows.length > 0 && setPlayerDetailSection(res?.rows[0])
    setLoader(false)
  }

  const onPlayerListError = () => {
    setLoader(false)
  }

  useEffect(async () => {
    dispatch(fetchBucketInformation(bucketUid))
  }, [])

  // useEffect(async () => {
  //   if (bucketDetails) {
  //     setLoader(true)
  //     const params = {
  //       limit: 8,
  //       offset: currentOffset,
  //       orderBy: listOrder
  //     }
  //     dispatch(
  //       getPlayerList({
  //         params,
  //         addToast,
  //         onPlayerListSuccess,
  //         onPlayerListError
  //       })
  //     )
  //   }
  // }, [bucketDetails])

  useEffect(() => {
    if (playerList?.rows?.length || currentOffset > 0) {
      setShowPagination(true)
      if (playerList?.rows?.length < 8) {
        setShowNextBtn(true)
      } else {
        setShowNextBtn(false)
      }
    } else {
      setShowPagination(false)
    }
  }, [playerList])
  useEffect(() => {
    setLoader(true)
    const params =
      activeTab === 'all'
        ? {
            limit: 8,
            offset: 0,
            orderBy: listOrder
          }
        : {
            limit: 8,
            offset: 0,
            position: activeTab,
            orderBy: listOrder
          }
    dispatch(
      getPlayerList({
        params,
        addToast,
        onPlayerListSuccess,
        onPlayerListError
      })
    )
  }, [listOrder])
  const onTabChange = async (tab) => {
    setLoader(true)
    setActiveTab(tab)
    setCurrentOffset(0)
    if (bucketDetails) {
      const params =
        tab === 'all'
          ? {
              limit: 8,
              offset: 0,
              orderBy: listOrder
            }
          : {
              limit: 8,
              offset: 0,
              position: tab,
              orderBy: listOrder
            }
      dispatch(
        getPlayerList({
          params,
          addToast,
          onPlayerListSuccess,
          onPlayerListError
        })
      )
    }
  }

  const onPageChange = async (offsetData) => {
    setLoader(true)
    if (bucketDetails) {
      const params =
        activeTab === 'all'
          ? {
              limit: 8,
              offset: offsetData,
              orderBy: listOrder
            }
          : {
              limit: 8,
              offset: offsetData,
              position: activeTab,
              orderBy: listOrder
            }
      dispatch(
        getPlayerList({
          params,
          addToast,
          onPlayerListSuccess,
          onPlayerListError
        })
      )
      setCurrentOffset(offsetData)
    }
  }

  const handleRosterPlayerList = (data) => {
    if (!rosterPlayerList.map((item) => item.id).includes(data.id)) {
      const bucketPositionCount = JSON.parse(
        bucketDetails?.positionWisePlayer
      )
      if (
        rosterPlayerList?.filter((item) => item?.position == data?.position)
          ?.length +
          1 >
        bucketPositionCount[data?.position]
      ) {
        addToast(
          `${bucketPositionCount[data?.position]} ${
            data?.position
          } ${t('positionPlayerAllowed')}`,
          { appearance: 'error' }
        )
        return
      }
      if (
        Number(bucketDetails?.budget) -
          (remainingSalaryCap + Number(data.value)) <
        0
      ) {
        addToast(t('notEnoughSalary'), { appearance: 'error' })
        return
      }
      dispatch(
        setRosterPlayerList([
          ...rosterPlayerList,
          { ...data, dnpAmount: 0, isPlayerDnp: false }
        ])
      )
      const total = remainingSalaryCap + Number(data.value)
      dispatch(setRemainingSalaryCap(total))
    } else {
      addToast(t('playerAlreadyAdded'), { appearance: 'error' })
    }
  }

  const handleRosterPlayerDelete = (data) => {
    const updatedItems = rosterPlayerList.filter((item) => item.id !== data.id)
    const dataItem = rosterPlayerList.filter((item) => item.id === data.id)
    dispatch(setRosterPlayerList(updatedItems))
    const total = remainingSalaryCap - Number(dataItem?.[0]?.value)
    dispatch(setRemainingSalaryCap(total))
    dispatch(
      setDnpInsuranceAmount(dnpInsuranceAmount - dataItem?.[0]?.dnpAmount)
    )
  }

  const handleViewRoster = () => {
    if (bucketDetails?.budget - remainingSalaryCap < 0) {
      addToast(t('notEnoughSalary'), { appearance: 'error' })
      return
    }
    if (
      rosterPlayerList.length == 0 ||
      rosterPlayerList.length >
        bucketDetails?.maximumPlayers
    ) {
      rosterPlayerList.length == 0
        ? addToast(t('selectAtleastOnePlayer'), { appearance: 'error' })
        : addToast(
            `${t('maximum')} ${bucketDetails?.maximumPlayers} ${t('playerAllowed')}`,
            { appearance: 'error' }
          )
      return
    }
    let qbCount = 0,
      rbCount = 0,
      wrCount = 0,
      teCount = 0,
      kCount = 0,
      defCount = 0
    rosterPlayerList.map((item, index) => {
      if (item?.position == 'QB') {
        qbCount++
      } else if (item?.position == 'RB') {
        rbCount++
      } else if (item?.position == 'WR') {
        wrCount++
      } else if (item?.position == 'TE') {
        teCount++
      } else if (item?.position == 'K') {
        kCount++
      } else if (item?.position == 'DEF') {
        defCount++
      }
    })
    const bucketPositionCount = JSON.parse(
      bucketDetails?.positionWisePlayer
    )
    if (
      qbCount < Number(bucketPositionCount?.QB) ||
      rbCount < Number(bucketPositionCount?.RB) ||
      wrCount < Number(bucketPositionCount?.WR) ||
      teCount < Number(bucketPositionCount?.TE) ||
      kCount < Number(bucketPositionCount?.K) ||
      defCount < Number(bucketPositionCount?.DEF)
    ) {
      if (qbCount < Number(bucketPositionCount?.QB)) {
        addToast(
          `${t('pleaseSelect')} ${bucketPositionCount?.QB} QB ${t('positionPlayers')}`,
          { appearance: 'error' }
        )
      } else if (rbCount < Number(bucketPositionCount?.RB)) {
        addToast(
          `${t('pleaseSelect')} ${bucketPositionCount?.RB} RB ${t('positionPlayers')}`,
          { appearance: 'error' }
        )
      } else if (wrCount < Number(bucketPositionCount?.WR)) {
        addToast(
          `${t('pleaseSelect')} ${bucketPositionCount?.WR} WR ${t('positionPlayers')}`,
          { appearance: 'error' }
        )
      } else if (teCount < Number(bucketPositionCount?.TE)) {
        addToast(
          `${t('pleaseSelect')} ${bucketPositionCount?.TE} TE ${t('positionPlayers')}`,
          { appearance: 'error' }
        )
      } else if (kCount < Number(bucketPositionCount?.K)) {
        addToast(`${t('pleaseSelect')} ${bucketPositionCount?.K} K ${t('positionPlayers')}`, {
          appearance: 'error'
        })
      } else if (defCount < Number(bucketPositionCount?.DEF)) {
        addToast(
          `${t('pleaseSelect')} ${bucketPositionCount?.DEF} DEF ${t('positionPlayers')}`,
          { appearance: 'error' }
        )
      }
      return
    }
    const userRosterArray = rosterPlayerList.map(
      ({ id, name, position, value, dnpAmount, isPlayerDnp }, index) => {
        return {
          playerId: id,
          playerName: name,
          userId: userDetails?.id,
          bucketId: bucketDetails?.id,
          playerPosition: position,
          isPlayerDnp: isPlayerDnp,
          salary: value,
          dnpAmount
        }
      }
    )
    const rosterData = {
      bucketId: bucketDetails?.id,
      bucketConfigId: bucketDetails?.bucketConfigurationsId,
      userId: userDetails?.id,
      amount: bucketDetails?.price,
      dnpAmount:
        fullRosterDnp == true
          ? bucketDetails?.dnpInsurancePrice
          : dnpInsuranceAmount,
      dnpTaken: fullRosterDnp,
      userRoster: userRosterArray,
      date: bucketDetails?.date
    }
    history.push('/view-roster-details', { rosterData, bucketDetails })
  }

  const handleFullRosterDnp = () => {
    if (fullRosterDnp) {
      dispatch(setDnpInsuranceAmount(0))
    } else {
      dispatch(
        setDnpInsuranceAmount(
          bucketDetails?.dnpInsurancePrice
        )
      )
    }
    const updatedRosterPlayerList = rosterPlayerList.map((item, index) => {
      return {
        ...item,
        dnpAmount: 0,
        isPlayerDnp: false
      }
    })
    dispatch(setRosterPlayerList(updatedRosterPlayerList))
    dispatch(setFullRosterDnp(!fullRosterDnp))
  }

  const handlePerPlayerDnp = (playerId) => {
    const updatedRosterPlayerList = rosterPlayerList.map((item, index) =>
      item?.id == playerId
        ? {
            ...item,
            dnpAmount: !item.isPlayerDnp
              ? bucketDetails?.dnpInsurancePerPlayer
              : 0,
            isPlayerDnp: !item.isPlayerDnp
          }
        : item
    )
    dispatch(setRosterPlayerList(updatedRosterPlayerList))
    const perPlayerDnpSum = updatedRosterPlayerList
      .filter(({ isPlayerDnp }) => isPlayerDnp == true)
      .reduce(
        (accumulator, rosterPlayer) => accumulator + rosterPlayer.dnpAmount,
        0
      )
    dispatch(setDnpInsuranceAmount(perPlayerDnpSum))
  }

  const handleSearch = (e) => {
    setLoader(true)
    if (bucketDetails) {
      const params = {
        limit: 8,
        offset: 0,
        searchKey: e?.target?.value,
        orderBy: listOrder
      }
      dispatch(
        getPlayerList({
          params,
          addToast,
          onPlayerListSuccess,
          onPlayerListError
        })
      )
    }
  }

  const rosterInfoArray = [
    {
      name: 'QB',
      playerLimit:
        bucketDetails?.positionWisePlayer &&
        JSON.parse(bucketDetails?.positionWisePlayer)?.QB
    },
    {
      name: 'RB',
      playerLimit:
        bucketDetails?.positionWisePlayer &&
        JSON.parse(bucketDetails?.positionWisePlayer)?.RB
    },
    {
      name: 'WR',
      playerLimit:
        bucketDetails?.positionWisePlayer &&
        JSON.parse(bucketDetails?.positionWisePlayer)?.WR
    },
    {
      name: 'TE',
      playerLimit:
        bucketDetails?.positionWisePlayer &&
        JSON.parse(bucketDetails?.positionWisePlayer)?.TE
    },
    {
      name: 'K',
      playerLimit:
        bucketDetails?.positionWisePlayer &&
        JSON.parse(bucketDetails?.positionWisePlayer)?.K
    },
    {
      name: 'DEF',
      playerLimit:
        bucketDetails?.positionWisePlayer &&
        JSON.parse(bucketDetails?.positionWisePlayer)?.DEF
    }
  ]

  const positionTabArray = [
    {
      name: 'all'
    },
    {
      name: 'QB'
    },
    {
      name: 'RB'
    },
    {
      name: 'WR'
    },
    {
      name: 'TE'
    },
    {
      name: 'K'
    },
    {
      name: 'DEF'
    }
  ]

  const rosterPositionList = [
    {
      position: 'QB',
      length:
        bucketDetails?.positionWisePlayer &&
        Number(
          JSON.parse(bucketDetails?.positionWisePlayer)?.QB
        )
    },
    {
      position: 'RB',
      length:
        bucketDetails?.positionWisePlayer &&
        Number(
          JSON.parse(bucketDetails?.positionWisePlayer)?.RB
        )
    },
    {
      position: 'WR',
      length:
        bucketDetails?.positionWisePlayer &&
        Number(
          JSON.parse(bucketDetails?.positionWisePlayer)?.WR
        )
    },
    {
      position: 'TE',
      length:
        bucketDetails?.positionWisePlayer &&
        Number(
          JSON.parse(bucketDetails?.positionWisePlayer)?.TE
        )
    },
    {
      position: 'K',
      length:
        bucketDetails?.positionWisePlayer &&
        Number(
          JSON.parse(bucketDetails?.positionWisePlayer)?.K
        )
    },
    {
      position: 'DEF',
      length:
        bucketDetails?.positionWisePlayer &&
        Number(
          JSON.parse(bucketDetails?.positionWisePlayer)
            ?.DEF
        )
    }
  ]

  return {
    t,
    activeTab,
    currentOffset,
    showPagination,
    showNextBtn,
    playerList,
    rosterPlayerList,
    bucketDetails,
    remainingSalaryCap,
    fullRosterDnp,
    dnpInsuranceAmount,
    playerDetailSection,
    userDetails,
    showRosterDetailsModal,
    paymentType,
    rosterInfoArray,
    positionTabArray,
    rosterPositionList,
    loader,
    setPaymentType,
    setShowRosterDetailsModal,
    setPlayerDetailSection,
    onTabChange,
    onPageChange,
    handleRosterPlayerList,
    handleRosterPlayerDelete,
    handleFullRosterDnp,
    handlePerPlayerDnp,
    handleShowRosterDetails,
    handleViewRoster,
    handleSearch,
    listOrder,
    setListOrder,
    history
  }
}
