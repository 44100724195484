import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis/index'

export const getPlayerListService = (data) => {
    return axiosInstance(METHOD_TYPES.get, 'players/list', data, {
      server: microServices.USER,
      loader: LOADER_HANDLER_TYPES.submit,
      params: data
  
    })
  }

  export const getPlayerInfoService = (data) => {
    return axiosInstance(METHOD_TYPES.get, 'players/details', {}, {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table,
      params: data
    })
  }
export const getSpotlightDataService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'players/spot-light', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data

  })
}
export const nominateLegendService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'players/nominate-player', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const addCommentService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'players/comment', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const getPlayerBySearchService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'players/players-list', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}
export const getTopPickPlayerService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'players/top-pick-player', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}