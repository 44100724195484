import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { useState, useEffect } from 'react'
import { METHOD_TYPES } from '../../utils/constants'
import PaymentForm from './components/PaymentForm'
import Loader from '../../components/Loader'
import { useTranslation } from 'react-i18next'

const PaypalPayment = () => {
  const { t } = useTranslation('home')
  const [clientToken, setClientToken] = useState(null)

  const initialOptions = {
    'client-id':
      process.env.PAYPAL_CLIENT_ID ||
      'AX3wW1CkXZwDblenkZcYqIqd5kuMnpEm4UHu_Rs0S4xc4kjRgOco7Sr8nNW_vaSo7SLz9JKziCBGZOdD',
    'data-client-token': clientToken,
    components: 'hosted-fields,buttons',
    'data-sdk-integration-source': 'integrationbuilder_ac'
  }

  useEffect(async () => {
    const USER_URL = process.env.REACT_APP_BACKEND_URL_HTTP
    const response = await fetch(`${USER_URL}/payment/client-token`, {
      method: METHOD_TYPES.get
    })
    const data = await response.json()
    const { client_token } = data?.data
    setClientToken(client_token)
  }, [])

  return (
    <>
      {clientToken ? (
        <PayPalScriptProvider options={initialOptions}>
          <PaymentForm />
        </PayPalScriptProvider>
      ) : (
        <div className='flex flex-col justify-center items-center h-[50vh]'>
          <Loader />
          <h4 className='text-white text-xl mt-8'>{t('loading')}... {t('pleaseWait')}</h4>
        </div>
      )}
    </>
  )
}

export default PaypalPayment
