import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTransactionListService } from '../../../../network/services/transaction.service'

export const getTransactionList = createAsyncThunk(
  'transaction/list',
  async ({params, onTransactionListSuccess, onTransactionListError, addToast}, thunkApi) => {
    try {
      const res = await getTransactionListService(params)
      onTransactionListSuccess()
      return res
    } catch (error) {
      onTransactionListError()
      addToast(error[0].description, { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
