import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
const EarlyBirdAuth = () => {
    const [showModal,setShowModal] = useState(false)
    const handleModal = (value) => setShowModal(value)
    const { t } = useTranslation('home')
    useEffect(()=>{
        setShowModal(true)
        // const username = window.prompt("Enter your username:", "");
        // if (username === null) {
        //   // User clicked cancel
        //   return;
        // }
        // const password = window.prompt("Enter your password:", "");
        // if (password === null) {
        //   // User clicked cancel
        //   return;
        // }
        // // Perform sign-in with username and password
        // if(username == user && password == pass){
        //     localStorage.setItem('isVerified',true)
        //     history.replace('/')
        // }
        // else{
        //     alert("Wrong username or password")
        // }
    },[])
    
    return(
        <>
            <Popup showModal={showModal} t={t} handleModal={handleModal}/>
        </>
    )
}
export default EarlyBirdAuth
const Popup = ({showModal,handleModal,t}) => {
    const cancelButtonRef = useRef(null)
    const [username,setUsername] = useState("")
    const [password,setPassword] = useState("")
    // const user = process.env.REACT_APP_ADMIN_EARLY_BIRD_USERNAME
    const pass = process.env.REACT_APP_ADMIN_EARLY_BIRD_PASSWORD
    const history = useHistory()
    const handleSubmit = () => {
        if(password == pass){
            sessionStorage.setItem('isVerified',true)
            history.replace('/')
        }
        else{
            alert("Wrong username or password")
        }
    }
    return(<>
    <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full max-w-[472px]'>
                  <div className='p-6 bg-dialogBg'>
                    {/* <button
                      type='button'
                      onClick={() => handleModal(false)}
                      className='absolute right-6 top-5 cursor-pointer outline-none'
                    >
                      <CloseIcon />
                    </button> */}
                    <div className=''>
                      <div className='mt-3 text-center'>
                        <Dialog.Title
                          as='h3'
                          className='text-[22px] font-semibold leading-6 text-royalNavy-200'
                        >
                          {t('Beta Login')}
                        </Dialog.Title>

                        <div className='mt-6'>
                          {/* <form
                            class=''
                            onSubmit={handleSubmit(handleLoginSubmit)}
                          > */}
                            <div>
                              <div style={{textAlign:"start"}}>This is the password to access the website</div>
                              {/* <div class='relative'>
                                <input
                                  name='userNameOrEmail'
                                  maxLength={50}
                                  autoComplete='off'
                                  type='text'
                                  id='outlined_error'
                                  aria-describedby='outlined_error_help'
                                  class='block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                  placeholder=' '
                                  value={username}
                                  onChange={(e)=>setUsername(e.target.value)}
                                />
                                <label
                                  for='outlined_error'
                                  class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
                                >
                                  {t('Username')}
                                </label>
                              </div> */}
                              <div class='relative'>
                                <input
                                  type={'text'}
                                  name='password'
                                  maxLength={50}
                                  autoComplete='off'
                                  id='outlined_error'
                                  aria-describedby='outlined_error_help'
                                  class='block px-2.5 pb-2.5 pt-4 mt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                  placeholder=' '
                                  value={password}
                                  onChange={(e)=>setPassword(e.target.value)}
                                />
                                <label
                                  for='outlined_error'
                                  class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
                                >
                                  {t('signin.password')}
                                </label>
                              </div>
                              <div className='my-6'>
                                <button
                                  type='button'
                                  onClick={handleSubmit}
                                  className='w-full bg-btnGrdient inline-block p-4 text-[18px] text-white rounded-lg capitalize leading-tight'
                                > 
                                   {
                                     t('login')
                                   }
                                </button>
                              </div>

                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>)
}