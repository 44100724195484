import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import {
  getResultList,
  getRosterDetail
} from '../../../redux-store/redux/thunk/Result/Result'
import { PAGINATION_LIMIT, ROUTE_PATHS } from '../../../utils/constants'
import { setActiveTab } from '../../../redux-store/redux-slices/Result/resultSlice'
export const useResultListController = () => {
  const { t } = useTranslation('home')
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { resultList, loading, activeTab } = useSelector((state) => state.resultSlice)
  // const [activeTab, setActiveTab] = useState(1)
  const [showRosterDetailPopup, setShowRosterDetailPopup] = useState(false)
  const [rosterDetail, setRosterDetail] = useState([])
  const [rosterDetailBucket, setRosterDetailBucket] = useState([])
  const [loader, setLoader] = useState(false)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [showPagination, setShowPagination] = useState(false)
  const [showNextBtn, setShowNextBtn] = useState(true)
  const history = useHistory()
  useEffect(() => {
    dispatch(
      getResultList({
        status: activeTab,
        limit: PAGINATION_LIMIT,
        offset: currentOffset
      })
    )
  }, [])
  useEffect(() => {
    if (resultList?.rows?.length || currentOffset > 0) {
      setShowPagination(true)
      if (resultList?.rows?.length < PAGINATION_LIMIT) {
        setShowNextBtn(true)
      } else {
        setShowNextBtn(false)
      }
    } else {
      setShowPagination(false)
    }
  }, [resultList])
  const onPageChange = async (offsetData) => {
    setLoader(true)
    dispatch(
      getResultList({
        status: activeTab,
        limit: PAGINATION_LIMIT,
        offset: offsetData
      })
    )

    setCurrentOffset(offsetData)
  }
  const handleTabChange = (type) => {
    dispatch(setActiveTab(type))
    dispatch(getResultList({ limit: 10, offset: 0, status: type }))
  }
  const handleSearch = (event) => {
    if (event.target.value?.length > 2)
      dispatch(
        getResultList({
          limit: 10,
          offset: 0,
          search: event.target.value,
          status: activeTab
        })
      )
    else if(event.target.value?.length == 0){
      dispatch(
        getResultList({
          limit: 10,
          offset: 0,
          status: activeTab
        })
      )
    }
  }
  const handleResultDetails = (bucketId, bucketUid) => {
    history.push({
      pathname: ROUTE_PATHS.RESULTDETAILS,
      state: { bucketId, bucketUid }
    })
  }

  const onSuccess = (res) => {
    setLoader(false)
    setRosterDetail(res)
  }
  const onError = (res) => {
    setLoader(false)
  }

  const handleRosterDetailPopup = async (value, bucketData) => {
    setLoader(true)
    setRosterDetailBucket(bucketData)
    setShowRosterDetailPopup(value)
    const params = {
      bucketId: bucketData?.bucketId
    }
    dispatch(getRosterDetail({ params, onSuccess, onError, addToast }))
  }

  return {
    t,
    loading,
    resultList,
    activeTab,
    handleTabChange,
    handleSearch,
    handleResultDetails,
    showRosterDetailPopup,
    setShowRosterDetailPopup,
    handleRosterDetailPopup,
    rosterDetail,
    setRosterDetail,
    loader,
    rosterDetailBucket,
    setRosterDetailBucket,
    setRosterDetailBucket,
    currentOffset,
    showNextBtn,
    showPagination,
    onPageChange
  }
}
