import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const CurrentTimeDifference = ({ time }) => {
  const [currentTimeDiff, setCurrentTimeDiff] = useState('00:00:00')
  const history = useHistory()

  const getFormattedTime = () => {
    return moment
      .utc(
        moment
          .duration(
            moment(time, 'HH:mm:ssZ').local().diff(moment())
          )
          .asMilliseconds()
      )
      .format('HH:mm:ss')
  }

  useEffect(() => {
    if (time) {
      const intervalId = setInterval(() => {
        const newTime = getFormattedTime()
        setCurrentTimeDiff(newTime)
        if (newTime === '00:00:00') {
          clearInterval(intervalId)
          history.push('/lobby')
        }
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [time])

  return <>{currentTimeDiff}</>
}
export default CurrentTimeDifference
