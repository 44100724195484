import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLocalLanguage } from '../utils/common-services/localstorage.services'
import { LANGUAGES } from '../utils/constants/index'
import translationsEN from './locales/en/index'
import translationsES from './locales/es'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        ...translationsEN
      },
      es: {
        ...translationsES
      }
    },
    lng: getLocalLanguage() || LANGUAGES[0].languageCode,
    nsSeparator: '::',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
