// import './App.css'
import React, { Suspense, useEffect } from 'react'
import { Provider } from 'react-redux'
import store from './redux-store/store'
import Routes from './routes'
import Loader from './components/Loader'
import { ToastProvider } from 'react-toast-notifications'
import ErrorBoundary from './components/ErrorBoundary'


function App () {
  useEffect(() => {
    Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve }))).then(() => {
      // document.querySelector('.loading-page').style.display = 'none'
    })
  }, [])

  return (
  
      <ToastProvider autoDismiss>
        <Suspense fallback={<Loader />}>
          <Provider store={store}>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Provider>
        </Suspense>
      </ToastProvider>


  )
}

export default App
