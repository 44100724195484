import { useState, useRef } from 'react'

import {
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  PayPalButtons,
  usePayPalHostedFields
} from '@paypal/react-paypal-js'
import { getAuthToken } from '../../../../utils/common-services/cookie.services'
import { useToasts } from 'react-toast-notifications'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { METHOD_TYPES } from '../../../../utils/constants'
import { fetchUserInformation } from '../../../../redux-store/redux/thunk/Users/User'
import Loader from '../../../../components/Loader'
import masterCard from '../../../../assets/image/mastercard.png'
import americanCard from '../../../../assets/image/american-express.png'
import visaCard from '../../../../assets/image/visa.png'
import discoverCard from '../../../../assets/image/discover.png'
import { useTranslation } from 'react-i18next'

const PaymentForm = () => {
  const { t } = useTranslation('home')
  const [loader, setLoader] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  const authToken = getAuthToken()
  const location = useLocation()
  const { addToast } = useToasts()
  const history = useHistory()
  const dispatch = useDispatch()
  const rosterData = location?.state?.rosterData
  const amount = location?.state?.amount

  const USER_URL = process.env.REACT_APP_BACKEND_URL_HTTP

  const createOrderEndpoint = rosterData
    ? 'buckets/purchase'
    : 'payment/deposit-paypal'
  const approveOrderEndpoint = rosterData
    ? 'buckets/purchase-capture'
    : 'payment/capture-deposit-paypal'
  const newData = rosterData
    ? { ...rosterData, paymentType: 'Paypal' }
    : { ...amount }

  const onApproveSuccess = () => {
    setPaymentSuccess(true)
    const id = setTimeout(() => {
      history.push('/lobby')
    }, 5000)
    setTimeoutId(id)
  }

  const handleGoToLobby = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      history.push('/lobby')
    }
  }

  const createOrder = async () => {
    try {
      const response = await fetch(`${USER_URL}/${createOrderEndpoint}`, {
        method: METHOD_TYPES.post,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify({
          ...newData
        })
      })

      const orderDataResponse = await response.json()
      const orderData = JSON.parse(orderDataResponse?.data?.data ?? '{}')
      if (orderData?.id) {
        return orderData.id
      } else {
        const errorDetail = orderDataResponse?.errors?.[0]
        const errorMessage = errorDetail
          ? `${errorDetail.description}`
          : JSON.stringify(orderData)
        addToast(errorMessage, { appearance: 'error' })
        throw new Error(errorMessage)
      }
    } catch (error) {
      console.error(`Could not initiate PayPal Checkout...${error}`)
      return `Could not initiate PayPal Checkout...${error}`
    }
  }

  const onApprove = async (data, actions) => {
    try {
      const response = await fetch(`${USER_URL}/${approveOrderEndpoint}`, {
        method: METHOD_TYPES.post,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify({
          transactionId: data.orderID,
          ...newData
        })
      })

      const orderData = await response.json()

      if (orderData?.status == 'Not Found') {
        addToast(t('notFound'), { appearance: 'error' })
        setLoader(false)
        const errorMessage = JSON.stringify(orderData)
        throw new Error(errorMessage)
      }
      if (orderData?.errors?.[0]) {
        addToast(orderData?.errors?.[0]?.description, { appearance: 'error' })
        setLoader(false)
        const errorMessage = JSON.stringify(orderData)
        throw new Error(errorMessage)
      }

      if (rosterData) {
        addToast(orderData?.data?.message, { appearance: 'success' })
      } else {
        addToast(orderData?.data?.message, { appearance: 'success' })
        dispatch(fetchUserInformation())
      }
      setLoader(false)
      onApproveSuccess()
    } catch (error) {
      console.error(error)
      console.error(`Sorry, your transaction could not be processed...${error}`)
    }
  }

  const onError = (e) => {
    console.error('err', e)
  }

  const SubmitPayment = () => {
    const { cardFields } = usePayPalHostedFields()
    const cardHolderName = useRef(null)

    const submitHandler = () => {
      setLoader(true)
      if (typeof cardFields.submit !== 'function') return
      cardFields
        .submit({
          cardholderName: cardHolderName?.current?.value
        })
        .then(async (data) => await onApprove(data))
        .catch((orderData) => {
          addToast(orderData?.details?.[0]?.description, {
            appearance: 'error'
          })
          setLoader(false)
          console.error(
            `Sorry, your transaction could not be processed...${JSON.stringify(
              orderData
            )}`
          )
        })
    }

    return (
      <button
        onClick={submitHandler}
        disabled={loader}
        className='w-full text-lg leading-none font-normal capitalize border-primaryColor-500 border-solid border px-8 py-4   rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-200  overflow-hidden group cursor-pointer z-[1] text-center'
      >
        <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
        {!loader ? t('pay') : ''}
      </button>

    )
  }

  return (
    <>
      {amount && (
        <div className='mx-8 mt-4'>
          <h1 className='flex justify-center items-center overflow-hidden'>
            <span className='text-[22px] px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full'>
              {t('amountDeposit')}
            </span>
          </h1>
        </div>
      )}
      {paymentSuccess ? (
        <div className='flex flex-col justify-center items-center h-[50vh]'>
          <span className='text-primaryColor-450 text-2xl font-bold'>
            {t('congratulations')}
          </span>
          <span className='text-primaryColor-450 text-lg mt-4 mb-8'>
            {t('paymentDoneSuccessfully')}
          </span>
          <div>
            <button
              onClick={handleGoToLobby}
              class='leading-tight px-[56px] py-[12px] text-[18px] bg-btnGrdient rounded-3xl overflow-hidden group relative hover:bg-btnGrdient-to-r hover:from-btnGrdient hover:to-btnGrdient text-white transition-all ease-out duration-300'
            >
              <span class='absolute blur-2xl right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white rotate-12 group-hover:-translate-x-60 te-x-44 ease animate-btnMotion'></span>
              <span class='relative uppercase leading-tight'>{t('goToLobby')}</span>
            </button>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`flex mx-[38%] mt-4
              ${loader && 'hidden'}`}
          >
            {amount && (
              <div className='flex items-left p-2'>
                <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500 capitalize'>
                  {t('amount')} <span className='px-2'>:</span>
                </p>
                <p className='text-lg max-lg:text-sm font-semibold text-customGreen-400'>
                  $
                  <span>
                    {rosterData ? rosterData?.amount : amount?.amount}
                  </span>
                </p>
              </div>
            )}
          </div>
          <div
            className={`flex justify-center items-center p-4 max-sm:px-2 z-[1] ${loader && 'hidden'
              }`}
          >
            <div className='z-[1]'>
              <PayPalButtons
                style={{
                  shape: 'rect',
                  layout: 'vertical'
                }}
                styles={{ marginTop: '4px', marginBottom: '4px' }}
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
                onError={(e) => onError(e)}
              />

              <div>
                <h1 className='flex justify-center items-center overflow-hidden'>
                  <span className='text-base px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full'>
                    {t('or')}
                  </span>
                </h1>
              </div>

              <PayPalHostedFieldsProvider
                createOrder={(data, actions) => createOrder(data, actions)}
              >
                <div className='mt-8'>
                  <label
                    for='text'
                    className='block px-5 max-sm:px-3 max-sm:px-2 text-base max-sm:text-xs font-medium text-white'
                  >
                    {t('enterCard')}
                  </label>
                  <PayPalHostedField
                    id='card-number'
                    hostedFieldType='number'
                    options={{
                      selector: '#card-number',
                      placeholder: 'xxxx-xxxx-xxxx-xxxx'
                    }}
                    className='mt-1 p-4 h-12 mb-4 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-800 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                  />
                  <div className='grid grid-cols-2 gap-4'>
                    <div className=''>
                      <label
                        for='date'
                        className='block px-5 max-sm:px-2 text-base max-sm:text-xs font-medium text-white'
                      >
                        {t('expirationDate')}
                      </label>
                      <PayPalHostedField
                        id='expiration-date'
                        hostedFieldType='expirationDate'
                        options={{
                          selector: '#expiration-date',
                          placeholder: 'xx-xxxx'
                        }}
                        className='mt-1 p-4 h-12 mb-4 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-800 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                      />
                    </div>
                    <div className=''>
                      <label
                        for='cvv'
                        className='block uppercase px-5 text-base max-sm:text-xs font-medium text-white'
                      >
                        {t('cvv')}
                      </label>
                      <PayPalHostedField
                        id='cvv'
                        hostedFieldType='cvv'
                        options={{
                          selector: '#cvv',
                          placeholder: 'xxx'
                        }}
                        className='mt-1 p-4 h-12 mb-4 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-800 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                      />
                    </div>
                  </div>
                  <div className='flex justify-center gap-8 pt-2 pb-6 [&>img]:w-[13%] [&>img]:ratio-[3/2] [&>img]:object-contain '>
                    <img src={masterCard} alt="master card" />
                    <img src={americanCard} alt="american card" />
                    <img src={visaCard} alt="visa card" />
                    <img src={discoverCard} alt="discover card" />
                  </div>
                  <SubmitPayment />
                </div>
              </PayPalHostedFieldsProvider>
            </div>
          </div>
          <div
            className={`flex flex-col justify-center items-center h-[50vh] ${!loader && 'hidden'
              }`}
          >
            <Loader />
            <h4 className='text-white text-xl mt-4'>
              {t('pleaseWait')}... {t('transactionProcessing')}
            </h4>
          </div>
        </>
      )}
    </>
  )
}

export default PaymentForm
