import React from 'react'
import { PhoneCall, EmailIcon } from '../../assets/image'
import envelopeIcon from '../../assets/image/envelope.png'
import { useContactUsController } from './controller/useContactUsController'
import Loader from '../../components/Loader'
import { LOADER } from '../../utils/constants'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const ContactUs = () => {
  const {
    register,
    errors,
    loading,
    handleContactUsSubmit,
    handleSubmit,
    reset,
    handleMobileNumberChange,
    watch,
    trigger
  } = useContactUsController()
  return (
    <>
      <div className='bg-contactBg bg-center bg-no-repeat bg-cover'>
        <div className='max-w-[1320px] mx-auto py-10 min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
          <div className='grid lg:grid-cols-2 grid-cols-1 items-center'>
            <div>
              <div className='flex justify-between max-w-[413px] max-sm:max-w-full max-sm:flex-wrap mb-12'>
                <div className='flex items-center justify-between gap-4 max-sm:gap-2'>
                  <PhoneCall className='w-10 max-sm:w-6 h-10 max-sm:h-6 object-contain' />
                  <p className='text-white capitalize text-base max-sm:text-xs font-semibold flex flex-col'>
                    Contact Number{' '}
                    <a
                      href='#'
                      className='text-base max-sm:text-xs font-normal text-primaryColor-700'
                    >
                      (303) 569-1279
                    </a>
                  </p>
                </div>
                <div className='flex items-center justify-between gap-4 max-sm:gap-2'>
                  <EmailIcon className='w-10 max-sm:w-6 h-10 max-sm:h-6 object-contain' />
                  <p className='text-white text-base max-sm:text-xs font-semibold flex flex-col'>
                    Email Support{' '}
                    <a
                      href='#'
                      className='text-base max-sm:text-xs font-normal text-primaryColor-700'
                    >
                      support@roster.com
                    </a>
                  </p>
                </div>
              </div>

              <div className='max-lg:hidden'>
                <img src={envelopeIcon} alt='' />
              </div>
            </div>

            <div>
              <div className='bg-primaryColor-100 rounded-3xl mt-10 p-8 max-sm:p-4'>
                <h3 className='text-2xl max-sm:text-base font-semibold text-primaryColor-800 capitalize'>
                  Get In Touch With Us
                </h3>

                <form
                  onSubmit={handleSubmit((data) => handleContactUsSubmit(data))}
                  className='mt-8'
                >
                  <div className='gap-4 grid grid-cols-2 max-sm:grid-cols-1'>
                    <div className=''>
                      <label
                        for='firstname'
                        className='block px-5 text-base max-sm:text-xs font-medium text-white'
                      >
                        First Name
                      </label>
                      <input
                        name='firstName'
                        // maxLength={45}
                        {...register('firstName')}
                        type='text'
                        id='firstname'
                        placeholder=''
                        className='mt-1 p-5 max-sm:px-4 max-sm:py-2.5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                      />
                      <p
                        id='outlined_error_help'
                        className={
                          errors &&
                          errors?.firstName &&
                          errors?.firstName?.message &&
                          ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                        }
                      >
                        {errors?.firstName?.message}
                      </p>
                    </div>

                    <div className=''>
                      <label
                        for='lastName'
                        className='block px-5 text-base max-sm:text-xs font-medium text-white'
                      >
                        Last Name
                      </label>
                      <input
                        name='lastName'
                        // maxLength={45}
                        {...register('lastName')}
                        type='text'
                        id='lastName'
                        placeholder=''
                        className='mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                      />
                      <p
                        id='outlined_error_help'
                        className={
                          errors &&
                          errors?.lastName &&
                          errors?.lastName?.message &&
                          ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                        }
                      >
                        {errors?.lastName?.message}
                      </p>
                    </div>

                    <div className=''>
                      <label
                        for='EmailAddress'
                        className='block px-5 text-base max-sm:text-xs font-medium text-white'
                      >
                        Email Address
                      </label>
                      <input
                        name='email'
                        // maxLength={255}
                        {...register('email')}
                        type='email'
                        id='email'
                        placeholder=''
                        className='mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700'
                      />
                      <p
                        id='outlined_error_help'
                        className={
                          errors &&
                          errors?.email &&
                          errors?.email?.message &&
                          ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                        }
                      >
                        {errors?.email?.message}
                      </p>
                    </div>

                    <div className=''>
                      <label
                        for='ContactNumber'
                        className='block px-5 text-base max-sm:text-xs font-medium text-white'
                      >
                        Contact Number
                      </label>
                      <PhoneInput
                        defaultCountry=''
                        placeholder=''
                        value={watch('phone')}
                        onChange={(e) => handleMobileNumberChange(e)}
                        className={`phone-input mt-1 p-5 max-sm:px-4 max-sm:py-2.5 w-full focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base placeholder-primaryColor-700`}
                      />
                      <p
                        id='outlined_error_help'
                        className={
                          errors &&
                          errors?.contact &&
                          errors?.contact?.message &&
                          ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                        }
                      >
                        {errors?.contact?.message}
                      </p>
                    </div>

                    <div className='sm:col-span-2'>
                      <label
                        for='userName'
                        className='block px-5 text-base max-sm:text-xs font-medium text-white'
                      >
                        Leave a message
                      </label>
                      <textarea
                        name='description'
                        {...register('description')}
                        rows='7'
                        className='w-full p-5 max-sm:px-4 max-sm:py-2.5 focus-visible:outline-none bg-primaryColor-900 rounded-[34px] text-primaryColor-700 text-base resize-none'
                      ></textarea>
                      <p
                        id='outlined_error_help'
                        className={
                          errors &&
                          errors?.description &&
                          errors?.description?.message &&
                          ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                        }
                      >
                        {errors?.description?.message}
                      </p>
                    </div>
                  </div>

                  <div className='flex justify-center items-center gap-4 mt-6'>
                    <button
                      className='text-lg leading-none w-full font-normal capitalize border-primaryColor-500 border-solid border px-[40px] py-4 rounded-lg relative inline-block text-white transition-all duration-300 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
                      disabled={loading}
                    >
                      <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                      {loading ? (
                        <Loader component={LOADER.BUTTON} />
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs
