import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
const Cms = () => {
    const { i18n } = useTranslation('home')
    const lang = i18n.language
    const location = useLocation();
    const currentRoute = location.pathname;
    const { allCms } = useSelector(state => state.gameSetting)
    const cms = allCms?.cms
    const currentData = cms?.find(item => `/${item.slug}` == currentRoute)

    useEffect(() => {
        // Adjust the height of the iframe to maintain aspect ratio
        const adjustIframeHeight = () => {
          const iframes = document.querySelectorAll('.responsive-iframe');
          iframes.forEach((iframe) => {
            let width = iframe.parentElement.offsetWidth;
            width = width*1
            const height = (width / 16) * 9; // Assuming 16:9 aspect ratio
            iframe.style.width = '100%';
            iframe.style.height = `${height}px`;
          });
        };
    
        adjustIframeHeight();
    
        // Re-adjust iframe height on window resize
        window.addEventListener('resize', adjustIframeHeight);
        return () => {
          window.removeEventListener('resize', adjustIframeHeight);
        };
      }, []);
// Function to extract video ID from YouTube URL
const getVideoIdFromUrl = (url) => {
    const regex = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w\-]{11})/;
    const match = url.match(regex);
    return match && match[1] ? match[1] : null;
  };
  // Function to parse HTML string and convert it to React components
  const parseHtmlToReact = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const elements = Array.from(doc.body.children);

    return elements.map((element, index) => {
      switch (element.nodeName) {
        case 'P':
            return <p key={index} dangerouslySetInnerHTML={{ __html: element.innerHTML }} />;
        case 'FIGURE':
          const oEmbedUrl = element.querySelector('oembed').getAttribute('url');
          const videoId = getVideoIdFromUrl(oEmbedUrl);
          return (
            <div key={index} style={{display:'flex',justifyContent:"center",alignItems:'center'}}>
              <iframe
                style={{}}
                src={`https://www.youtube.com/embed/${videoId}`}
                className="responsive-iframe"
                allowFullScreen
              ></iframe>
            </div>
          );
        default:
          return null;
      }
    });
  };
    return (
        <div className="max-w-[1320px] mx-auto pt-10 py-10 min-h-[calc(100dvh_-_505px)]">
            <div>
                <h1 className="flex justify-center items-center overflow-hidden">
                    <span className="text-[22px] px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full">{lang == "en"?currentData?.title:(currentData?.titleSp ?? currentData?.title)}</span>
                </h1>
            </div>

            {/* ---- DNP Insurance Section---- */}
            <div  className='text-base relative bg-primaryColor-200 rounded-[18px] p-6 mt-6 text-primaryColor-700'>
                {parseHtmlToReact(lang == "en"?currentData?.content:(currentData?.contentSp ?? currentData?.content))}

            </div>
        </div>
    )
}

export default Cms
