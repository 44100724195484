import { createSlice } from '@reduxjs/toolkit'
import { userLogout } from '../../redux/thunk/Auth/Auth'

const initialState = {
  user: null,
  loading: false
}
const {
  actions: {
    setLoader
  },
  reducer
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoader: (state, action) => ({
      ...state,
      loading: action.payload
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...initialState,
          loading: false
        }
      })
  }
})

export default reducer
export {
  setLoader
}
