/* ==========================================================================
  LANGUAGES
  ========================================================================== */

const LANGUAGES = [
  { label: 'English', languageCode: 'en' },
  { label: 'Spanish', languageCode: 'es' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
const TOKEN = 'authtoken'
const LANGUAGE = 'operator_language'

/* ==========================================================================
Loader types
========================================================================== */
const LOADER = {
  BUTTON: 'button'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Loader Types
========================================================================== */
const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  Table Cell Type
========================================================================== */
const TABLE_CELL_TYPE = {
  status: 'status',
  switch: 'switch',
  button: 'button',
  date: 'date',
  text: 'text',
  number: 'number',
  default: 'default',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */

const ROUTE_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  VERIFY_EMAIL: '/verify/:userId/:verificationToken',
  RESET_PASSWORD: '/reset-password/:userId/:resetToken',
  ABOUT: '/about',
  TRANSACTIONS: '/transactions',
  PLAYERSELECTION: '/player-selection/:bucketUid',
  PLAYERDETAIL: '/player-detail',
  PLAYERSPOTLIGHT: '/player-spotlight',
  MYACCOUNT: '/myaccount',
  LOBBY: '/lobby',
  TODAYRESULT: '/today-result',
  RESULTDETAILS: '/result-details',
  DNPINSURANCE: '/dnp-insurance',
  HOWTOPLAY: '/how-to-play',
  CONTACTUS: '/contact-us',
  PAYMENT: '/payment',
  TRANSACTIONS: '/transactions',
  CMS: '/cms',
  VIEW_ROSTER_DETAILS: '/view-roster-details',
  WITHDRAWAL: '/withdrawals',
  COMINGSOON: '/comingsoon',
  EARLY_BIRD:'/beta'
}

/* ==========================================================================
  DIALOG Types
========================================================================== */
const DIALOG_TYPE = {
  add: 'add',
  update: 'update',
  delete: 'delete',
  hide: ''
}

export const PAGINATION_LIMIT = 10

const EMAILS = [
  { label: 'partners', email: 'partners@rosterstars.com' },
  { label: 'support', email: 'support@rosterstars.com' },
  { label: 'legal', email: 'legal@rosterstars.com' }
]

// Export All constant variables
export {
  EMAILS,
  LANGUAGES,
  TOASTER_TYPE,
  TOKEN,
  LANGUAGE,
  ROUTE_PATHS,
  LOADER,
  METHOD_TYPES,
  LOADER_HANDLER_TYPES,
  TABLE_CELL_TYPE,
  DIALOG_TYPE
}
