import React from 'react'
import LandingPage from '../../components/LandingPage';

const LandingPageContainer = () => {

  return (<>
    <LandingPage />
  </>
  )
}

export default LandingPageContainer