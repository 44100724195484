import { createSlice } from '@reduxjs/toolkit'
import {
  getAllCms,
  getAllSocialLinks,
  getBanner,
  getBonusDetails,
  getDnpPageData,
  getLobbyContent,
  getLotteryInfo,
  getTimeSetting,
  getWebSettings,
  getWithdrawalList
} from '../redux/thunk/gameSetting'
import { userLogout } from '../redux/thunk/Auth/Auth'

const initialState = {
  gameSettingLoader: false,
  homeBanner: [],
  allCms: [],
  socialLinks: [],
  lobbyContent: null,
  timeSettings: null,
  lotteryInfo: null,
  withdrawalList: [],
  dnpPage:null,
  webSettings:null,
  bonus:null
}

const {
  actions: { setGameSettingLoader },
  reducer
} = createSlice({
  name: 'gameSetting',
  initialState,
  reducers: {
    setGameSettingLoader: (state, action) => ({
      ...state,
      setGameSettingLoader: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanner.fulfilled, (state, action) => {
        return {
          ...state,
          homeBanner: action.payload
        }
      })
      .addCase(getBonusDetails.fulfilled, (state, action) => {
        return {
          ...state,
          bonus: action.payload
        }
      })
      .addCase(getAllCms.fulfilled, (state, action) => {
        return {
          ...state,
          allCms: action.payload
        }
      })
      .addCase(getAllSocialLinks.fulfilled, (state, action) => {
        return {
          ...state,
          socialLinks: action.payload
        }
      })
      .addCase(getLobbyContent.fulfilled, (state, action) => {
        return {
          ...state,
          lobbyContent: action.payload
        }
      })
      .addCase(getDnpPageData.fulfilled, (state, action) => {
        return {
          ...state,
          dnpPage: action.payload
        }
      })
      .addCase(getTimeSetting.fulfilled, (state, action) => {
        return {
          ...state,
          timeSettings: action.payload
        }
      })
      .addCase(getLotteryInfo.fulfilled, (state, action) => {
        return {
          ...state,
          lotteryInfo: action.payload
        }
      })
      .addCase(getWithdrawalList.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawalList: action.payload
        }
      })
      .addCase(getWebSettings.fulfilled, (state, action) => {
        return {
          ...state,
          webSettings: action.payload
        }
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...state,
          gameSettingLoader: false,
          homeBanner: [],
          timeSettings: null,
          lotteryInfo: null,
          withdrawalList: []
        }
      })
  }
})

export default reducer
export { setGameSettingLoader }
