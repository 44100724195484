import React, { useEffect, useState } from 'react'
import { UserIcon } from '../../../../icons'
import { PaypalIcon, RugbyICon } from '../../../../assets/image'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { bucketPurchase } from '../../../../redux-store/redux/thunk/Lobby/Lobby'
import { LOADER, ROUTE_PATHS } from '../../../../utils/constants'
import PaypalPayment from '../../../PaypalPayment'
import CurrentTimeDifference from '../../../../components/CurrentTimeDifference'
import { useTranslation } from 'react-i18next'
import { fetchUserInformation } from '../../../../redux-store/redux/thunk/Users/User'
import { toFixed } from '../../../../utils/common-functions'
import Loader from '../../../../components/Loader'

const ViewRoster = () => {
  const { t } = useTranslation('playerSelection')
  const [paymentType, setPaymentType] = useState('Paypal')
  const [isBonusIncluded, setIsBonusIncluded] = useState(false)
  const [bucketPurchaseSuccess, setbucketPurchaseSuccess] = useState(false)
  const [bucketPurchaseLoader, setbucketPurchaseLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  const { dnpInsuranceAmount, rosterPlayerList, fullRosterDnp } = useSelector(
    (state) => state.rosterSlice
  )
  const { userDetails } = useSelector((state) => state.userSlice)
  const dispatch = useDispatch()
  const location = useLocation()
  const { addToast } = useToasts()
  const history = useHistory()
  const bucketDetails = location?.state?.bucketDetails
  const rosterData = location?.state?.rosterData

  useEffect(() => {
    if (rosterPlayerList?.length === 0) {
      history.goBack()
    }
  }, [rosterPlayerList])

  useEffect(() => {
    if (!bucketDetails) {
      history.push('/lobby')
    }
  }, [bucketDetails])

  useEffect(() => {
    if(moment(bucketDetails?.startTime, 'HH:mm:ssZ').isBefore(moment(bucketDetails?.endTime, 'HH:mm:ssZ'))) {
      if(moment().isAfter(moment(bucketDetails?.endTime, 'HH:mm:ssZ').local())) {
      history.push('/lobby')
      }
    } else if(moment(bucketDetails?.startTime, 'HH:mm:ssZ').local().isAfter(moment(bucketDetails?.endTime, 'HH:mm:ssZ').local())) {
      if(moment().isBetween(moment(bucketDetails?.endTime, 'HH:mm:ssZ'),moment(bucketDetails?.startTime, 'HH:mm:ssZ'))) {
        history.push('/lobby')
        }
    }
  }, [bucketDetails?.endTime])

  const onSuccess = () => {
    dispatch(fetchUserInformation())
    setbucketPurchaseSuccess(true)
    setbucketPurchaseLoading(false)
    const id = setTimeout(() => {
      history.push(ROUTE_PATHS.LOBBY)
    }, 5000)
    setTimeoutId(id)
  }

  const handleGoToLobby = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      history.push(ROUTE_PATHS.LOBBY)
    }
  }

  const handleWalletPay = () => {
    setbucketPurchaseLoading(true)
    if(userDetails?.wallets?.[0]?.amount + userDetails?.wallets?.[0]?.nonCashAmount < (rosterData?.amount +
      (fullRosterDnp == true
        ? bucketDetails
            ?.dnpInsurancePrice
        : dnpInsuranceAmount))) {
          addToast(t('InsufficentBalance'), { appearance: 'error' })
          return
        }
    const newData = {
      ...rosterData,
      amount:
        rosterData?.amount,
      paymentType: 'Wallet',
      isBonusIncluded
    }
    dispatch(bucketPurchase({ newData, history, addToast, onSuccess }))
  }

  const handleFreeBucketPay = () => {
    setbucketPurchaseLoading(true)
    const newData = {
      ...rosterData
    }
    dispatch(bucketPurchase({ newData, history, addToast, onSuccess }))
  }

  return (
    <>
      <div className='max-w-[1320px] mx-auto pt-10 pb-[70px] min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
      <div>
        <h1 className="flex justify-center items-center overflow-hidden mb-6">
          <span className="text-[22px] max-sm:text-base px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full">{t('viewRosterTitle')}</span>
        </h1>
      </div>

        <div className='flex gap-4 items-start flex-wrap justify-center'>
          <div className='bg-primaryColor-200 p-6 rounded-3xl max-w-[785px] w-full'>
            <div className='bg-primaryColor-100 px-8 max-md:px-4 py-6 rounded-3xl'>
              <div className=''>
                <div className='pb-4 mb-4 border-b border-solid border-primaryColor-350'>
                  <div className='flex items-center gap-[15px] max-md:justify-center'>
                    <RugbyICon className='w-[30px] h-[30px] object-contain' />
                    <span className='text-xl max-md:text-lg max-sm:text-base font-semibold text-white'>
                      {bucketDetails?.bucketUid}
                    </span>
                  </div>
                </div>

                <div className='flex justify-between max-md:flex-wrap max-md:gap-4 max-md:justify-center'>
                  <div>
                    <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500'>
                      {t('timeRemaining')}
                    </p>
                    <p className='text-lg max-lg:text-sm font-semibold text-white'>
                      <CurrentTimeDifference
                        time={bucketDetails?.endTime}
                      />
                    </p>
                  </div>
                  <div>
                    <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500'>
                      {t('contestEntryFee')}
                    </p>
                    <p className='text-lg max-lg:text-sm font-semibold text-white'>
                      $<span>{bucketDetails?.price && toFixed(bucketDetails?.price)}</span>
                    </p>
                  </div>
                  <div>
                    <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500'>
                      {t('contestSalaryCap')}
                    </p>
                    <p className='text-lg max-lg:text-sm font-semibold text-white'>
                      $<span>{bucketDetails?.budget && toFixed(bucketDetails?.budget)}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-4'>
              <div className='overflow-x-auto max-h-[770px]'>
                <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
                  <thead className='ltr:text-left rtl:text-right p-2'>
                    <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-xs [&>th]:tabletscreen:text-base'>
                      <th className='text-start whitespace-nowrap pl-4 pr-1 py-5 font-normal capitalize '>
                      {t('playername')}
                      </th>

                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                        {t('pos')}
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                        {t('careerAvg')}
                      </th>
                      <th className='whitespace-nowrap px-1 py-5 font-normal capitalize'>
                        {t('salary')}
                      </th>
                      {!fullRosterDnp &&
                        bucketDetails?.bucketType ==
                          'paid' && (
                          <th className='text-start whitespace-nowrap px-1 py-5 font-normal capitalize '>
                            {t('dnp')}
                          </th>
                        )}
                    </tr>
                  </thead>

                  <tbody className='[&>tr]:mb-0 :divide-y  divide-primaryColor-350 divide-solid '>
                    {rosterPlayerList.map((item, index) => {
                      return (
                        <tr className='[&>td]:text-center rounded-2x'>
                          <td className='!text-start capitalize whitespace-nowrap pl-4 pr-1 py-8 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                          {item?.name}
                          </td>

                          <td className='capitalize whitespace-nowrap px-1 py-8 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                            {item?.position}
                          </td>

                          <td className='whitespace-nowrap px-1 py-8 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                            {item?.careerAvg && toFixed(item?.careerAvg)}
                          </td>

                          <td className='whitespace-nowrap px-1 py-8 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                            $ <span>{item?.value && toFixed(item?.value)}</span>
                          </td>

                          {!fullRosterDnp &&
                            bucketDetails?.bucketType ==
                              'paid' && (
                              <td className='whitespace-nowrap px-1 py-8 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                <label
                                  htmlFor='BlueCheckbox'
                                  className='flex items-center justify-start'
                                >
                                  <input
                                    checked={item?.isPlayerDnp}
                                    type='checkbox'
                                    id='BlueCheckbox'
                                    className='cursor-pointer appearance-none w-6 h-6 bg-transparent border border-primaryColor-800 border-solid rounded-md checked:bg-bluecheckboxIcon checked:bg-no-repeat checked:bg-center'
                                  />
                                  <p className='text-base font-normal'>
                                    <span className='px-2 text-sm text-primaryColor-800'>
                                      $
                                      <span>
                                        {
                                          bucketDetails
                                            ?.dnpInsurancePerPlayer && 
                                            toFixed(bucketDetails?.dnpInsurancePerPlayer)
                                        }
                                      </span>
                                    </span>
                                  </p>
                                </label>
                              </td>
                            )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className='mt-5 bg-primaryColor-100 px-8 max-md:px-4 py-6 rounded-3xl'>
              <div className='flex justify-between max-sm:flex-wrap max-sm:gap-4 max-sm:justify-center flex-wrap'>
                <div className='flex items-center gap-[56px] max-lg:gap-4 max-lg:flex-wrap max-lg:justify-center'>
                  <div className='flex items-center '>
                    <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500 capitalize'>
                      {t('bucketPrice')} <span className='px-2'>:</span>
                    </p>
                    <p className='text-lg max-lg:text-sm font-semibold text-customGreen-400'>
                      {bucketDetails?.bucketType ==
                        'paid' && '$'}
                      <span>
                        {bucketDetails?.bucketType ==
                        'free'
                          ? 'Free'
                          : bucketDetails?.price && toFixed(bucketDetails?.price)}
                      </span>
                    </p>
                  </div>
                  {dnpInsuranceAmount !== 0 && (
                    <div className='flex items-center '>
                      <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500 capitalize'>
                        {t('dnpInsurance')} <span className='px-2'>:</span>
                      </p>
                      <p className='text-lg max-lg:text-sm font-semibold text-customGreen-400'>
                        $ <span>{dnpInsuranceAmount}</span>
                      </p>
                    </div>
                  )}
                  {bucketDetails?.bucketType ==
                        'paid' && (
                    <div className='flex items-center '>
                      <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500 capitalize'>
                        {t('totalPrice')} <span className='px-2'>:</span>
                      </p>
                      <p className='text-lg max-lg:text-sm font-semibold text-customGreen-400'>
                        $ <span>{dnpInsuranceAmount + bucketDetails?.price}</span>
                      </p>
                    </div>
                  )}
                </div>

                {fullRosterDnp && (
                  <div className='flex items-center gap-4 max-sm:flex-[1_100%] max-sm:justify-center justify-center w-full'>
                    <label
                      htmlFor='BlueCheckbox'
                      className='flex items-center justify-start'
                    >
                      <input
                        checked={fullRosterDnp}
                        type='checkbox'
                        id='BlueCheckbox'
                        className='cursor-pointer appearance-none w-6 h-6 bg-transparent border border-primaryColor-800 border-solid rounded-md checked:bg-bluecheckboxIcon checked:bg-no-repeat checked:bg-center'
                      />
                    </label>
                    <p className='text-lg max-lg:text-sm font-normal text-white capitalize'>
                      {t('fullRosterDnpInsurance')}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {bucketDetails?.bucketType === 'free' ? (
              bucketPurchaseSuccess ? (
                <div className='flex flex-col justify-center items-center h-[50vh]'>
                  <span className='text-primaryColor-450 text-2xl font-bold'>
                    {t('congratulations')}
                  </span>
                  <span className='text-primaryColor-450 text-lg mt-4 mb-8'>
                    {t('bucketJoinedSuccessfully')}
                  </span>
                  <div>
                    <button
                      onClick={handleGoToLobby}
                      class='leading-tight px-[56px] py-[12px] text-[18px] bg-btnGrdient rounded-3xl overflow-hidden group relative hover:bg-btnGrdient-to-r hover:from-btnGrdient hover:to-btnGrdient text-white transition-all ease-out duration-300'
                    >
                      <span class='absolute blur-2xl right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white rotate-12 group-hover:-translate-x-60 te-x-44 ease animate-btnMotion'></span>
                      <span class='relative uppercase leading-tight'>
                        {t('goToLobby')}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className='m-12 text-center'>
                  <button
                    disabled={bucketPurchaseLoader}
                    onClick={handleFreeBucketPay}
                    className='w-full text-lg leading-none font-normal capitalize border-primaryColor-500 border-solid border px-8 py-4   rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
                  >
                    <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                    {t('joinNow')}
                    <span>{bucketPurchaseLoader ? <Loader type={LOADER.BUTTON}/> : ''}</span>
                  </button>
                </div>
              )
            ) : null}
          </div>

          {bucketDetails?.bucketType !== 'free' && (
            <div className='bg-primaryColor-200 p-6 max-sm:px-2 rounded-3xl max-w-[516px] w-full'>
              <div className='max-sm:p-0'>
                <div>
                  {!bucketPurchaseSuccess && (
                    <div className='w-full'>
                      <ul className='bg-primaryColor-100 rounded-[56px] columns-2 p-2 mt-6'>
                        <li
                          onClick={() => setPaymentType('Paypal')}
                          className={`${
                            paymentType == 'Paypal' ? 'resultBtnBg' : ''
                          } text-center p-2.5 tabletscreen:px-4 tabletscreen:py-2.5 flex flex-col items-center gap-4 group cursor-pointer`}
                        >
                          <span className='text-xs tabletscreen:text-lg capitalize text-primaryColor-800 group-[.resultBtnBg]:text-dustyGray-700'>
                            Paypal
                          </span>
                        </li>
                        <li
                          onClick={() => setPaymentType('Wallet')}
                          className={`${
                            paymentType == 'Wallet' ? 'resultBtnBg' : ''
                          } text-center p-2.5 tabletscreen:px-4 tabletscreen:py-2.5 flex flex-col items-center gap-4 group cursor-pointer`}
                        >
                          <span className='text-xs tabletscreen:text-lg capitalize text-primaryColor-800 group-[.resultBtnBg]:text-dustyGray-700'>
                            {t('wallet')}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}

                  {bucketDetails?.bucketType === 'free' ? (
                    bucketPurchaseSuccess && (
                      <div className='flex flex-col justify-center items-center h-[50vh]'>
                        <span className='text-primaryColor-450 text-2xl font-bold'>
                          {t('congratulations')}
                        </span>
                        <span className='text-primaryColor-450 text-lg mt-4 mb-8'>
                        {t('bucketJoinedSuccessfully')}
                        </span>
                        <div>
                          <button
                            onClick={handleGoToLobby}
                            class='leading-tight px-[56px] py-[12px] text-[18px] bg-btnGrdient rounded-3xl overflow-hidden group relative hover:bg-btnGrdient-to-r hover:from-btnGrdient hover:to-btnGrdient text-white transition-all ease-out duration-300'
                          >
                            <span class='absolute blur-2xl right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white rotate-12 group-hover:-translate-x-60 te-x-44 ease animate-btnMotion'></span>
                            <span class='relative uppercase leading-tight'>
                            {t('goToLobby')}
                            </span>
                          </button>
                        </div>
                      </div>
                    )
                  ) : paymentType === 'Wallet' ? (
                    bucketPurchaseSuccess ? (
                      <div className='flex flex-col justify-center items-center h-[50vh]'>
                        <span className='text-primaryColor-450 text-2xl font-bold'>
                        {t('congratulations')}
                        </span>
                        <span className='text-primaryColor-450 text-lg mt-4 mb-8'>
                          {t('paymentDoneSuccessfully')}
                        </span>
                        <div>
                          <button
                            onClick={handleGoToLobby}
                            class='leading-tight px-[56px] py-[12px] text-[18px] bg-btnGrdient rounded-3xl overflow-hidden group relative hover:bg-btnGrdient-to-r hover:from-btnGrdient hover:to-btnGrdient text-white transition-all ease-out duration-300'
                          >
                            <span class='absolute blur-2xl right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white rotate-12 group-hover:-translate-x-60 te-x-44 ease animate-btnMotion'></span>
                            <span class='relative uppercase leading-tight'>
                            {t('goToLobby')}
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className='m-8 text-center'>
                        <div className='flex items-center p-2'>
                          <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500 capitalize'>
                            {t('amountToPay')} <span className='px-2'>:</span>
                          </p>
                          <p className='text-lg max-lg:text-sm font-semibold text-customGreen-400'>
                            $
                            <span>
                              {rosterData?.amount +
                                (fullRosterDnp == true
                                  ? bucketDetails
                                      ?.dnpInsurancePrice
                                  : dnpInsuranceAmount)}
                            </span>
                          </p>
                        </div>
                        <div className='flex items-center p-2'>
                          <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500 capitalize'>
                            {t('availableBalance')} <span className='px-2'>:</span>
                          </p>
                          <p className='text-lg max-lg:text-sm font-semibold text-customGreen-400'>
                            $
                            <span>
                              {userDetails?.wallets?.[0]?.amount && toFixed(userDetails?.wallets?.[0]?.amount)}
                            </span>
                          </p>
                        </div>
                        <div className='flex items-center p-2'>
                          <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500 capitalize'>
                            {t('availableBonusBalance')} <span className='px-2'>:</span>
                          </p>
                          <p className='text-lg max-lg:text-sm font-semibold text-customGreen-400'>
                            $
                            <span>
                              {userDetails?.wallets?.[0]?.nonCashAmount && toFixed(userDetails?.wallets?.[0]?.nonCashAmount)}
                            </span>
                          </p>
                        </div>
                        {userDetails?.wallets?.[0]?.nonCashAmount > 0 && 
                        <div className='flex items-center p-2 mb-4'>
                        <p className='mr-2 text-lg max-lg:text-sm font-normal text-primaryColor-500 capitalize'>
                            {t('payWithBonusBalance')}
                          </p>
                          <label
                            htmlFor='BlueCheckbox'
                            className='flex items-center justify-start'
                          >
                            <input
                              checked={isBonusIncluded}
                              onChange={() => setIsBonusIncluded(prev => !prev)}
                              type='checkbox'
                              id='BlueCheckbox'
                              className='cursor-pointer appearance-none w-6 h-6 bg-transparent border border-primaryColor-800 border-solid rounded-md checked:bg-bluecheckboxIcon checked:bg-no-repeat checked:bg-center'
                            />
                          </label>
                        </div>}
                        <button
                          disabled={bucketPurchaseLoader}
                          onClick={handleWalletPay}
                          className='w-full text-lg leading-none font-normal capitalize border-primaryColor-500 border-solid border px-8 py-4   rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
                        >
                          <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                          {t('pay')} {isBonusIncluded && (rosterData?.amount + (fullRosterDnp == true 
                          ? bucketDetails?.dnpInsurancePrice 
                          : dnpInsuranceAmount)) - userDetails?.wallets?.[0]?.nonCashAmount > 0 && 
                          `( $${(rosterData?.amount + (fullRosterDnp == true
                                  ? bucketDetails?.dnpInsurancePrice
                                  : dnpInsuranceAmount)) - userDetails?.wallets?.[0]?.nonCashAmount} )`}
                            <span>{bucketPurchaseLoader ? <Loader type={LOADER.BUTTON}/> : ''}</span>
                        </button>
                      </div>
                    )
                  ) : (
                      <PaypalPayment />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewRoster
