import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis/index'

export const getBucketListService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'buckets/list', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data

  })
}

export const getBucketInfoService = (bucketUid) => {
  return axiosInstance(METHOD_TYPES.get, 'buckets/details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { bucketUid }
  })
}

export const bucketPurchaseService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'buckets/purchase', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getBucketPlayerListService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'buckets/bucket-users', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data

  })
}
