import React, { Suspense } from 'react'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import CustomRoutes from './routes'
import Loader from '../components/Loader'

const Routes = () => {
  return (
  <Router>
    <Suspense fallback={<Loader />}>
      <CustomRoutes />
    </Suspense>
  </Router>
)
}

export default Routes
