import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CloseIcon, WalletIcon } from '../../assets/image'
import { useHistory } from 'react-router-dom'
import { LOADER } from '../../utils/constants'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { withdrawRequest } from '../../redux-store/redux/thunk/gameSetting'
import { useToasts } from 'react-toast-notifications'
import { toFixed } from '../../utils/common-functions'

const Wallet = ({
  showWalletModal,
  handleShowWallet,
  activeTabCashier,
  setActiveTabCashier
}) => {
  const { t } = useTranslation('header')
  const cancelButtonRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const { addToast } = useToasts()
  const { userDetails } = useSelector((state) => state.userSlice)

  const depositSchema = yup.object().shape({
    amount: yup
      .string()
      .required(`${t('wallet.errors.amount.required')}`)
      .matches(/^\d+(\.\d{1,2})?$/, t('wallet.errors.amount.pattern'))
  })

  const withdrawSchema = yup.object().shape({
    amount: yup
      .string()
      .required(`${t('wallet.errors.amount.required')}`)
      .matches(/^\d+(\.\d{1,2})?$/, t('wallet.errors.amount.pattern')),
    paypalUserEmail: yup
      .string()
      .required(`${t('wallet.errors.paypalUserEmail.required')}`)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('wallet.errors.paypalUserEmail.pattern')
      )
  })

  const validationSchema =
    activeTabCashier === 'deposit' ? depositSchema : withdrawSchema

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })

  const onSuccess = () => {
    reset()
    handleShowWallet(false)
    setLoader(false)
  }

  const onError = () => {
    setLoader(false)
  }

  const handleWalletSubmit = (formData) => {
    if (activeTabCashier == 'deposit') {
      const amount = {
        amount: formData?.amount
      }
      history.push('/payment', { amount })
    } else {
      setLoader(true)
      const data = {
        paypalUserEmail: formData?.paypalUserEmail,
        amount: formData?.amount
      }
      dispatch(withdrawRequest({ data, addToast, onSuccess, onError }))
    }
  }

  const handleActiveTab = (tab) => {
    reset()
    setActiveTabCashier(tab)
  }

  return (
    <>
      <Transition.Root show={showWalletModal} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          initialFocus={cancelButtonRef}
          onClose={() => handleShowWallet(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-popupBg-100 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full justify-center p-4 text-center items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative shadow-dialogShadow transform overflow-hidden rounded-lg bg-primaryColor-200 text-left transition-all sm:my-8 w-full max-w-[450px]'>
                  <div className='px-4 py-5'>
                    <div>
                      <div className='flex items-center gap-2'>
                        <WalletIcon />{' '}
                        <span className='text-white text-lg font-normal'>
                          {t('wallet.wallet')}
                        </span>
                      </div>
                      <button
                        onClick={() => handleShowWallet(false)}
                        className='absolute right-6 top-5 cursor-pointer outline-none'
                      >
                        {/* <img src={closeIcon} alt="close btn" /> */}
                        <CloseIcon />
                      </button>
                    </div>
                    <div className='p-4 max-sm:p-0'>
                      <form onSubmit={handleSubmit(handleWalletSubmit)}>
                        <div className='w-full'>
                          <ul className='bg-primaryColor-100 rounded-[56px] columns-2 p-2 mt-6'>
                            <li
                              onClick={() => handleActiveTab('deposit')}
                              className={`${
                                activeTabCashier == 'deposit'
                                  ? 'resultBtnBg'
                                  : ''
                              } text-center p-2.5 tabletscreen:px-4 tabletscreen:py-2.5 flex flex-col items-center gap-4 group cursor-pointer`}
                            >
                              <span className='text-xs tabletscreen:text-lg capitalize text-primaryColor-800 group-[.resultBtnBg]:text-dustyGray-700'>
                                {t('wallet.deposit')}
                              </span>
                            </li>
                            <li
                              onClick={() => handleActiveTab('withdraw')}
                              className={`${
                                activeTabCashier == 'withdraw'
                                  ? 'resultBtnBg'
                                  : ''
                              } text-center p-2.5 tabletscreen:px-4 tabletscreen:py-2.5 flex flex-col items-center gap-4 group cursor-pointer`}
                            >
                              <span className='text-xs tabletscreen:text-lg capitalize text-primaryColor-800 group-[.resultBtnBg]:text-dustyGray-700'>
                                {t('wallet.withdraw')}
                              </span>
                            </li>
                          </ul>
                        </div>

                        {activeTabCashier == 'withdraw' && (
                          <div>
                            <div className='relative min-w-[251px] max-sm:min-w-min mt-8'>
                              <label
                                className='block block text-sm font-medium text-primaryColor-800 pb-2'
                                for='grid-state'
                              >
                                {t('wallet.enterYourPaypalUserEmail')}
                              </label>
                              <input
                                name='paypalUserEmail'
                                maxLength={50}
                                autoComplete='off'
                                {...register('paypalUserEmail')}
                                type='text'
                                className='datePicker block w-full px-[18px] py-3.5 text-sm tabletscreen:text-base text-white border border-primaryColor-400 rounded-lg bg-primaryColor-750 focus-visible:border-primaryColor-500 focus-visible:outline-none placeholder:text-primaryColor-500 !leading-none'
                              />
                            </div>
                            <p
                              id='outlined_error_help'
                              className={
                                errors &&
                                errors?.paypalUserEmail &&
                                errors?.paypalUserEmail?.message &&
                                ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                              }
                            >
                              {errors?.paypalUserEmail?.message}
                            </p>
                          </div>
                        )}

                        <div>
                          <div className='relative min-w-[251px] max-sm:min-w-min mt-8'>
                            <label
                              className='block block text-sm font-medium text-primaryColor-800 pb-2'
                              for='grid-state'
                            >
                              {activeTabCashier == 'deposit'
                                ? t('wallet.enterYourDepositAmount')
                                : t('wallet.enterYourWithdrawAmount')}
                            </label>
                            <input
                              name='amount'
                              maxLength={50}
                              autoComplete='off'
                              {...register('amount')}
                              type='text'
                              className='datePicker block w-full px-[18px] py-3.5 text-sm tabletscreen:text-base text-white border border-primaryColor-400 rounded-lg bg-primaryColor-750 focus-visible:border-primaryColor-500 focus-visible:outline-none placeholder:text-primaryColor-500 !leading-none'
                            />
                          </div>
                          <p
                            id='outlined_error_help'
                            className={
                              errors &&
                              errors?.amount &&
                              errors?.amount?.message &&
                              ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                            }
                          >
                            {errors?.amount?.message}
                          </p>
                        </div>

                        {activeTabCashier == 'withdraw' && (
                          <div className='my-8'>
                            <p className='flex items-center gap-2 justify-center'>
                              <span className='text-primaryColor-500 text-sm font-normal'>
                                <span>{t('wallet.withdrawableAmount')}</span> :
                              </span>
                              <span className='text-white text-sm font-normal'>
                                $ <span>{userDetails?.wallets?.[0]?.amount && 
                                toFixed(userDetails?.wallets?.[0]?.amount)}</span>
                              </span>
                            </p>
                          </div>
                        )}

                        <div className='mt-6 mb-4 text-center'>
                          <button
                            className='max-w-[245px] w-full text-base leading-none font-normal capitalize border-primaryColor-500 border-solid border px-8 py-4   rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
                            disabled={loader}
                          >
                            <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                            {loader ? (
                              <Loader type={LOADER.BUTTON} />
                            ) : activeTabCashier == 'deposit' ? (
                              t('wallet.deposit')
                            ) : (
                              t('wallet.withdraw')
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Wallet
