import React, { useRef } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseIcon, RugbyICon } from '../../../../assets/image'
import Loader from '../../../../components/Loader'
import { useTranslation } from 'react-i18next'

const RosterDetailPopup = ({
  showRosterDetailPopup,
  setShowRosterDetailPopup,
  rosterDetail,
  setRosterDetail,
  loader,
  rosterDetailBucket,
  setRosterDetailBucket,
  activeTab
}) => {
  const { t } = useTranslation('playerSelection')
  const cancelButtonRef = useRef(null)

  const handleClose = () => {
    setShowRosterDetailPopup(false)
    setRosterDetail([])
    setRosterDetailBucket([])
  }

  return (
    <>
      <Transition.Root show={showRosterDetailPopup} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-popupBg-100 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='max-w-[785px] w-full relative transform overflow-hidden rounded-lg bg-primaryColor-200 text-left shadow-dialogShadow transition-all '>
                  <div className='p-6 max-sm:p-4'>
                    <button
                      onClick={handleClose}
                      className='absolute right-6 top-5 cursor-pointer outline-none'
                    >
                      {/* <img src={closeIcon} alt="close btn" /> */}
                      <CloseIcon />
                    </button>
                    <div className=''>
                      <div className='flex gap-4 items-start flex-wrap justify-center'>
                        <div className='bg-primaryColor-200 w-full pt-6'>
                          <div className='bg-primaryColor-100 px-4 max-md:px-4 py-4 rounded-3xl'>
                            <div className=''>
                              <div className='pb-2 mb-2 border-b border-solid border-primaryColor-350'>
                                <div className='flex items-center gap-[15px] max-md:justify-center'>
                                  <RugbyICon className='w-[25px] h-[25px] object-contain' />
                                  <span className='text-xl max-md:text-lg max-sm:text-base font-semibold text-white'>
                                    {rosterDetailBucket?.Bucket?.bucketUid}
                                  </span>
                                </div>
                              </div>

                              <div className='flex justify-between max-md:flex-wrap max-md:gap-4 max-md:justify-center'>
                                <div>
                                  <p className='text-base max-lg:text-sm font-normal text-primaryColor-500'>
                                  {t('contestEntryFee')}
                                  </p>
                                  <p className='text-base max-lg:text-sm font-semibold text-white'>
                                    $<span>{rosterDetailBucket?.amount}</span>
                                  </p>
                                </div>
                                <div>
                                  <p className='text-base max-lg:text-sm font-normal text-primaryColor-500'>
                                    {t('submissionTime')}
                                  </p>
                                  <p className='text-base max-lg:text-sm font-semibold text-white'>
                                    <span>{moment(rosterDetailBucket?.submittedAt).format('MM/DD/YYYY / HH:mm:ss')}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {loader ? (
                            <Loader />
                          ) : (
                            <div className='mt-3'>
                              <div className='overflow-x-auto max-h-[770px]'>
                                <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
                                  <thead className='ltr:text-left rtl:text-right p-2'>
                                    <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-xs [&>th]:tabletscreen:text-base'>
                                      <th className='text-start whitespace-nowrap pl-4 pr-1 py-3 font-normal capitalize '>
                                        {t('position')}
                                      </th>

                                      <th className='whitespace-nowrap px-1 py-3 font-normal capitalize text-center'>
                                        {t('playerName')}
                                      </th>
                                      {activeTab == 2 && (
                                        <th className='whitespace-nowrap px-1 py-3 font-normal capitalize text-center'>
                                          {t('points')}
                                        </th>
                                      )}
                                      <th className='text-start whitespace-nowrap px-1 py-3 font-normal capitalize '>
                                        DNP
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className='[&>tr]:mb-0 divide-y divide-primaryColor-350 divide-solid '>
                                    {rosterDetail?.map((item, index) => {
                                      return (
                                        <tr className='[&>td]:text-center rounded-2x'>
                                          <td className='!text-start uppercase whitespace-nowrap pl-4 pr-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                            {item?.Player?.position}
                                          </td>

                                          <td className='capitalize whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                            {item?.Player?.name}
                                          </td>

                                          {activeTab == 2 && (
                                            <td className='capitalize whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                              {((rosterDetailBucket?.dnpTaken ||  item?.isPlayerDnp) && item?.earnedPoints == item?.Player?.careerAvg ) ? `0 ( ${item?.earnedPoints && parseFloat(item?.earnedPoints).toFixed(2)} )`: item?.earnedPoints && parseFloat(item?.earnedPoints).toFixed(2)}
                                            </td>
                                          )}
                                          <td className='whitespace-nowrap px-1 py-4 text-primaryColor-850 text-xs tabletscreen:text-base font-semibold'>
                                            <label
                                              htmlFor='BlueCheckbox'
                                              className='flex items-center justify-start'
                                            >
                                              <input
                                                checked={item?.isPlayerDnp || rosterDetailBucket?.dnpTaken}
                                                type='checkbox'
                                                id='BlueCheckbox'
                                                className='cursor-pointer appearance-none w-6 h-6 bg-transparent border border-primaryColor-800 border-solid rounded-md checked:bg-bluecheckboxIcon checked:bg-no-repeat checked:bg-center'
                                              />
                                            </label>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                          <div className='mt-4 bg-primaryColor-100 px-8 max-md:px-4 py-6 rounded-3xl'>
                            <div className=''>

                              <div className='flex justify-between max-md:flex-wrap max-md:gap-4 max-md:justify-center'>
                              <div className='flex justify-center align-center'>
                                  <p className='mr-4 text-lg max-lg:text-sm font-normal text-primaryColor-500'>
                                    Full Roster Dnp
                                  </p>
                                  <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500'>
                                    <input
                                      checked={rosterDetailBucket?.dnpTaken}
                                      type='checkbox'
                                      className='cursor-pointer appearance-none w-6 h-6 bg-transparent border border-primaryColor-800 border-solid rounded-md checked:bg-bluecheckboxIcon checked:bg-no-repeat checked:bg-center'
                                      />
                                  </p>
                                </div>
                              {activeTab == 2 && <div className='flex items-center gap-2'>
                                  <p className='text-lg max-lg:text-sm font-normal text-primaryColor-500'>
                                    {t('totalPoints')} :
                                  </p>
                                  <p className='text-lg max-lg:text-sm font-semibold text-white'>
                                    <span>{rosterDetail && parseFloat(rosterDetail?.reduce((acc,obj) => acc+obj?.earnedPoints,0)).toFixed(2)}</span>
                                  </p>
                                </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default RosterDetailPopup
