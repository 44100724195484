import homeEn from './home.json'
import HeaderEn from './header.json'
import LobbyEn from './lobby.json'
import PlayerSelectionEn from './playerSelection.json'
import landingPageEn from './landingPage.json'

const translationsEN = {
  home: homeEn,
  header: HeaderEn,
  lobby: LobbyEn,
  playerSelection: PlayerSelectionEn,
  landingPage: landingPageEn
}

export default translationsEN
