import axiosInstance, { microServices } from '../../network/apis/index'
import { SuccessMessage } from '../../helpers/messages'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'

/**
 * Service to do login
 * @param {object} data - object contains username and password
 */
export const loginService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/login', data, {
    server: microServices.USER_URL,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.login
  })
}

/**
 * Service to do logout
 */
export const logoutService = () => {
  return axiosInstance(METHOD_TYPES.post, 'user/logout', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: SuccessMessage.logout
  })
}

export const signUpService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/signup', data, {
    server: microServices.USER_URL,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.signup
  })
}

/**
 * Service to change password
 * @param {object} data - object contains old and new password
 */
export const updatePasswordService = (data) => {
  return axiosInstance(METHOD_TYPES.put, 'user/change-password', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const forgetPasswordService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/forgot-password', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}
export const forgetVerifyPasswordService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/verify-password-token', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const verifyEmailService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/verify-email-token', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const checkUserAddress = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/check-user-address', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getMetaUser = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/login-with-metaMask', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}
export const loginWithOtpService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/login', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const placeBetCrashGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'crash-game/place-bet-crash-game', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}
export const escapeCrashBetService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'crash-game/player-escape-crashGame', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const cancelCrashBetService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'crash-game/cancel-bet-crash-game', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}
export const getAllRoundHistoryService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/get-crash-game-history', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword,
    params
  })
}
export const getAllBetDataService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-total-bets', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getTotalWinningAmountService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/system/get-total-winning-amount', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getCrashGameRoundDetails = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/get-crash-game-history', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getCrashGameRoundDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/get-crash-game-round-detail', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getMyBetsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/get-crash-game-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const verifyMetaSignature = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/verify-metaSignature', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const CrashCheckFairness  = ({ data }) => {
  return axiosInstance(METHOD_TYPES.post, 'crash-game/check-fairness', data, {
    server: microServices.USER_URL
    // headers: { 'demo-game': isDemoGame }
  })
}

export const allRoundPlacedBets  = ({ params }) => {
  return axiosInstance(METHOD_TYPES.get, 'crash-game/get-all-round-placed-bets', {}, {
    server: microServices.USER_URL,
    params
    // headers: { 'demo-game': isDemoGame }
  })
}

export const getHighrollerCrashService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/crash-game/top-bets', {}, {
    server: microServices.USER_URL,
    params
  })
}

export const recordUserDetails = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/record-user-detail', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}


// getMetaUser = (payload) =>{
//   return new Promise((resolve, reject) => {
//       axios
//           .get(`${apiConfig.routes.getMetaUser}`, {
//               params: {
//                   ...payload
//               }
//           })
//           .then((response) => {
//               resolve(response.data);
//           })
//           .catch((error) => {
//               reject(error);
//           });
//   });
// }

// verifyMetaSignature = (payload) =>{
//   return new Promise((resolve, reject) => {
//       axios
//           .post(`${apiConfig.routes.verifyMetaSignature}`, {
//               ...payload
//           })
//           .then((response) => {
//               this.setSession(response?.data?.auth_token);
//               resolve(response.data);
//           })
//           .catch((error) => {
//               reject(error);
//           });
//   });
// }

export const resetPasswordService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/verify-password-token', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    // successMessage: SuccessMessage.changePassword
  })
}
