import Cookies from 'universal-cookie'
const cookies = new Cookies()

// export const getAuthToken = () => {
//   const tokenString = window.sessionStorage.getItem('authtoken')
//   const userToken = JSON.parse(tokenString)
//   return userToken
// }

// export const setAuthToken = userToken => {
//   window.sessionStorage.setItem('authtoken', JSON.stringify(userToken))
// }

// 24 hour * 60 minutes of every hour
const COOKIE_EXPIRE_MIN = 24 * 60

/* ==========================================================================
  Auth Token
========================================================================== */
export const getAuthToken = () => {
  return cookies.get('authtoken')
}
export const isEarlyBirdAuthenticated = () => {
  return sessionStorage.getItem('isVerified')
}
export const setAuthToken = (authAccessToken) => {
  cookies.set('authtoken', authAccessToken, {
    path: '/',
    expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
  })

}

export const removeAuthToken = () => {
  cookies.remove('authtoken', { path: '/' })
  // sessionStorage.removeItem('authtoken')
}

export const removeMaxBtn = () => {
  // cookies.remove(TOKEN, { path: '/' })
  localStorage.removeItem('is-max-btn-enable')
}

/* ==========================================================================
  Signin Action
========================================================================== */
export const signIn = ({ token, operatorId, role }) => {
  setAuthToken(token)
  // setOperatorId(operatorId)
  // setOperatorRole(role)
}

/* ==========================================================================
  Signout Action
========================================================================== */
export const signOut = () => {
  removeAuthToken()
  removeMaxBtn()
  // removeOperatorId()
  // removeOperatorRole()
}
