import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { userSignUp } from '../../redux-store/redux/thunk/Auth/Auth'
import { setLoader } from '../../redux-store/redux-slices/Users/user'
import { useLocation } from 'react-use'
import './register.scss'
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CloseIcon,
  HidePasswordIcon,
  ShowPasswordIcon
} from '../../assets/image'
import { LOADER } from '../../utils/constants'
import Loader from '../../components/Loader'

const Register = ({
  setShowPopup,
  showRegisterPopup,
  handleShowRegister,
  handleShowLogin,
  fromComingSoon,
  openSuccessPopup
}) => {
  const { t } = useTranslation('home')
  const dispatch = useDispatch()
  const { search } = useLocation()
  const affiliateCode = new URLSearchParams(search).get('affiliateCode')
  const { loading } = useSelector((state) => state.user)

  const { addToast } = useToasts()

  const handleRegisterSubmit = useCallback(
    async (userDetails, reset) => {
      if (affiliateCode) {
        userDetails.affiliatedBy = affiliateCode
      }
      try {
        dispatch(setLoader(true))
        dispatch(
          userSignUp({
            userDetails,
            addToast,
            handleShowRegister,
            reset,
            fromComingSoon,
            openSuccessPopup
          })
        )
      } catch (e) {
        addToast(e.message, { appearance: 'error' })
      }
    },
    [affiliateCode, addToast]
  )

  const cancelButtonRef = useRef(null)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(`${t('signup.errors.firstName.required')}`)
      .min(2, `${t('signup.errors.firstName.minLength')}`)
      .max(45, `${t('signup.errors.firstName.maxLength')}`)
      .matches(/^[A-Za-z]+$/, t('signup.errors.firstName.pattern')),
    lastName: yup
      .string()
      .required(`${t('signup.errors.lastName.required')}`)
      .min(2, `${t('signup.errors.lastName.minLength')}`)
      .max(45, `${t('signup.errors.lastName.maxLength')}`)
      .matches(/^[A-Za-z]+$/, t('signup.errors.lastName.pattern')),
    userName: yup
      .string()
      .required(`${t('signup.errors.userName.required')}`)
      .min(4, `${t('signup.errors.userName.minLength')}`)
      .max(20, `${t('signup.errors.userName.maxLength')}`)
      .matches(/^\w+$/, t('signup.errors.userName.pattern')),
    email: yup
      .string()
      .required(t('signup.errors.email.required'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signup.errors.email.pattern')
      ),
    password: yup
      .string()
      .required(`${t('signup.errors.password.required')}`)
      .min(8, `${t('signup.errors.password.pattern')}`)
      .max(16, `${t('signup.errors.password.pattern')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('signup.errors.password.pattern')
      ),
    referralCode: yup
      .string()
      .min(4, `${t('signup.errors.userName.minLength')}`)
      .max(20, `${t('signup.errors.userName.maxLength')}`),
    confirmPassword: yup
      .string()
      .required(`${t('signup.errors.confirmPassword.required')}`)
      .oneOf(
        [yup.ref('password'), null],
        `${t('signup.errors.confirmPassword.match')}`
      )
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    resetField
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const showCodeField = watch('showCode')

  useEffect(() => {
    if (!showCodeField) {
      resetField('referrerCode')
    }
  }, [showCodeField])

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev)
  }

  const handleOpenLoginPopup = () => {
    handleShowRegister(false)
    handleShowLogin(true)
  }

  return (
    <>
      <Transition.Root show={showRegisterPopup} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          initialFocus={cancelButtonRef}
          onClose={() => handleShowRegister(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-popupBg-100 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[584px]'>
                  <div className='p-6 bg-dialogBg  bg-contain bg-no-repeat bg-center'>
                    <button
                      type='button'
                      className='absolute right-6 top-5 cursor-pointer'
                      onClick={() => handleShowRegister(false)}
                    >
                      <CloseIcon />
                    </button>
                    <div className=''>
                      <div className='mt-3 text-center'>
                        <Dialog.Title
                          as='h3'
                          className='text-[22px] font-semibold leading-6 text-royalNavy-200'
                        >
                          {t('signup.title')}
                        </Dialog.Title>

                        <div className='mt-6'>
                          <form
                            class=''
                            onSubmit={handleSubmit((data) =>
                              handleRegisterSubmit(data, reset)
                            )}
                          >
                            <div>
                              <div className='flex max-sm:flex-wrap gap-4'>
                                <div className='w-1/2 max-sm:w-full'>
                                  <div class='relative'>
                                    <input
                                      name='firstName'
                                      maxLength={50}
                                      autoFocus
                                      {...register('firstName')}
                                      type='text'
                                      id='outlined_error'
                                      aria-describedby='outlined_error_help'
                                      class='inputAutofill block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                      placeholder=' '
                                    />
                                    <label
                                      for='outlined_error'
                                      class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-autofill:px-2 peer-autofill:top-2 peer-autofill:scale-90 peer-autofill:-translate-y-4 rtl:peer-autofill:translate-x-1/4 rtl:peer-autofill:left-auto'
                                    >
                                      {t('signup.firstName')}
                                    </label>
                                  </div>
                                  <p
                                    id='outlined_error_help'
                                    class={
                                      errors &&
                                      errors?.firstName &&
                                      errors?.firstName?.message &&
                                      ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                    }
                                  >
                                    {errors?.firstName?.message}
                                  </p>
                                </div>

                                <div className='w-1/2 max-sm:w-full'>
                                  <div class='relative'>
                                    <input
                                      name='lastName'
                                      maxLength={50}
                                      autoFocus
                                      {...register('lastName')}
                                      type='text'
                                      id='outlined_error'
                                      aria-describedby='outlined_error_help'
                                      class='inputAutofill block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                      placeholder=' '
                                    />
                                    <label
                                      for='outlined_error'
                                      class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-autofill:px-2 peer-autofill:top-2 peer-autofill:scale-90 peer-autofill:-translate-y-4 rtl:peer-autofill:translate-x-1/4 rtl:peer-autofill:left-auto'
                                    >
                                      {t('signup.lastName')}
                                    </label>
                                  </div>
                                  <p
                                    id='outlined_error_help'
                                    class={
                                      errors &&
                                      errors?.lastName &&
                                      errors?.lastName?.message &&
                                      'text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                    }
                                  >
                                    {errors?.lastName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='flex max-sm:flex-wrap gap-4 my-6'>
                                <div className='w-1/2 max-sm:w-full'>
                                  <div class='relative'>
                                    <input
                                      name='email'
                                      maxLength={50}
                                      {...register('email')}
                                      type='email'
                                      id='outlined_error'
                                      aria-describedby='outlined_error_help'
                                      class='inputAutofill block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                      placeholder=' '
                                    />
                                    <label
                                      for='outlined_error'
                                      class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-autofill:px-2 peer-autofill:top-2 peer-autofill:scale-90 peer-autofill:-translate-y-4 rtl:peer-autofill:translate-x-1/4 rtl:peer-autofill:left-auto'
                                    >
                                      {t('signup.email')}
                                    </label>
                                  </div>
                                  <p
                                    id='outlined_error_help'
                                    class={
                                      errors &&
                                      errors?.email &&
                                      errors?.email?.message &&
                                      'text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                    }
                                  >
                                    {errors?.email?.message}
                                  </p>
                                </div>

                                <div className='w-1/2 max-sm:w-full'>
                                  <div class='relative'>
                                    <input
                                      name='userName'
                                      maxLength={50}
                                      autoFocus
                                      {...register('userName')}
                                      type='text'
                                      id='outlined_error'
                                      aria-describedby='outlined_error_help'
                                      class='inputAutofill peer-autofill:px-2 peer-autofill:top-2 peer-autofill:scale-90 peer-autofill:-translate-y-4 rtl:peer-autofill:translate-x-1/4 rtl:peer-autofill:left-auto block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                      placeholder=' '
                                    />
                                    <label
                                      for='outlined_error'
                                      class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-autofill:px-2 peer-autofill:top-2 peer-autofill:scale-90 peer-autofill:-translate-y-4 rtl:peer-autofill:translate-x-1/4 rtl:peer-autofill:left-auto'
                                    >
                                      {t('signup.username')}
                                    </label>
                                  </div>
                                  <p
                                    id='outlined_error_help'
                                    class={
                                      errors &&
                                      errors?.userName &&
                                      errors?.userName?.message &&
                                      'text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                    }
                                  >
                                    {errors?.userName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='flex max-sm:flex-wrap gap-4'>
                                <div className='w-1/2 max-sm:w-full'>
                                  <div class='relative'>
                                    <input
                                      type={showPassword ? 'text' : 'password'}
                                      name='password'
                                      maxLength={50}
                                      {...register('password')}
                                      id='outlined_error'
                                      aria-describedby='outlined_error_help'
                                      class='inputAutofill block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                      placeholder=' '
                                    />
                                    <button
                                      type='button'
                                      onClick={() => handleShowPassword()}
                                      className='absolute top-1/2 right-4 transform translate-y-[-50%]'
                                    >
                                      {showPassword ? (
                                        <ShowPasswordIcon className='h-7 w-7' />
                                      ) : (
                                        <HidePasswordIcon className='h-7 w-7' />
                                      )}
                                    </button>
                                    <label
                                      for='outlined_error'
                                      class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-autofill:px-2 peer-autofill:top-2 peer-autofill:scale-90 peer-autofill:-translate-y-4 rtl:peer-autofill:translate-x-1/4 rtl:peer-autofill:left-auto'
                                    >
                                      {t('signup.password')}
                                    </label>
                                  </div>
                                  <p
                                    id='outlined_error_help'
                                    class={
                                      errors &&
                                      errors?.password &&
                                      errors?.password?.message &&
                                      'text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                    }
                                  >
                                    {errors?.password?.message}
                                  </p>
                                </div>

                                <div className='w-1/2 max-sm:w-full'>
                                  <div class='relative'>
                                    <input
                                      name='confirmPassword'
                                      maxLength={50}
                                      {...register('confirmPassword')}
                                      type={showConfirmPassword ? 'text' : 'password'}
                                      id='outlined_error'
                                      aria-describedby='outlined_error_help'
                                      class='inputAutofill block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                      placeholder=' '
                                    />
                                    <button
                                      type='button'
                                      onClick={() =>
                                        handleShowConfirmPassword()
                                      }
                                      className='absolute top-1/2 right-4 transform translate-y-[-50%]'
                                    >
                                      {showConfirmPassword ? (
                                        <ShowPasswordIcon className='h-7 w-7' />
                                      ) : (
                                        <HidePasswordIcon className='h-7 w-7' />
                                      )}
                                    </button>
                                    <label
                                      for='outlined_error'
                                      class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-autofill:px-2 peer-autofill:top-2 peer-autofill:scale-90 peer-autofill:-translate-y-4 rtl:peer-autofill:translate-x-1/4 rtl:peer-autofill:left-auto'
                                    >
                                      {t('signup.confirmPassword')}
                                    </label>
                                  </div>
                                  <p
                                    id='outlined_error_help'
                                    class={
                                      errors &&
                                      errors?.confirmPassword &&
                                      errors?.confirmPassword?.message &&
                                      'text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                    }
                                  >
                                    {errors?.confirmPassword?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='mb-6 mt-4'>
                                <button
                                  type='submit'
                                  className='w-full bg-btnGrdient inline-block p-4 text-[18px] text-white rounded-lg capitalize leading-tight'
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <Loader type={LOADER.BUTTON} />
                                  ) : (
                                    t('signup.signup')
                                  )}
                                </button>
                                {/* <a href="#" className='w-full bg-btnGrdient inline-block p-4 text-[18px] text-white rounded-lg capitalize leading-tight'>Signup</a> */}
                              </div>

                              {
                                !fromComingSoon &&
                                <div className='border-t border-solid border-dustyGray-300 pt-6'>
                                <p className='text-dustyGray-100 text-base font-normal'>
                                  {t('signup.haveAccount')}?{' '}
                                  <button
                                    type='button'
                                    onClick={handleOpenLoginPopup}
                                    className='text-primaryColor-600 font-semibold'
                                  >
                                    {t('login')}
                                  </button>
                                </p>
                              </div>
                              }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Register
