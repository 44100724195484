import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { setLoader } from '../../redux-store/redux-slices/Users/user'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../../redux-store/redux/thunk/Auth/Auth'
import { useHistory, useLocation } from 'react-router-dom'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CloseIcon, HidePasswordIcon, ShowPasswordIcon } from '../../assets/image'
import { LOADER } from '../../utils/constants'
import Loader from '../../components/Loader'

const ResetPassword = ({ showResetPasswordModal, handleShowResetPassword }) => {
  const { t } = useTranslation('home')
  const { loading } = useSelector((state) => state.user)
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const cancelButtonRef = useRef(null)

  const onResetPasswordSuccess = () => {
    addToast(t('resetPassword.successMessage'), { appearance: 'success' })
    handleShowResetPassword(false)
    dispatch(setLoader(false))
    history.push('/lobby')
  }
  const OnResetPasswordError = (error) => {
    dispatch(setLoader(false))
    addToast(error?.[0]?.description || t('resetPassword.somethingWentWrong'), {
      appearance: 'error'
    })
  }
  const handleLoginSubmit = useCallback(
    async (data) => {
      dispatch(setLoader(true))
      const param = {
        id: pathname.split('/')[2],
        token: pathname.split('/')[3],
        password: data?.newPassword
      }
      dispatch(
        resetPassword({
          param,
          onResetPasswordSuccess,
          OnResetPasswordError,
          addToast
        })
      )
    },
    [onResetPasswordSuccess, OnResetPasswordError, dispatch]
  )

  const password = useRef(null) // Create a ref for the input field
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const loginValidationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required(`${t('signup.errors.newPassword.required')}`)
      .min(8, `${t('signup.errors.newPassword.minLength')}`)
      .max(16, `${t('signup.errors.newPassword.maxLength')}`),
    confirmPassword: yup
      .string()
      .required(`${t('Confirm password is required')}`)
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  })

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    mode: 'all'
  })

  useEffect(() => {
    if (password.current) {
      password.current.focus() // Set focus on the input field
    }
  }, [password.current])

  useEffect(() => {
    return () => {
      handleShowResetPassword(false)
    }
  }, [reset])

  const handleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev)
  }

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev)
  }

  return (
    <>
      <Transition.Root show={showResetPasswordModal} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          initialFocus={cancelButtonRef}
          onClose={() => handleShowResetPassword(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-popupBg-100 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full max-w-[472px]'>
                  <div className='p-6 bg-dialogBg'>
                    <button
                      type='button'
                      onClick={() => handleShowResetPassword(false)}
                      className='absolute right-6 top-5 cursor-pointer outline-none'
                    >
                      <CloseIcon />
                    </button>
                    <div className=''>
                      <div className='mt-3 text-center'>
                        <Dialog.Title
                          as='h3'
                          className='text-[22px] font-semibold leading-6 text-royalNavy-200'
                        >
                          {t('resetPassword.title')}
                        </Dialog.Title>

                        <div className='mt-6'>
                          <form
                            class=''
                            onSubmit={handleSubmit(handleLoginSubmit)}
                          >
                            <div>
                              <div class='relative'>
                                <input
                                  type={showNewPassword ? 'text' : 'password'}
                                  name='newPassword'
                                  maxLength={50}
                                  autoComplete='off'
                                  {...register('newPassword')}
                                  id='outlined_error'
                                  aria-describedby='outlined_error_help'
                                  class='block px-2.5 pb-2.5 pt-4 mt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                  placeholder=' '
                                />
                                <button
                                  type='button'
                                  onClick={() => handleShowNewPassword()}
                                  className='absolute top-1/2 right-4 transform translate-y-[-50%]'
                                >
                                  {showNewPassword ? (
                                    <ShowPasswordIcon className='h-7 w-7' />
                                  ) : (
                                    <HidePasswordIcon className='h-7 w-7' />
                                  )}
                                </button>
                                <label
                                  for='outlined_error'
                                  class='absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
                                >
                                  {t('signup.newPassword')}
                                </label>
                              </div>
                              <p
                                id='outlined_error_help'
                                class={
                                  errors &&
                                  errors?.newPassword &&
                                  errors?.newPassword?.message &&
                                  ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                }
                              >
                                {errors?.newPassword?.message}
                              </p>

                              <div class='relative'>
                                <input
                                  type={showConfirmPassword ? 'text' : 'password'}
                                  name='confirmPassword'
                                  maxLength={50}
                                  autoComplete='off'
                                  {...register('confirmPassword')}
                                  id='outlined_error'
                                  aria-describedby='outlined_error_help'
                                  class='block px-2.5 pb-2.5 pt-4 mt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                  placeholder=' '
                                />
                                <button
                                  type='button'
                                  onClick={() => handleShowConfirmPassword()}
                                  className='absolute top-1/2 right-4 transform translate-y-[-50%]'
                                >
                                  {showConfirmPassword ? (
                                    <ShowPasswordIcon className='h-7 w-7' />
                                  ) : (
                                    <HidePasswordIcon className='h-7 w-7' />
                                  )}
                                </button>
                                <label
                                  for='outlined_error'
                                  class='absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto'
                                >
                                  {t('signup.confirmPassword')}
                                </label>
                              </div>
                              <p
                                id='outlined_error_help'
                                class={
                                  errors &&
                                  errors?.confirmPassword &&
                                  errors?.confirmPassword?.message &&
                                  ' text-start mt-2 text-xs text-red-600 dark:text-red-400'
                                }
                              >
                                {errors?.confirmPassword?.message}
                              </p>

                              <div className='my-6'>
                                <button
                                  type='submit'
                                  className='w-full bg-btnGrdient inline-block p-4 text-[18px] text-white rounded-lg capitalize leading-tight'
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <Loader type={LOADER.BUTTON} />
                                  ) : (
                                    t('resetPassword.resetPassword')
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default ResetPassword
