import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis/index'

export const getResultListService = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'result/my-buckets', {}, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.table,
    params: params

  })
}
export const getBucketWinnerService = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'result/get-bucket-winners', {}, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.table,
    params: params

  })
}

export const getRosterDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'result/user-roster', data, {
    server: microServices.USER,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data

  })
}