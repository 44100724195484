import React, { useEffect, useState } from 'react'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import logoIcon from '../../assets/image/logo.png'
import landingImg from '../../assets/image/landing-player.png'
import dnpIcon from '../../assets/image/dnp.png'
import CustomSlider3d from '../../components/CustomSlider3d'
import Login from '../../containers/Login'
import Register from '../../containers/Register'
import ForgetPassword from '../../containers/ForgetPassword'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { resetPassword, verifyEmail } from '../../redux-store/redux/thunk/Auth/Auth'
import { CatchIcon, GuitarPick, ProfileIcon, StarCheck, TapIcon } from '../../assets/image'
import { getTopPicks } from '../../redux-store/redux/thunk/Player/Player'
import { getAuthToken, isEarlyBirdAuthenticated } from '../../utils/common-services/cookie.services'
import { getLobbyContent } from '../../redux-store/redux/thunk/gameSetting'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from '../../utils/constants'
import ResetPassword from '../../containers/ResetPassword'
import { useTranslation } from 'react-i18next'


const LandingPage = () => {
  const { t, i18n} = useTranslation('landingPage')
  const lang = i18n.language
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const { userId, verificationToken } = useParams()
  const isLoggedIn = !!getAuthToken()

  useEffect(() => {
    if (pathname.split('/')[1] === 'verify' && userId && verificationToken) {
      try {
        const param = { id: Number(userId), token: verificationToken }
        dispatch(verifyEmail({ param, addToast, history }))
      } catch (e) {
        addToast(e.message, { appearance: 'error' })
      }
    } else if(pathname.split('/')[1] === 'reset-password') {
      handleShowResetPassword(true)
    }
  }, [pathname])
  useEffect(() => {
    dispatch(getTopPicks())
    dispatch(getLobbyContent())
  }, [])
  const { topPicks } = useSelector(state => state.playerSlice)
  const { lobbyContent } = useSelector(state => state.gameSetting)
  const bottomHeading = lang == "en"?lobbyContent?.bottomHeading:lobbyContent?.bottomHeadingSp
  const dnpHeading = lang == "en"?lobbyContent?.dnpHeading:lobbyContent?.dnpHeadingSp

  const dynamicContentHeading = lang == "en"?lobbyContent?.dynamicContentHeading:lobbyContent?.dynamicContentHeadingSp


  const heading = lang == "en"?lobbyContent?.heading:lobbyContent?.headingSp

  const howItWorksDescription = lang == "en"?lobbyContent?.howItWorksDescription:lobbyContent?.howItWorksDescriptionSp

 

  let dnpContent = null
  if (lobbyContent?.dnpContent) {
    dnpContent = lang == "en"?JSON.parse(lobbyContent?.dnpContent):JSON.parse(lobbyContent?.dnpContentSp)
  }
  let dynamicContent = null
  if (lobbyContent?.dynamicContent) {
    dynamicContent = lang == "en"?JSON.parse(lobbyContent?.dynamicContent):JSON.parse(lobbyContent?.dynamicContentSp)
  }
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showRegisterPopup, setShowRegisterPopup] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)

  const handleShowResetPassword = (value) => {
    setShowResetPasswordModal(value)
  }

  const handleShowLogin = (value) => {
    setShowLoginModal(value)
  }

  const handleShowRegister = (value) => {
    setShowRegisterPopup(value)
  }

  const handleShowForgotPassword = (value) => {
    setShowForgotPassword(value)
  }

  useEffect(() => {
    Aos.init({
      duration: 2000,
      disable: window.innerWidth < 1280,
    });
  }, []);
  const isEarlyBirdVerified = isEarlyBirdAuthenticated()
  return (
    <>
      <div className='bg-heroPattern bg-fixed bg-center bg-no-repeat bg-cover min-h-[calc(100dvh_-_505px)] px-4'>

        {/* ---- Hero Section ---- */}
        <div className='max-w-[1257px] mx-auto flex gap-[40px] justify-between items-center pt-[95px] max-md:pt-10 max-lg:justify-center'>
          <div data-aos="fade-right" className='xl:max-w-[519px] max-md:max-w-full text-center flex flex-col align-middle items-center justify-center gap-[24px]'>
            <img src={logoIcon} alt="logo" className='xl:max-w-[470px] max-md:max-w-[300px] max-sm:max-w-[192px] max-md:w-full max-md:h-auto ' />

            <div className='hidden max-lg:block'>
              <img className='maskImg max-w-[699px] max-md:max-w-full max-h-[408px] max-md:max-h-auto object-contain [-webkit-mask-image: linear-gradient(#000, rgba(0,0,0,0.0));]' src={(lobbyContent?.bannerImage === 'null' || lobbyContent?.bannerImage === null ) ? landingImg : lobbyContent?.bannerImage} alt="logo" />
            </div>
            <h1 className=' text-[40px] max-xl:text-3xl max-sm:text-2xl text-white leading-normal font-semibold'>"{heading ?? t('title')}"</h1>
            {!isLoggedIn ?
              <div>
                {
                  !isEarlyBirdVerified &&
                  <button onClick={() => setShowRegisterPopup(true)} data-modal-target="default-modal" data-modal-toggle="default-modal" href="#_" class="leading-tight px-[56px] py-[12px] text-[18px] bg-btnGrdient rounded-3xl overflow-hidden group relative hover:bg-btnGrdient-to-r hover:from-btnGrdient hover:to-btnGrdient text-white transition-all ease-out duration-300">
                  <span class="absolute blur-2xl right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white rotate-12 group-hover:-translate-x-60 te-x-44 ease animate-btnMotion"></span>
                  <span class="relative uppercase leading-tight">{t('signUpNow')}</span>
                </button>
                }
                <p className='text-dustyGray-100 text-base leading-normal'>{t('haveAccount')}? <button onClick={() => setShowLoginModal(true)} className='text-primaryColor-600 font-semibold'>{t('logIn')}</button></p>
              </div>
              : <div>
                <button onClick={() => history.push('/lobby')} data-modal-target="default-modal" data-modal-toggle="default-modal" href="#_" class="leading-tight px-[56px] py-[12px] text-lg max-sm:text-sm bg-btnGrdient rounded-3xl overflow-hidden group relative hover:bg-btnGrdient-to-r hover:from-btnGrdient hover:to-btnGrdient text-white transition-all ease-out duration-300">
                  <span class="absolute blur-2xl right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white rotate-12 group-hover:-translate-x-60 te-x-44 ease animate-btnMotion"></span>
                  <span class="relative uppercase leading-tight">{t('returnToLobby')}</span>
                </button>
              </div>
            }
          </div>
          <div data-aos="fade-left" className='lg:block max-lg:hidden'>
            <img className='maskImg xl:max-w-[699px] xl:max-h-[408px] object-contain [-webkit-mask-image: linear-gradient(#000, rgba(0,0,0,0.0));]' src={(lobbyContent?.bannerImage === 'null' || lobbyContent?.bannerImage === null ) ? landingImg : lobbyContent?.bannerImage} alt="logo" />
          </div>
        </div>

        {/* ---- Buttons Group Section---- */}
        <div data-aos="fade-up" className='max-w-[1200px] mx-auto text-center mt-[131px] max-sm:mt-14 mb-[140px] max-md:my-16'>
          <div data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <h2 className='text-3xl max-lg:text-2xl max-md:text-lg text-white leading-tight uppercase font-semibold'>{t('howItWorks')}</h2>
            <p className='text-lg max-lg:text-base max-md:text-sm text-primaryColor-800 font-normal mb-[18px]'>{howItWorksDescription ?? t('howItWorksText')}</p>
            <Link to={ROUTE_PATHS.HOWTOPLAY} className='text-lg max-lg:text-base max-md:text-sm text-primaryColor-150 capitalize font-normal underline'>{t('readMore')}</Link>
          </div>
          <div className='flex gap-6 max-lg:gap-3 mt-[40px] flex-wrap justify-center'>
            {
              !isLoggedIn && !isEarlyBirdVerified ?
                <button onClick={() => setShowRegisterPopup(true)} className='text-[22px] max-md:text-base max-sm:text-sm w-full text-white border-[1px] border-solid border-primaryColor-800 flex justify-start items-center max-w-[282px] max-md:max-w-[200px] p-4 max-sm:p-3 rounded-[120px] shadow-btnShadow'>
                  <span className='mr-2 p-3 max-md:p-1.5 bg-btnGrdient rounded-[120px]'>
                    <ProfileIcon className='max-md:w-5 max-md:h-5'/>
                  </span>
                  {t('signUp')}
                </button>
                : <button onClick={() => history.push('/lobby')} className='text-[22px] max-md:text-base max-sm:text-sm w-full text-white border-[1px] border-solid border-primaryColor-800 flex justify-start items-center max-w-[282px] max-md:max-w-[200px] p-4 max-sm:p-3 rounded-[120px] shadow-btnShadow'>
                  <span className='mr-2 p-3 max-md:p-1.5 bg-btnGrdient rounded-[120px]'>
                    <ProfileIcon className='max-md:w-5 max-md:h-5'/>
                  </span>
                  {t('lobby')}
                </button>
            }
            <Link to='pick-your-contest' className='text-[22px] max-md:text-base max-sm:text-sm w-full text-white border-[1px] border-solid border-primaryColor-800 flex justify-start items-center max-w-[282px] max-md:max-w-[200px] p-4 max-sm:p-3 rounded-[120px] shadow-btnShadow'>
              <span className='mr-2 p-3 max-md:p-1.5 bg-btnGrdient rounded-[120px]'>
                <GuitarPick className='max-md:w-5 max-md:h-5'/>
              </span>
              {t('pickYourContest')}
            </Link>
            <Link to='build-your-roster'className='text-[22px] max-md:text-base max-sm:text-sm w-full text-white border-[1px] border-solid border-primaryColor-800 flex justify-start items-center max-w-[282px] max-md:max-w-[200px] p-4 max-sm:p-3 rounded-[120px] shadow-btnShadow'>
              <span className='mr-2 p-3 max-md:p-1.5 bg-btnGrdient rounded-[120px]'>
                <CatchIcon className='max-md:w-5 max-md:h-5'/>
              </span>
              {t('buildYourRoster')}
            </Link>
            <Link to='view-and-submit' className='text-[22px] max-md:text-base max-sm:text-sm w-full text-white border-[1px] border-solid border-primaryColor-800 flex justify-start items-center max-w-[282px] max-md:max-w-[200px] p-4 max-sm:p-3 rounded-[120px] shadow-btnShadow'>
              <span className='mr-2 p-3 max-md:p-1.5 bg-btnGrdient rounded-[120px]'>
                <TapIcon className='max-md:w-5 max-md:h-5'/>
              </span>
              {t('viewAndSubmit')}
            </Link>
          </div>
        </div>

        {/* ---- Slider Section---- */}
        <div data-aos="fade-up" className='max-w-[1200px] mx-auto text-center max-md:mb-12'>
          <h2 className='text-3xl max-lg:text-2xl max-md:text-lg text-white leading-tight uppercase font-semibold'>{t('topPicks')}</h2>
          <CustomSlider3d data={topPicks?.rows ?? []} />
        </div>

        {/* ---- DNP Insurance Section 1 ---- */}
        <div className='max-w-[1028px] mx-auto flex items-center justify-between max-lg:justify-center mb-[150px] max-lg:mb-[35px] max-sm:mb-5 relative'>

          <div data-aos="fade-right" className='w-[calc(100%_-_202px)] max-lg:w-full bg-primaryColor-200 rounded-[18px] p-10 max-md:p-5 max-sm:p-4'>
            <div className='w-[calc(100%_-_10vw)] max-lg:w-[calc(100%_-_25vw)] max-md:w-full'>
              <h3 className='text-3xl max-lg:text-2xl max-md:text-lg uppercase text-white font-semibold max-md:w-[calc(100%_-_25vw)]'>{dnpHeading ?? t('tryDnpTitle')}</h3>

              {
                dnpContent ?
                  <ul className='py-[24px] flex flex-col gap-2'>
                    {
                      dnpContent.map(item => {
                        return (
                          <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                            <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                              <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px] object-contain' /></div> {item}
                          </li>
                        )
                      })
                    }
                  </ul>
                  :
                  <ul className='py-[24px] flex flex-col gap-2'>
                    <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                    <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                      <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[15px] max-lg:md-[15px] object-contain' /> 
                      </div>
                      {t('tryDnpText1')}
                    </li>
                    <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                    <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                      <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[15px] max-lg:md-[15px] object-contain' /> 
                      </div> 
                      {t('tryDnpText2')}
                    </li>
                    <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                    <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                      <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[15px] max-lg:md-[15px] object-contain' /> 
                      </div>
                      {t('tryDnpText3')}
                    </li>
                    <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                    <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                      <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[15px] max-lg:md-[15px] object-contain' /> 
                      </div>
                      {t('tryDnpText4')}
                    </li>
                  </ul>
              }

              <Link to={ROUTE_PATHS.DNPINSURANCE} class="text-lg max-lg:text-base leading-tight font-normal capitalize border-primaryColor-500 border-solid border px-[40px] max-lg:px-[20px] py-4 max-lg:py-2 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]">

                <span class="absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]"></span>
                {t('readMore')}
              </Link>
            </div>

          </div>
          <div data-aos="fade-left" className='absolute right-0 top-0 max-lg:top-[-66px] max-lg:w-[clamp(117px,_25vw,_360px)] lg:w-[360px] aspect-[90_/_101]'>
            <img src={lobbyContent?.dnpImage ?? dnpIcon} alt="" className='w-full h-full object-contain' />
          </div>
        </div>

        {/* ---- DNP Insurance Section 2 ---- */}
        {
          dynamicContentHeading &&
        <div className='max-w-[1028px] mx-auto flex items-center justify-end max-lg:justify-center mb-[58px] max-lg:mb-[35px] max-sm:mb-5 max-lg:mt-28 relative'>
            
          <div  className='absolute left-0 top-0 max-lg:top-[-66px] z-[2] max-lg:w-[clamp(117px,_25vw,_360px)] lg:w-[360px] aspect-[90_/_101]'>
            <img src={lobbyContent?.dynamicContentImage ?? dnpIcon} alt="" className='w-full h-full object-contain' />
          </div>

          <div className='w-[calc(100%_-_202px)] max-lg:w-full bg-primaryColor-200 rounded-[18px] p-10 max-md:p-5 max-sm:p-4 flex justify-end'>
            <div className='w-[75%] max-lg:w-[63%] max-md:w-[calc(100%_-_200px)] max-tabletscreen:w-[calc(100%_-_125px)] max-smallscreen:w-full'>
              <h3 className='text-3xl max-lg:text-2xl max-md:text-lg uppercase text-white font-semibold max-smallscreen:pl-[27vw]'>{dynamicContentHeading}</h3>
                  <ul className='py-[24px] flex flex-col gap-2'>
                    {
                      dynamicContent && dynamicContent.map(item => {
                        return (
                          <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                            <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                              <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px] object-contain' /></div> {item}
                          </li>
                        )
                      })
                    }
                  </ul>
              
                  {/* // :
                  // <ul className='py-[24px] flex flex-col gap-2'>
                  //   <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                  //   <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                  //     <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[15px] max-lg:md-[15px] object-contain' /> 
                  //     </div>
                  //     {t('tryDnpText1')}
                  //   </li>
                  //   <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                  //   <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                  //     <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[15px] max-lg:md-[15px] object-contain' /> 
                  //     </div> 
                  //     {t('tryDnpText2')}
                  //   </li>
                  //   <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                  //   <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                  //     <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[15px] max-lg:md-[15px] object-contain' /> 
                  //     </div>
                  //     {t('tryDnpText3')}
                  //   </li>
                  //   <li className='text-[22px] max-lg:text-base max-sm:text-xs text-white flex items-center gap-4 max-md:gap-2 font-medium list-none'>
                  //   <div className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[8px] max-lg:md-[8px]'>
                  //     <StarCheck className='w-[25px] h-[25px] max-lg:w-[18px] max-lg:h-[18px] max-md:w-[15px] max-lg:md-[15px] object-contain' /> 
                  //     </div>
                  //     {t('tryDnpText4')}
                  //   </li>
                  // </ul> */}
              

              <Link to={ROUTE_PATHS.DNPINSURANCE} class="text-lg max-lg:text-base leading-tight font-normal capitalize border-primaryColor-500 border-solid border px-[40px] max-lg:px-[20px] py-4 max-lg:py-2 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]">

                <span class="absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]"></span>
                {t('readMore')}
              </Link>
            </div>

          </div>
          
        </div>
        }

        {/* ---- Slogan Section---- */}
        <div className='max-w-[767px] mx-auto text-[38px] max-xl:text-3xl max-lg:text-2xl max-sm:text-base font-semibold text-center text-white leading-tight pb-[100px] pt-14 max-lg:pt-[35px] max-lg:pb-[50px] max-sm:pt-5'>
          <p>{bottomHeading}</p>
        </div>

        {/* <ModalCustom/> */}
        <Login showLoginModal={showLoginModal} handleShowLogin={handleShowLogin} handleShowRegister={handleShowRegister} handleShowForgotPassword={handleShowForgotPassword} />
        <Register showRegisterPopup={showRegisterPopup} handleShowRegister={handleShowRegister} handleShowLogin={handleShowLogin} />
        <ForgetPassword showForgotPassword={showForgotPassword} handleShowForgotPassword={handleShowForgotPassword} handleShowLogin={handleShowLogin} />
        <ResetPassword showResetPasswordModal={showResetPasswordModal} handleShowResetPassword={handleShowResetPassword} />

      </div>

    </>
  )
}

export default LandingPage