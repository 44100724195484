import React from 'react'
import { Redirect } from 'react-router-dom'

const PrivateRoute = ({ Component, isAuthenticated, child,redirectToHome }) => {
  if(redirectToHome)
  return <Redirect to={{ pathname: '/' }} />
  return isAuthenticated
    ? (
      <Component child={child} />
      )
    : <Redirect to={{ pathname: '/' }} />
}

export default PrivateRoute
