import { LOADER_HANDLER_TYPES, METHOD_TYPES } from '../../utils/constants/index'
import axiosInstance, { microServices } from '../apis/index'

export const getBannerService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-banners',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
    }
  )
}
export const getCountryService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-countries',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
    }
  )
}
export const getStateService = (countryId) => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-states',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table,
      params: { countryId }
    }
  )
}
export const getCityService = (stateId) => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-cities',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table,
      params: { stateId }
    }
  )
}
export const getAllCmsService = (stateId) => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-all-cms',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table,
      params: { stateId }
    }
  )
}
export const getAllSocialLinksService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-settings',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
      //params: { stateId }
    }
  )
}
export const setContactUsService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'system/contact-us', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const getLobbyContentService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-lobby-page',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
    }
  )
}
export const getDnpPageDataService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-dnp-page',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
    }
  )
}
export const getWebSettingsService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-web-settings',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
    }
  )
}
export const getBonusDetailsService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'bonus/get-active-joining-bonus',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
    }
  )
}

export const getTimeSettingService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-time-settings',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
    }
  )
}

export const getLotteryInfoService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    'system/get-lottery-info',
    {},
    {
      server: microServices.USER_URL,
      loader: LOADER_HANDLER_TYPES.table
    }
  )
}
export const getWithdrawRequestService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'payment/withdrawal-paypal', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getWithdrawalListService = (data) => {
  return axiosInstance(
    METHOD_TYPES.get,
    'withdraw-request/all-withdraw-requests',
    data,
    {
      server: microServices.USER,
      loader: LOADER_HANDLER_TYPES.submit,
      params: data
    }
  )
}
export const withdrawRequestCancelService = (data) => {
  return axiosInstance(
    METHOD_TYPES.get,
    'withdraw-request/cancel',
    data,
    {
      server: microServices.USER,
      loader: LOADER_HANDLER_TYPES.submit,
      params: data
    }
  )
}
