import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { forgetPassword } from '../../redux-store/redux/thunk/Auth/Auth'
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { CloseIcon } from '../../assets/image'
import { LOADER, ROUTE_PATHS } from '../../utils/constants'
import Loader from '../../components/Loader'
import { Link } from 'react-router-dom'

const ForgetPassword = ({
  showForgotPassword,
  handleShowForgotPassword,
  handleShowLogin
}) => {
  const { t } = useTranslation('home')

  const { addToast } = useToasts()
  const { loading } = useSelector((state) => state.user)

  const cancelButtonRef = useRef(null)

  const dispatch = useDispatch()

  const onForgetPasswordSuccess = () => {
    handleShowForgotPassword(false)
  }

  const handleForgetPasswordSubmit = async (ForgetPasswordDetails) => {
    const { userNameOrEmail } = ForgetPasswordDetails
    if (userNameOrEmail) {
      dispatch(
        forgetPassword({ userNameOrEmail, addToast, onForgetPasswordSuccess })
      )
    } else {
      addToast(t('resetPassword.wentWrong'), { appearance: 'error' })
    }
  }

  const schema = yup.object().shape({
    userNameOrEmail: yup
      .string()
      .required(t('signup.errors.email.required'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signup.errors.email.pattern')
      )
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  useEffect(() => {
    return () => {
      handleShowForgotPassword(false)
    }
  }, [reset])

  const handleOpenLogin = () => {
    handleShowForgotPassword(false)
    handleShowLogin(true)
  }

  return (
    <Transition.Root show={showForgotPassword} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-50'
        initialFocus={cancelButtonRef}
        onClose={() => handleShowForgotPassword(false)}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-popupBg-100 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full max-w-[472px]'>
                <div className='p-6 bg-dialogBg bg-dialogBg  bg-contain bg-no-repeat bg-center'>
                  <button
                    onClick={() => handleShowForgotPassword(false)}
                    className='absolute right-6 top-5 cursor-pointer'
                  >
                    <CloseIcon />
                  </button>
                  <div className=''>
                    <div className='mt-3 text-center'>
                      <Dialog.Title
                        as='h3'
                        className='text-[22px] font-semibold leading-6 text-royalNavy-200'
                      >
                        {t('forgotPassword.title')}
                      </Dialog.Title>
                      <p className='text-dustyGray-100 text-sm font-normal pt-2'>
                        {t('forgotPassword.description')}
                      </p>

                      <div className='mt-6'>
                        <form
                          class=''
                          onSubmit={handleSubmit(handleForgetPasswordSubmit)}
                        >
                          <div>
                            <div class='relative'>
                              <input
                                type='email'
                                name='userNameOrEmail'
                                maxLength={50}
                                {...register('userNameOrEmail')}
                                id='outlined_error'
                                aria-describedby='outlined_error_help'
                                class='inputAutofill block px-2.5 pb-2.5 pt-4 w-full text-[14px] text-dustyGray-200 bg-transparent rounded-lg border border-dustyGray-300 border-solid  appearance-none focus:outline-none focus:ring-0 peer'
                                placeholder=' '
                              />
                              <label
                                for='outlined_error'
                                class='pointer-events-none absolute text-sm text-dustyGray-200 duration-200 transform -translate-y-4 scale-90 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-autofill:px-2 peer-autofill:top-2 peer-autofill:scale-90 peer-autofill:-translate-y-4 rtl:peer-autofill:translate-x-1/4 rtl:peer-autofill:left-auto'
                              >
                                {t('forgotPassword.email')}
                              </label>
                            </div>
                            <p
                              id='outlined_error_help'
                              class={
                                errors &&
                                errors.userNameOrEmail &&
                                errors?.userNameOrEmail?.message &&
                                'text-start mt-2 text-xs text-red-600 dark:text-red-400'
                              }
                            >
                              {errors?.userNameOrEmail?.message}
                            </p>

                            <div className='mt-6'>
                              <button
                                type='submit'
                                className='w-full bg-btnGrdient inline-block p-4 text-[18px] text-white rounded-lg capitalize leading-tight'
                                disabled={loading}
                              >
                                {loading ? (
                                  <Loader type={LOADER.BUTTON} />
                                ) : (
                                  t('forgotPassword.resetPassword')
                                )}
                              </button>
                            </div>

                            <div className='text-center pt-2.5 mb-4'>
                              <button
                                onClick={handleOpenLogin}
                                className='text-primaryColor-600 text-base '
                              >
                                {t('forgotPassword.returnToLogin')}
                              </button>
                            </div>

                            <div className='border-t border-solid border-dustyGray-300 pt-6'>
                              <p className='text-dustyGray-100 text-sm font-normal leading-4'>
                                {t('forgotPassword.footerText')}
                              </p>
                              <Link
                                className='text-primaryColor-600 text-sm leading-4 font-semibold'
                                to={ROUTE_PATHS.CONTACTUS}
                              >
                                {t('forgotPassword.contactOurSupportTeam')}
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ForgetPassword
