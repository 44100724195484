import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthToken } from '../../utils/common-services/cookie.services'
import { withRouter, Link } from 'react-router-dom'
import CountdownTimer from '../CountdownTimer'
import logo from '../../assets/image/main-logo.png'
import plusIcon from '../../assets/image/plus.png'
import userImg from '../../assets/image/user.png'
import UserDropdown from './UserDropdown'
import Depositdropdown from './Depositdropdown'
import userIcon from '../../assets/image/usericon.png'
import Wallet from '../../containers/Wallet'
import Cashier from './Cashier'
import Login from '../../containers/Login'
import Register from '../../containers/Register'
import ForgetPassword from '../../containers/ForgetPassword'

const Header = (props) => {
  const { t } = useTranslation('header')
  const token = !!getAuthToken()
  const [activeTab, setActiveTab] = useState('')
  const { userDetails } = useSelector((state) => state.userSlice)
  const [showWalletModal, setShowWalletModal] = useState(false)
  const [activeTabCashier, setActiveTabCashier] = useState('deposit')

  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showRegisterPopup, setShowRegisterPopup] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  const handleShowLogin = (value) => {
    setShowLoginModal(value)
  }

  const handleShowRegister = (value) => {
    setShowRegisterPopup(value)
  }

  const handleShowForgotPassword = (value) => {
    setShowForgotPassword(value)
  }

  useEffect(() => {
    setActiveTab(location.pathname?.split('/')[1])
  }, [location.pathname])

  const handleShowWallet = (value) => {
    setShowWalletModal(value)
  }

  return (
    <>
      <header className='px-6 max-md:px-3 max-sm:px-2 py-4 bg-primaryColor-200'>
        <div className='flex justify-between items-center max-w-[1440px] mx-auto'>
          <div className=''>
            <Link to='/'>
              <img
                className='max-w-[245px] max-xl:max-w-[200px] max-lg:max-w-[175px] max-sm:max-w-[100px] max-h-[80px] max-smallscreen:w-[75px] object-contain'
                src={logo}
                alt='logo'
              />
            </Link>
          </div>

          {token && <><div className='flex gap-2 items-center'>
            <CountdownTimer t={t} />
          </div>

          <div className='flex items-center gap-[42px] max-xl:gap-4'>
            <div className='bg-primaryColor-100 rounded-[40px] p-2 flex items-center gap-3 max-lg:hidden'>
              <Depositdropdown />
              <Cashier setShowWalletModal={setShowWalletModal} t={t} />
            </div>
            <div className='flex items-center gap-3'>
              <div
                className={`${
                  userDetails?.profileImageUrl
                    ? 'max-lg:hidden'
                    : 'max-lg:hidden   p-2 max-smallscreen:p-1.5 border border-solid activeProTab rounded-full inline-block w-[42px] max-sm:w-[34px] max-smallscreen:w-[28px] h-[42px] max-sm:h-[34px] max-smallscreen:h-[28px]'
                }`}
              >
                <img
                  className={`${
                    userDetails?.profileImageUrl
                      ? 'w-[42px] max-sm:w-[34px] max-smallscreen:w-[28px] h-[42px] max-sm:h-[34px] max-smallscreen:h-[28px] object-cover rounded-full'
                      : ''
                  }`}
                  src={
                    userDetails?.profileImageUrl
                      ? userDetails?.profileImageUrl
                      : userIcon
                  }
                  alt='user image'
                />
              </div>
              <div className='flex items-center justify-between gap-2'>
                <UserDropdown setShowWalletModal={setShowWalletModal} t={t} />
              </div>
            </div>
          </div></>}
          {!token && 
          <div className='flex justify-center items-center gap-4 mt-8'>
          <button
            onClick={()=>setShowLoginModal(true)}
            type='button'
            className='text-lg w-[120px] leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 py-4 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'
          >
            <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
            {t('login')}
          </button>

          <button
            onClick={()=>setShowRegisterPopup(true)}
            className='text-lg w-[120px] leading-none font-normal capitalize border-primaryColor-500 border-solid border px-6 py-4 rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
          >
            <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
            {t('register')}
          </button>
        </div>}
        </div>
      </header>
      {token && 
      <>
      <div className='px-[80px] max-lg:px-8 max-sm:px-2 bg-primaryColor-100'>
        <ul className='flex items-center justify-between max-w-[1440px] mx-auto overflow-x-auto'>
          <li>
            <Link
              className={`text-xl max-lg:text-lg max-md:text-base max-sm:text-xs px-2 py-3 text-primaryColor-800 inline-block whitespace-nowrap ${
                activeTab == 'lobby' ? 'menuActiveBorder' : ''
              }`}
              to='/lobby'
            >
              {t('lobby')}
            </Link>
          </li>
          <li>
            <Link
              className={`text-xl max-lg:text-lg max-md:text-base max-sm:text-xs px-2 py-3 text-primaryColor-800 inline-block whitespace-nowrap ${
                activeTab == 'player-spotlight' ? 'menuActiveBorder' : ''
              }`}
              to='/player-spotlight'
            >
              {t('playerSpotlight')}
            </Link>
          </li>
          <li>
            <Link
              className={`text-xl max-lg:text-lg max-md:text-base max-sm:text-xs px-2 py-3 text-primaryColor-800 inline-block whitespace-nowrap ${
                activeTab == 'player-detail' ? 'menuActiveBorder' : ''
              }`}
              to='/player-detail'
            >
              {t('playerCareerLogs')}
            </Link>
          </li>
          <li>
            <Link
              className={`text-xl max-lg:text-lg max-md:text-base max-sm:text-xs px-2 py-3 text-primaryColor-800 inline-block whitespace-nowrap ${
                activeTab == 'today-result' ? 'menuActiveBorder' : ''
              }`}
              to='/today-result'
            >
              {t('results')}
            </Link>
          </li>
          <li>
            <Link
              className={`text-xl max-lg:text-lg max-md:text-base max-sm:text-xs px-2 py-3 text-primaryColor-800 inline-block whitespace-nowrap ${
                activeTab == 'dnp-insurance' ? 'menuActiveBorder' : ''
              }`}
              to='/dnp-insurance'
            >
              {t('dnpInsurance')}
            </Link>
          </li>
        </ul>
      </div>
      <Wallet
        showWalletModal={showWalletModal}
        handleShowWallet={handleShowWallet}
        activeTabCashier={activeTabCashier}
        setActiveTabCashier={setActiveTabCashier}
      />
      </>}

        <Login showLoginModal={showLoginModal} handleShowLogin={handleShowLogin} handleShowRegister={handleShowRegister} handleShowForgotPassword={handleShowForgotPassword} />
        <Register showRegisterPopup={showRegisterPopup} handleShowRegister={handleShowRegister} handleShowLogin={handleShowLogin} />
        <ForgetPassword showForgotPassword={showForgotPassword} handleShowForgotPassword={handleShowForgotPassword} handleShowLogin={handleShowLogin} />
    </>
  )
}

export default React.memo(withRouter(Header))
