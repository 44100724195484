import React, { useRef } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import RosterSection from '../RosterSection'
import {CloseIcon} from '../../../../assets/image/index'

const RosterSectionPopup = ({
  showRosterSectionPopup,
  handleShowRosterSection,
  bucketDetails,
  remainingSalaryCap,
  fullRosterDnp,
  rosterPositionList,
  rosterPlayerList,
  handlePerPlayerDnp,
  handleRosterPlayerDelete,
  handleFullRosterDnp,
  dnpInsuranceAmount,
  handleViewRoster
}) => {
  const cancelButtonRef = useRef(null)
  return (
    <>
      <Transition.Root show={showRosterSectionPopup} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-50'
          initialFocus={cancelButtonRef}
          onClose={() => handleShowRosterSection(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-popupBg-100 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full justify-center p-4 text-center items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative shadow-dialogShadow transform overflow-hidden rounded-lg bg-primaryColor-200 text-left transition-all sm:my-8 w-full max-w-[550px] p-2 pt-10'>
                  <RosterSection
                    bucketDetails={bucketDetails}
                    remainingSalaryCap={remainingSalaryCap}
                    fullRosterDnp={fullRosterDnp}
                    rosterPositionList={rosterPositionList}
                    rosterPlayerList={rosterPlayerList}
                    handlePerPlayerDnp={handlePerPlayerDnp}
                    handleRosterPlayerDelete={handleRosterPlayerDelete}
                    handleFullRosterDnp={handleFullRosterDnp}
                    dnpInsuranceAmount={dnpInsuranceAmount}
                    handleViewRoster={handleViewRoster}
                  />
                  <CloseIcon onClick={()=>handleShowRosterSection(false)}  className='absolute top-1 right-1 [&>path]:fill-white' />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default RosterSectionPopup
