import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ArrowDown, CalendarIcon } from '../../assets/image'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { PAGINATION_LIMIT } from '../../utils/constants'
import Pagination from '../../components/Pagination'
import { moment } from '../../config/moment'
import {
  getWithdrawalList,
  withdrawRequestCancel
} from '../../redux-store/redux/thunk/gameSetting'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { dateTimeFormat } from '../../utils/common-functions'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'

const Withdrawal = () => {
  const { t } = useTranslation('home')
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: t('all')
  })
  const [selectedDate, setSelectedDate] = useState(null)
  const dispatch = useDispatch()
  const [currentOffset, setCurrentOffset] = useState(0)
  const [showPagination, setShowPagination] = useState(false)
  const [showNextBtn, setShowNextBtn] = useState(true)
  const { withdrawalList } = useSelector((state) => state.gameSetting)
  const { addToast } = useToasts()
  const [loader, setLoader] = useState(false)

  const onWithdrawListSuccess = () => {
    setLoader(false)
  }

  const onWithdrawListError = () => {
    setLoader(false)
  }

  useEffect(async () => {
    setLoader(true)
    const params = {
      limit: PAGINATION_LIMIT,
      offset: currentOffset
    }
    dispatch(
      getWithdrawalList({
        params,
        onWithdrawListSuccess,
        onWithdrawListError,
        addToast
      })
    )
  }, [])

  useEffect(() => {
    if (withdrawalList?.rows?.length || currentOffset > 0) {
      setShowPagination(true)
      if (withdrawalList?.rows?.length < PAGINATION_LIMIT) {
        setShowNextBtn(true)
      } else {
        setShowNextBtn(false)
      }
    } else {
      setShowPagination(false)
    }
  }, [withdrawalList])

  const onPageChange = async (offsetData) => {
    setLoader(true)
    const params =
      selectedOption?.value === ''
        ? {
            limit: PAGINATION_LIMIT,
            offset: offsetData
          }
        : {
            limit: PAGINATION_LIMIT,
            offset: offsetData,
            status: selectedOption?.value
          }
    dispatch(
      getWithdrawalList({
        params,
        onWithdrawListSuccess,
        onWithdrawListError,
        addToast
      })
    )

    setCurrentOffset(offsetData)
  }

  const handleWithdrawalList = () => {
    setLoader(true)
    const date =
      selectedDate === null ? '' : moment(selectedDate).format('YYYY-MM-DD')
    const params =
      selectedOption?.value === ''
        ? {
            limit: PAGINATION_LIMIT,
            offset: currentOffset,
            transactionDate: date
          }
        : {
            limit: PAGINATION_LIMIT,
            offset: currentOffset,
            status: selectedOption?.value,
            transactionDate: date
          }
    dispatch(
      getWithdrawalList({
        params,
        onWithdrawListSuccess,
        onWithdrawListError,
        addToast
      })
    )
  }

  const handleReset = () => {
    setLoader(true)
    setSelectedOption({ value: '', label: t('all') })
    setSelectedDate(null)
    const params = {
      limit: PAGINATION_LIMIT,
      offset: currentOffset,
      transactionDate: ''
    }
    dispatch(
      getWithdrawalList({
        params,
        onWithdrawListSuccess,
        onWithdrawListError,
        addToast
      })
    )
  }

  const handleDateSelect = (e) => {
    setSelectedDate(e)
  }

  const onSuccess = () => {
    setLoader(true)
    const params = {
      limit: PAGINATION_LIMIT,
      offset: currentOffset
    }
    dispatch(
      getWithdrawalList({
        params,
        onWithdrawListSuccess,
        onWithdrawListError,
        addToast
      })
    )
  }

  const handleCancelWithdrawRequest = (item) => {
    const params = {
      withdrawRequestId: item?.id,
      status: 'pending'
    }
    dispatch(withdrawRequestCancel({ params, addToast, onSuccess }))
  }

  const options = [
    { value: '', label: t('all') },
    { value: 'pending', label: t('pending') },
    { value: 'rejected', label: t('rejected') },
    { value: 'approved', label: t('approved') },
    { value: 'cancelled', label: t('cancelled') }
  ]

  return (
    <div className='max-w-[1320px] mx-auto pt-10 pb-[70px] min-h-[calc(100dvh_-_505px)] max-containerWidth:px-5'>
      <div>
        <h1 className='flex justify-center items-center overflow-hidden'>
          <span className='text-[22px] px-4 text-white whitespace-nowrap relative after:absolute after:w-[600px] after:h-[1px] after:bg-primaryColor-650 after:top-1/2 after:right-full before:absolute before:w-[600px] before:h-[1px] before:bg-primaryColor-650 before:top-1/2 before:left-full'>
            {t('withdrawals')}
          </span>
        </h1>
      </div>
      <div className='mt-8'>
        <div className='my-6 flex flex-wrap lg:flex-nowrap gap-4 items-end'>
          <div className='flex items-center gap-4 z-[2] flex-wrap'>
            <div className='min-w-[251px] max-sm:w-full'>
              <label
                className='block block text-base font-medium text-white pb-2'
                for='grid-state'
              >
                {t('selectType')}
              </label>
              <div className='relative'>
                <Select
                  isSearchable={false}
                  placeholder={'All'}
                  className='react-border-select'
                  classNamePrefix='react-border-inner'
                  defaultValue={selectedOption}
                  value={selectedOption}
                  onChange={(item) => setSelectedOption(item)}
                  options={options}
                />
                <ArrowDown className='absolute top-1/2 right-4 transform translate-y-[-50%] pointer-events-none' />
              </div>
            </div>
            <div className='relative min-w-[251px] max-sm:w-full max-sm:[&>.react-datepicker-wrapper]:w-full'>
              <label
                className='block block text-base font-medium text-white pb-2'
                for='grid-state'
              >
                {t('selectDate')}
              </label>
              <DatePicker
                showIcon
                icon={
                  <CalendarIcon className='absolute top-1/2 right-4 transform translate-y-[-50%] pointer-events-none' />
                }
                placeholderText='MM-DD-YYYY'
                dateFormat='MM-dd-yyyy'
                maxDate={new Date()}
                selected={selectedDate}
                onChange={(e) => handleDateSelect(e)}
                className='datePicker block h-[42px] w-full px-[18px] py-[10px] text-sm tabletscreen:text-base text-primaryColor-500 border border-primaryColor-500 rounded-lg bg-transparent focus-visible:border-primaryColor-500 focus-visible:outline-none placeholder:text-primaryColor-500 !leading-none'
              />
            </div>
          </div>

          <div className='flex items-center gap-4'>
            <button
              onClick={handleWithdrawalList}
              className='text-base leading-none font-normal capitalize border-primaryColor-500 border-solid border px-8 py-[12px] rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group cursor-pointer z-[1] text-center'
            >
              <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
              {t('show')}
            </button>

            <button
              onClick={handleReset}
              class='text-base leading-none font-normal capitalize border-primaryColor-500 border-solid border px-8 py-[12px] rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'
            >
              <span class='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
              {t('reset')}
            </button>
          </div>
        </div>
      </div>

      <div className='bg-primaryColor-200 rounded-3xl p-4'>
        <div className='overflow-x-auto'>
          <table className='min-w-full bg-transparent text-sm border-collapse rounded-3xl'>
            <thead className='ltr:text-left rtl:text-right p-2 lg:table-header-group hidden'>
              <tr className='[&>*:first-child]:rounded-l-2xl [&>*:last-child]:rounded-r-2xl [&>th]:bg-primaryColor-100 [&>th]:text-primaryColor-800 [&>th]:text-lg [&>th]:p-4'>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('date')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('amount')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'>
                  {t('status')}
                </th>
                <th className='whitespace-nowrap px-4 py-2 font-normal capitalize'></th>
              </tr>
            </thead>

            {loader ? (
              <tr>
                <td colSpan='3'>
                  <Loader />
                </td>
              </tr>
            ) : (
              <tbody className='[&>tr]:mb-4 [&>tr]:lg:mb-0 [&>*:last-child]:mb-0 lg:divide-y  lg:divide-primaryColor-350 divide-solid '>
                {withdrawalList?.rows?.length > 0 ? (
                  withdrawalList?.rows?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className='[&>td]:py-6 [&>td]:lg:pl-1 flex flex-col items-start lg:table-row bg-primaryColor-100 lg:bg-transparent rounded-2xl lg:rounded-none max-lg:border-primaryColor-500 max-lg:border max-lg:border-solid'
                      >
                        <td dynamic-heading-value={t('date')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                          {item?.createdAt &&
                            dateTimeFormat(item?.createdAt, '--')}
                        </td>

                        <td dynamic-heading-value={t('amount')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-white font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                          $ <span>{item?.amount}</span>
                        </td>

                        <td className='tabletscreen:whitespace-nowrap px-1 lg:w-auto w-full flex justify-center'>
                          <span
                            className={`${
                              item?.status == 'approved'
                                ? 'statusSuccess'
                                : 'statusFailed'
                            } capitalize`}
                          >
                            {t(item.status)}
                          </span>
                        </td>

                        <td dynamic-heading-value={t('status')} className="text-center pl-[50%] tabletscreen:whitespace-nowrap px-1 text-primaryColor-500 text-base font-semibold w-full relative lg:w-auto before:content-[attr(dynamic-heading-value)] before:text-start lg:before:content-none before:absolute before:left-4 before:tabletscreen:text-base before:text-sm before:text-white before:w-[43%] before:border-r before:border-primaryColor-500 border-b border-solid border-primaryColor-500 lg:border-none before:flex before:items-center before:top-0 before:h-full">
                          {item?.status === 'pending' ? (
                            <button
                              onClick={() => handleCancelWithdrawRequest(item)}
                              className='text-base leading-none font-medium capitalize border-primaryColor-500 border-solid border px-6 py-2.5 rounded-lg relative inline-block text-primaryColor-500 transition-all duration-500 ease-in-out bg-transparent overflow-hidden group hover:text-white cursor-pointer z-[1]'
                            >
                              <span class='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
                              {t('cancel')}
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td
                      colSpan='3'
                      className='text-white text-center text-lg pt-4 max-lg:pt-0'
                    >
                      {t('noDataAvailable')}
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {showPagination && (
        <Pagination
          currentOffset={currentOffset}
          onPageChange={onPageChange}
          disableNext={showNextBtn}
          itemCount={withdrawalList?.count}
        />
      )}
    </div>
  )
}

export default Withdrawal
