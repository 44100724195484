import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserIcon } from '../../../../assets/image'
import { Link } from 'react-router-dom'
import { toFixed } from '../../../../utils/common-functions'

const PlayerDetail = ({
  playerDetailSection,
  bucketDetails,
  remainingSalaryCap,
  handleRosterPlayerList
}) => {
  const { t } = useTranslation('playerSelection')

  return (
    <div className='bg-primaryColor-200 px-6 max-sm:px-2 py-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-3'>
          {playerDetailSection?.image ? (
            <img
              className='w-[76px] max-sm:w-[42px] h-[76px] max-sm:h-[42px] object-cover rounded-2xl'
              src={playerDetailSection?.image}
              alt=''
            />
          ) : (
            <UserIcon className='w-[76px] h-[76px] max-lg:w-[100px] max-lg:h-[100px] max-md:w-[62px] max-md:h-[62px] object-contain rounded-xl' />
          )}
          <span className='text-2xl max-sm:text-lg font-semibold text-white'>
            {playerDetailSection?.name}
          </span>
        </div>
        <Link
          to={{
            pathname: `/player-detail`,
            state: {
              playerId: playerDetailSection?.id,
              bucketData: {
                bucketUid: bucketDetails?.bucketUid,
                entryFee: bucketDetails?.price,
                salaryCap: bucketDetails?.budget,
                remainingSalaryCap:
                  bucketDetails?.budget -
                  remainingSalaryCap,
                endTime: bucketDetails?.endTime
              }
            }
          }}
          className='underline text-primaryColor-250 text-base max-sm:text-xs font-normal capitalize cursor-pointer'
        >
          {t('playerCarrerLog')}
        </Link>
      </div>
      <div className='bg-primaryColor-900 py-2 px-4 my-4'>
        <h5 className='text-white capitalize text-lg font-normal'>
          {t('playerCareerSummary')}
        </h5>
      </div>
      <div className='flex items-center gap-12 pb-4 border-b border-solid border-primaryColor-350'>
        <div className='[&>:not(:last-child)]:pb-2'>
          <div className='w-full flex gap-4'>
            <span className='min-w-[130px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
              <span className='text-lg max-md:text-base max-sm:text-xs'>
                {t('careerAvg')}
              </span>
              <span>:</span>
            </span>
            <span className='text-white text-lg max-md:text-base max-sm:text-xs font-semibold'>
              {playerDetailSection?.careerAvg && toFixed(playerDetailSection?.careerAvg)}
            </span>
          </div>
          <div className='w-full flex gap-4'>
            <span className='min-w-[130px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
              <span className='text-lg max-md:text-base max-sm:text-xs'>
                {t('careerYear')}
              </span>
              <span>:</span>
            </span>
            <span className='text-white text-lg max-md:text-base max-sm:text-xs font-semibold'>
              {playerDetailSection?.careerYears}
            </span>
          </div>
        </div>
        <div className='[&>:not(:last-child)]:pb-2'>
          <div className='w-full flex gap-4'>
            <span className='min-w-[130px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
              <span className='text-lg max-md:text-base max-sm:text-xs'>
                {t('salary')}
              </span>
              <span>:</span>
            </span>
            <span className='flex items-center text-white text-lg max-md:text-base max-sm:text-xsfont-semibold'>
              $
              <span className='text-white text-lg max-md:text-base max-sm:text-xs font-semibold'>
                {playerDetailSection?.value && toFixed(playerDetailSection?.value)}
              </span>
            </span>
          </div>
          <div className='w-full flex gap-4'>
            <span className='min-w-[130px] flex items-center justify-between capitalize text-primaryColor-500 whitespace-nowrap'>
              <span className='text-lg max-md:text-base max-sm:text-xs'>
                {t('position')}
              </span>
              <span>:</span>
            </span>
            <span className='flex items-center text-white text-lg max-md:text-base max-sm:text-xsfont-semibold'>
              <span className='text-white text-lg max-md:text-base max-sm:text-xs font-semibold'>
                {playerDetailSection?.position}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className='w-full mt-4'>
        <button
          onClick={() =>
            playerDetailSection && handleRosterPlayerList(playerDetailSection)
          }
          className='w-full text-xl max-xl:text-base leading-none font-normal capitalize border-primaryColor-500 border-solid border rounded-lg relative inline-block text-white transition-all duration-500 ease-in-out bg-primaryColor-800  overflow-hidden group px-8 max-xl:px-4 py-[18px] max-sm:py-2.5 cursor-pointer z-[1] text-center'
        >
          <span className='absolute inset-0 demotest opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out z-[-1]'></span>
          {t('addToRoster')}
        </button>
      </div>
    </div>
  )
}

export default PlayerDetail
