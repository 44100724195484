import { createSlice } from '@reduxjs/toolkit'
import { userLogout } from '../../redux/thunk/Auth/Auth'

const initialState = {
  rosterPlayerList: [],
  dnpInsuranceAmount: 0,
  fullRosterDnp: false,
  remainingSalaryCap: 0
}

const {
  actions: {
    setRosterPlayerList,
    setDnpInsuranceAmount,
    setFullRosterDnp,
    clearRosterSlice,
    setRemainingSalaryCap
  },
  reducer
} = createSlice({
  name: 'rosterSlice',
  initialState,
  reducers: {
    setRosterPlayerList: (state, action) => ({
      ...state,
      rosterPlayerList: action.payload
    }),
    setDnpInsuranceAmount: (state, action) => ({
      ...state,
      dnpInsuranceAmount: action.payload
    }),
    setFullRosterDnp: (state, action) => ({
      ...state,
      fullRosterDnp: action.payload
    }),
    setRemainingSalaryCap: (state, action) => ({
        ...state,
        remainingSalaryCap: action.payload
    }),
    clearRosterSlice: (state, action) => ({
      ...initialState
    })
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout.fulfilled, (state, action) => {
      return {
        ...initialState
      }
    })
  }
})

export default reducer
export {
  setRosterPlayerList,
  setDnpInsuranceAmount,
  setFullRosterDnp,
  clearRosterSlice,
  setRemainingSalaryCap
}
